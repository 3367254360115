import { iTool } from '@/interfaces/iTool.ts'
import { Button, Spacer, Spinner, Tooltip, User } from '@nextui-org/react'
import React from 'react'
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import IconFetcher, { EntityType } from '@components/basic/icon/IconFetcher.tsx'
import { ICON_DELETE } from '@/constants/icons.tsx'
import ProfileImage from '@components/basic/profileImage/ProfileImage.tsx'

type ToolItemProps = {
  entityId: string
  entityType: EntityType
  name: string
  owner?: string
  description?: string
  image?: string
  isDeleting?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  onClickDelete?: () => void
  onClick?: () => void
}

const ItemSelectionItem = ({
  entityId,
  entityType,
  name,
  owner,
  description,
  image,
  onClickDelete,
  onClick,
  isLoading,
  isDeleting,
  isDisabled,
}: ToolItemProps) => {
  return (
    <div
      className="flex items-center flex-1"
      onClick={(event) => {
        event.stopPropagation()
        onClick && onClick()
      }}
    >
      {entityType === 'User' ? (
        <ProfileImage contact={owner!}></ProfileImage>
      ) : (
        <IconFetcher entityId={entityId} entityType={entityType} imageId={image} />
      )}
      <div className="flex flex-col ml-2">
        <span className="font-semibold">{name}</span>
        <span className="text-xs text-gray-500">{owner}</span>
      </div>

      <Tooltip content={description} className="max-w-96">
        <InformationCircleIcon
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.0}
          stroke="currentColor"
          className="w-4 h-4 ml-1"
        />
      </Tooltip>
      {onClickDelete && !isDisabled && (
        <>
          <Spacer className="flex-1"></Spacer>
          <Button
            isLoading={isDeleting}
            variant="light"
            isIconOnly
            color="danger"
            onPress={() => onClickDelete()}
          >
            <ICON_DELETE />
          </Button>
        </>
      )}
      {isLoading && <Spinner></Spinner>}
    </div>
  )
}

export default React.memo(ItemSelectionItem)
