import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import useTeamStore from '@/states/teamStore.ts'
import useFolderStore from '@states/folderStore.ts'
import { shallow } from 'zustand/shallow'
import { iFolder, SessionModalMode } from '@/interfaces/iFolder.ts'

type DialogSessionFolderModalProps = {
  open: boolean
  folder?: iFolder | undefined
  onCreated?: (newFolder: iFolder) => Promise<void>
  onClose?: () => void
}

const DialogSessionFolderModal = ({
  open,
  folder,
  onCreated,
  onClose,
}: DialogSessionFolderModalProps) => {
  const { updateFolder, addFolder } = useFolderStore(
    (state) => ({
      updateFolder: state.updateFolder,
      addFolder: state.addFolder,
    }),
    shallow,
  )
  const selectedTeam = useTeamStore((state) => state.selectedTeam, shallow)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const folderModalMode = useMemo(
    () => (folder ? SessionModalMode.Edit : SessionModalMode.Create),
    [folder],
  )

  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    inputRef?.current && inputRef.current.focus()
  }, [])

  /**
   * Check if the new folder name is valid and create the folder
   */
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // important to avoid page refresh
    e.preventDefault()
    const data: iFolder = Object.fromEntries(
      new FormData(e.currentTarget),
    ) as unknown as iFolder
    const tmpfolder: iFolder =
      folder ??
      ({
        title: data.title,
        teamId: selectedTeam?.id,
      } as iFolder)

    tmpfolder.title = data.title.trim()
    setIsLoading(true)
    const method = tmpfolder.id ? updateFolder : addFolder
    method(tmpfolder).then(() => {
      onCreated && onCreated(tmpfolder)
      setIsLoading(false)
      prepareClose()
    })
  }

  /**
   * reset the form and close the modal
   */
  const prepareClose = () => {
    onClose && onClose()
  }

  const saveButtonTitle = useMemo(
    () =>
      folderModalMode === SessionModalMode.Edit ? 'Edit Folder' : 'Create Folder',
    [],
  )
  const validateTitle = (title: string) => {
    if (!title) return 'Folder name is required'
    if (title.length < 3) return 'Title must have an minimum of three characters'
    if (title.length > 64) return 'Title can only have a maximum of 64 characters'
    const validName = /^[A-Za-z0-9 -]*[A-Za-z0-9][A-Za-z0-9 -]*$/i.test(title)
    if (!validName)
      return 'Folder name must be larger than 3 letters and must not contain special characters.'
    return null
  }

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={open}
      onClose={onClose}
      classNames={{
        base: '!max-w-[100vw] w-fit w-[350px]',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <h3 className="font-bold text-lg">{saveButtonTitle}</h3>
        </ModalHeader>

        <ModalBody>
          <Form
            id="sessionFolderForm"
            validationBehavior="native"
            onSubmit={onSubmit}
          >
            <Input
              name="title"
              type="text"
              label="Folder Name"
              isRequired
              defaultValue={folder?.title ?? ''}
              validate={validateTitle}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <>
            <Button
              type="reset"
              variant={'light'}
              form="sessionFolderForm"
              className="mr-4"
              onPress={() => prepareClose()}
            >
              Close
            </Button>

            <Button
              type="submit"
              form="sessionFolderForm"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {saveButtonTitle}
            </Button>
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DialogSessionFolderModal
