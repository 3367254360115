import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import useFolderStore from '@states/folderStore.ts'
import { shallow } from 'zustand/shallow'
import { iFolder } from '@/interfaces/iFolder.ts'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'
import { ICON_ASSISTANT } from '@/constants/icons.tsx'
import ItemSelection from '@components/tools/ItemSelection.tsx'
import AssistantService from '@/services/assistantService.ts'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'

type DialogSessionFolderModalProps = {
  open: boolean
  folder?: iFolder
  onCreated?: (newFolder: iFolder) => Promise<void>
  onClose?: () => void
}

const DialogFolderOptions = ({
  open,
  folder,
  onCreated,
  onClose,
}: DialogSessionFolderModalProps) => {
  const { updateFolder, addFolder } = useFolderStore(
    (state) => ({
      updateFolder: state.updateFolder,
      addFolder: state.addFolder,
    }),
    shallow,
  )
  const [defaultAssistant, setDefaultAssistant] = useState<iAssistant>()
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (folder?.defaultAssistantId) {
      AssistantService.getAssistant(folder.defaultAssistantId).then((res) =>
        setDefaultAssistant(res),
      )
    }
  }, [folder?.defaultAssistantId])

  const handleClose = () => {
    onClose?.()
  }

  const handleSave = async () => {
    if (!folder) return
    setIsSaving(true)
    try {
      const updatedFolder: iFolder = {
        ...folder,
        defaultAssistantId: defaultAssistant?.id,
      }
      if (folder.id) await updateFolder(updatedFolder)
      else await addFolder(updatedFolder)
      onCreated && (await onCreated(updatedFolder))
      onClose?.()
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Modal
      scrollBehavior="inside"
      backdrop="blur"
      isOpen={open}
      onClose={onClose}
      classNames={{ base: '!max-w-[100vw] w-fit w-[450px]' }}
    >
      <ModalContent>
        <ModalHeader>
          <h3 className="font-bold text-lg">Folder Options</h3>
        </ModalHeader>
        <ModalBody>
          <SectionCard
            className="min-w-72"
            icon={<ICON_ASSISTANT />}
            title="Default Assistant"
            description="Select a default assistant for new sessions."
          >
            <ItemSelection
              label="Add assistant"
              placeholder="Seek for assistants"
              singleSelection
              loadFunc={(filter) =>
                AssistantService.getAssistants({
                  ...filter,
                  teamId: folder?.teamId,
                })
              }
              selectedItems={defaultAssistant ? [defaultAssistant] : []}
              onChange={async (items) => {
                setDefaultAssistant(items[0] as iAssistant)
              }}
            />
          </SectionCard>
        </ModalBody>
        <ModalFooter>
          <Button variant="light" onPress={handleClose}>
            Close
          </Button>
          <Button color="primary" onPress={handleSave} isLoading={isSaving}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DialogFolderOptions
