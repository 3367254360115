import * as React from 'react'
import './profileImage.css'
import ProfileImageLoader from '../../../hooks/useProfileImage.ts'
import { useEffect, useState } from 'react'
import { Avatar, Skeleton, Spinner } from '@nextui-org/react'
import { UserIcon } from '@heroicons/react/24/outline'

type ProfileImageProps = {
  contact: string
  width?: number
  defaultIconWidth?: number
}
const ProfileImage = ({
  contact,
  width = 10,
  defaultIconWidth = 6,
}: ProfileImageProps) => {
  const [image, setImage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  const loadImage = async () => {
    setLoading(true)
    try {
      if (contact === 'gaia') {
        getGaiaAvatar()
        return
      }
      const loadedImage = await ProfileImageLoader.loadProfileImage(contact)
      setImage(loadedImage)
    } catch (e) {
      /* empty */
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    loadImage()
  }, [contact])

  const getGaiaAvatar = () => {
    // load the 96.png from the public folder
    // convert it to base64 to use it as src in Avatar
    const img = new Image()
    img.src = '/96.png'
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0)
      const dataUrl = canvas.toDataURL('image/png')
      setImage(dataUrl)
    }
  }

  return (
    <Skeleton
      isLoaded={!loading}
      className={`min-w-${width} min-h-${width} rounded-full flex justify-center align-center`}
    >
      {!loading && (
        <Avatar
          size={'md'}
          src={image}
          showFallback
          fallback={
            <UserIcon
              color="white"
              className={`w-${defaultIconWidth} h-${defaultIconWidth}`}
            />
          }
          className={`${contact === 'gaia' && 'bg-transparent'}`}
        />
      )}
    </Skeleton>
  )
}

export default ProfileImage
