import React from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
} from '@nextui-org/react'
import { AtSymbolIcon, LifebuoyIcon, StarIcon } from '@heroicons/react/24/outline'
import { PiMicrosoftTeamsLogo } from 'react-icons/pi'
import { AiOutlineCloud } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { ROUTE_FEATURE_VOTING } from '@/constants/routes.ts'

const SupportMenu = () => {
  const navigate = useNavigate()
  return (
    <Dropdown>
      <DropdownTrigger className={'h-18'}>
        <button>
          <LifebuoyIcon className="h-5 w-5" />
        </button>
      </DropdownTrigger>
      <DropdownMenu>
        <DropdownSection title="Contact us">
          <DropdownItem
            textValue={'Mail'}
            startContent={<AtSymbolIcon className={'w-5 h-5'} />}
            key={'mail'}
          >
            <a href="mailto:DLCDE-IT-GAIA@draeger.com">E-Mail</a>
          </DropdownItem>
          <DropdownItem
            textValue={'Viva'}
            startContent={<AiOutlineCloud className={'w-5 h-5'} />}
            key={'viva'}
          >
            <a
              href="https://engage.cloud.microsoft/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIyMDkyNjYzMjc1NTIifQ/new"
              target="_blank"
            >
              Viva Engage
            </a>
          </DropdownItem>
          <DropdownItem
            textValue={'Teams'}
            startContent={<PiMicrosoftTeamsLogo className="w-5 h-5" />}
            key={'teams-channel'}
          >
            <a
              href="https://teams.microsoft.com/l/team/19%3A58KsbnveJpV8NI-PRdtUFUa-O3yNkDDBcpMlh8js2581%40thread.tacv2/conversations?groupId=5b45ec2f-fc38-40ad-9740-4bd720544427&tenantId=e8d897a8-f400-4625-858a-6f3ae627542b"
              target="_blank"
            >
              Teams Channel
            </a>
          </DropdownItem>
        </DropdownSection>
        <DropdownSection>
          <DropdownItem
            textValue={'FeatureVoting'}
            startContent={<StarIcon className="w-5 h-5" />}
            key={'feature-voting'}
            onPress={() => navigate(ROUTE_FEATURE_VOTING)}
          >
            Feature Voting
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  )
}

export default SupportMenu
