import { createWithEqualityFn } from 'zustand/traditional'
import { Popup } from '../enums/PopupTypes.ts'

interface iMainState {
  isDrawerOpen: boolean
  isSidebarOpen: boolean
  setIsDrawerOpen: (loading: boolean) => void
  setIsSidebarOpen: (loading: boolean) => void
  isSidebarPinned: boolean
  setIsSidebarPinned: (loading: boolean) => void
  isSidebarContentOpen: boolean
  setIsSidebarContentOpen: (loading: boolean) => void

  isLoading: boolean
  setIsLoading: (loading: boolean) => void
  error: Error | null
  setError: (error: Error | null) => void

  popup: Popup
  setPopupType: (popup: Popup) => void

  popupArgs: any | null
  setPopupArgs: (args: any | null) => void

  feedback: string
  setFeedback: (feedback: string) => void

  feedbackType: string
  setFeedbackType: (type: string) => void
  setPopup: (popup: Popup, args?: any) => void
}

const useMainStore = createWithEqualityFn<iMainState>((set) => ({
  isDrawerOpen: false,
  isSidebarOpen: true,
  setIsSidebarOpen: (isSidebarOpen: boolean) => set(() => ({ isSidebarOpen })),
  setIsDrawerOpen: (isSidebarOpen: boolean) => set(() => ({ isSidebarOpen })),

  isSidebarContentOpen: false,
  setIsSidebarContentOpen: (isSidebarContentOpen: boolean) =>
    set(() => ({ isSidebarContentOpen })),

  isSidebarPinned: false,
  setIsSidebarPinned: (isSidebarPinned: boolean) => set(() => ({ isSidebarPinned })),

  isLoading: false,
  setIsLoading: (loading: boolean) => set(() => ({ isLoading: loading })),
  error: null,
  setError: (error: Error | null) => set(() => ({ error })),

  popup: Popup.None,
  setPopupType: (popup: Popup) => set(() => ({ popup })),

  popupArgs: null,
  setPopupArgs: (popupArgs: any | null) => set(() => ({ popupArgs })),

  feedback: '',
  setFeedback: (feedback: string) => set(() => ({ feedback })),

  feedbackType: '',
  setFeedbackType: (feedbackType: string) => set(() => ({ feedbackType })),
  setPopup: (popup: Popup, popupArgs?: any) => {
    set(() => ({ popup }))
    set(() => ({ popupArgs }))
  },
}))

export default useMainStore
