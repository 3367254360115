import legalJSON from '../../public/legal/legal.json'

export const GAIA_LEGAL_JSON = {
  version: legalJSON.date,
  content: [
    {
      language: 'en',
      isFallback: true,
      text: `
In order to use GAIA, the [terms of use](/legal/Nutzungsbedingungen_GAIA_v1.0_EN-US.pdf)
and the [privacy policy](${encodeURI('/legal/Annex 2 - DrägerGPT_Data protection information_EN.pdf')}) must be
accepted. If you agree to the terms and conditions, click on the "Accept" button.
`,
    },
  ],
}
