import React from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@nextui-org/react'
import ParameterFields from './ParameterFields'

interface Parameter {
  name: string
  parameter: {
    enum?: string[]
    required?: boolean
  }
}

interface ParameterModalProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  parameters: Parameter[]
  parameterValues: Record<string, any>
  onParameterChange: (name: string, value: any) => void
  onSubmit: () => void
  isLoading: boolean
}

/**
 * Modal for entering parameters (loads test response).
 */
const ParameterModal: React.FC<ParameterModalProps> = ({
  isOpen,
  onOpenChange,
  parameters,
  parameterValues,
  onParameterChange,
  onSubmit,
  isLoading,
}) => {
  return (
    <Modal
      isDismissable={false}
      isKeyboardDismissDisabled={true}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Enter Parameters
            </ModalHeader>
            <ModalBody>
              <ParameterFields
                parameters={parameters}
                parameterValues={parameterValues}
                onParameterChange={onParameterChange}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              <Button
                color="primary"
                isLoading={isLoading}
                onPress={() => {
                  onSubmit()
                  onClose()
                }}
              >
                Submit
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ParameterModal
