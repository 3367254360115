import { iDocument } from '@/interfaces/iFile'
import axiosClient from '../utils/axiosClient.ts'
import { iDocumentAccess } from '@/interfaces/iSourceFileCitations.ts'

class DocumentService {
  private static instance: DocumentService

  private constructor() {}

  public static getInstance(): DocumentService {
    if (!DocumentService.instance) {
      DocumentService.instance = new DocumentService()
    }

    return DocumentService.instance
  }

  public getDocuments(
    limit?: number,
    offset?: number,
    searchValue?: string,
    excludeImages?: boolean,
  ): Promise<{ data: iDocument[]; total: number }> {
    let excludeContentTypes = undefined
    if (excludeImages) {
      excludeContentTypes = 'image'
    }
    return axiosClient
      .get('/documents', {
        params: { limit, offset, searchValue, excludeContentTypes },
      })
      .then((response) => response.data)
  }

  public uploadDocument(
    files: File[],
    parsingMethod?: string,
    teamId?: string,
  ): Promise<iDocument[]> {
    const formData = new FormData()
    files.forEach((file) => {
      formData.append('files', file, file.name)
    })
    return axiosClient
      .post('/documents', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { parsingMethod, teamId },
      })
      .then((response) => response.data)
  }

  public parseDocument(
    documentId: string,
    teamId: string,
    pagerange: string | null = null,
    originalAssistantId: string,
    modelId?: string,
  ): Promise<any> {
    return axiosClient
      .get(`/documents/${documentId}/parse`, {
        params: { teamId, pagerange, originalAssistantId, modelId },
      })
      .then((response) => response.data)
  }

  public async downloadDocument(documentId: string): Promise<Blob> {
    const response = await axiosClient.get(`/documents/${documentId}/download`, {
      responseType: 'blob',
    })
    if (response.status !== 200) {
      throw new Error('Could not download document')
    }
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    })
    return blob
  }

  public async getDocumentAccess(documentId: string): Promise<iDocumentAccess> {
    return axiosClient
      .get(`/documents/${documentId}/access`)
      .then((response) => response.data)
  }

  public deleteDocument(documentId: string): Promise<void> {
    return axiosClient.delete(`/documents/${documentId}`).then(() => {})
  }
}

export default DocumentService.getInstance()
