import React, { useEffect, useMemo, useState } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import useUserProfileStore from '../../states/userProfileState.ts'
import { shallow } from 'zustand/shallow'
import { iTermsOfUse, iTermsOfUseContent } from '@/interfaces/iTermsOfUse.ts'
import Markdown from '@components/markdown/Markdown.tsx'

type LegalDialogProps = React.HTMLAttributes<HTMLDialogElement> & {
  termsOfUse: iTermsOfUse
  resourceId: string | 'gaia'
  onCloseDialog?: (accepted: boolean) => void
}
const LegalDialog = ({
  termsOfUse,
  resourceId,
  onCloseDialog,
}: LegalDialogProps) => {
  const [selectedLanguage] = useState<string>(navigator.language || 'en')
  const [termsOfUseContent, setTermsOfUseContent] =
    useState<iTermsOfUseContent | null>(null)
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { acceptedToUVersion, userProfile } = useUserProfileStore(
    (state) => ({
      acceptedToUVersion: state.acceptedToUVersion,
      userProfile: state.userProfile,
    }),
    shallow,
  )

  const hasOpenToU = useMemo(() => {
    if (!termsOfUse || !userProfile) return false
    const acceptedDate =
      userProfile.acceptedToUVersion && userProfile.acceptedToUVersion[resourceId]
    if (acceptedDate) {
      return termsOfUse.version > acceptedDate && termsOfUse.content.length
    } else {
      return true
    }
  }, [userProfile, termsOfUse, resourceId])

  const findContent = (
    termsOfUse: iTermsOfUse,
    lang: string,
  ): iTermsOfUseContent => {
    let content =
      termsOfUse.content.find((content: iTermsOfUseContent) =>
        lang.toLowerCase().startsWith(content.language),
      ) || null
    if (!content) {
      content =
        termsOfUse.content.find(
          (content: iTermsOfUseContent) => content.isFallback,
        ) || null
    }
    if (!content) {
      content = termsOfUse.content[0]
    }
    return { ...content }
  }

  useEffect(() => {
    setTermsOfUseContent({ ...findContent(termsOfUse, selectedLanguage) })
    if (termsOfUse && hasOpenToU) {
      onOpen()
    } else {
      onCloseDialog && onCloseDialog(true)
    }
  }, [hasOpenToU, termsOfUse, selectedLanguage])

  const handleAcceptLegalDialog = () => {
    setIsLoading(true)
    acceptedToUVersion(resourceId)
      .then(() => {
        onCloseDialog && onCloseDialog(true)
        onClose()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Modal
        scrollBehavior={'inside'}
        backdrop={'blur'}
        isOpen={isOpen}
        hideCloseButton={resourceId === 'gaia'}
        isDismissable={false}
        classNames={{
          base: '!max-w-[500px] w-fit',
        }}
        onClose={() => {
          onCloseDialog && onCloseDialog(false)
          onClose()
        }}
        // onOpenChange={(isOpen) => {
        //   if (!isOpen) {
        //     getSession(activeSession!.id!)
        //       .then((session) => {
        //         return activateSession(session!)
        //       })
        //       .then(() => {
        //         onCloseDialog && onCloseDialog()
        //         onClose()
        //       })
        //   } else {
        //     onOpenChange()
        //   }
        // }}
      >
        <ModalContent>
          <ModalHeader>
            <h3 className="font-bold text-lg">Terms of Service</h3>
          </ModalHeader>
          <ModalBody>
            <Markdown value={termsOfUseContent?.text ?? ''}></Markdown>
          </ModalBody>
          <ModalFooter>
            <Button isLoading={isLoading} onPress={() => handleAcceptLegalDialog()}>
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default LegalDialog
