import React, { useEffect, useRef, useState } from 'react'
import { getDocumentIcon } from '@/utils/getDocumentIcon.tsx'
import PDFViewer, {
  PdfViewerHandler,
} from '@components/basic/pdfViewer/PDFViewer.tsx'
import { iFile } from '@/interfaces/iSourceFileCitations.ts'
import { iDocumentItem } from '@/interfaces/iDocumentItem.ts'
import { DocumentType } from '@/enums/DocumentType.ts'
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'
import { Button, Card, CardBody, CardHeader, Skeleton } from '@nextui-org/react'
import Markdown from '@components/markdown/Markdown.tsx'
import { iDocumentChunk } from '@/interfaces/iDocumentChunk.ts'
import { XMLParser } from 'fast-xml-parser'
import useDocumentAccessStore from '@states/documentStore.ts'
import { renderHandlebarsTemplate } from '@components/tools/HandlebarsRenderer.ts'

const languageMap: { [key: string]: string } = {
  py: 'python',
  cs: 'csharp',
  js: 'javascript',
  jsx: 'jsx',
  ts: 'typescript',
  tsx: 'tsx',
  java: 'java',
  php: 'php',
  rb: 'ruby',
  go: 'go',
  c: 'c',
  cpp: 'cpp',
  h: 'cpp',
  html: 'html',
  css: 'css',
  json: 'json',
  xml: 'xml',
  sh: 'bash',
  md: 'markdown',
  yml: 'yaml',
  awslambda: 'yaml',
  yaml: 'yaml',
  sql: 'sql',
  azurepipelines: 'yaml',
  env: 'yaml',
}

function getFileExtension(filename: string): string {
  const parts = filename.split('.')
  return parts.length > 1 ? parts.pop()!.toLowerCase() : ''
}

type ChatDocumentsDetailViewProps = {
  navigation: any
  document: iDocumentItem
  sourceId?: number
}

// Main Component
const ChatDocumentsDetailView = ({
  navigation,
  document,
  sourceId,
}: ChatDocumentsDetailViewProps) => {
  const [file, setFile] = useState<iFile>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const cardRefs = useRef<{ [key: number]: HTMLDivElement | null }>({})
  const initialPage = sourceId
    ? document._documentChunks?.find((dc) => dc.sourceId === sourceId)?.excerpt
        .startOffset
    : document._documentChunks![0].excerpt.startOffset

  const [renderedMarkdown, setRenderedMarkdown] = useState<string>('')
  const [parsedData, setParsedData] = useState<string>('')

  const { getDocumentAccessFile } = useDocumentAccessStore()

  useEffect(() => {
    setIsLoading(true)

    // If it's a "Tool" doc, skip fetching file from KnowledgeContainerService
    if (document.documentType === DocumentType.Tool) {
      setIsLoading(false)
      return
    }

    // Retrieve a cached or newly-fetched iFile
    const kcId = document.knowledgeContainerId
    const refPath = document.refPath

    if (!kcId || !refPath) {
      setIsLoading(false)
      return
    }

    getDocumentAccessFile(kcId, refPath)
      .then((cachedFile) => {
        setFile(cachedFile!)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [document, getDocumentAccessFile])

  useEffect(() => {
    let resizeObserver: ResizeObserver | null = null
    let stableSizeTimeout: ReturnType<typeof setTimeout> | null = null

    if (sourceId && document.documentType === DocumentType.Tool) {
      const anchorText = `[${sourceId}]`
      const parentEl = window.document.getElementById(
        'handlebarsMarkdown',
      ) as HTMLElement | null

      if (parentEl) {
        const checkAndScroll = () => {
          const el = findDeepestContainingElement(parentEl, anchorText)
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' })
            // Disconnect the observer since we've scrolled to the element
            if (resizeObserver) {
              resizeObserver.disconnect()
              resizeObserver = null
            }
            if (stableSizeTimeout) {
              clearTimeout(stableSizeTimeout)
              stableSizeTimeout = null
            }
          }
        }

        let previousWidth = parentEl.offsetWidth

        const handleResize = () => {
          const currentWidth = parentEl.offsetWidth
          if (currentWidth !== previousWidth) {
            previousWidth = currentWidth
            // Reset the timeout since the size is still changing
            if (stableSizeTimeout) {
              clearTimeout(stableSizeTimeout)
            }
            // Wait for a period of time after the last resize event
            stableSizeTimeout = setTimeout(() => {
              checkAndScroll()
            }, 100) // Adjust the delay as needed
          } else {
            // Size hasn't changed, but we want to ensure we call checkAndScroll when the size stabilizes
            if (!stableSizeTimeout) {
              stableSizeTimeout = setTimeout(() => {
                checkAndScroll()
              }, 100) // Adjust the delay as needed
            }
          }
        }

        // Observe size changes on the parent element
        resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(parentEl)

        // Perform an initial check in case the size is already stable
        handleResize()
      }
    }

    // Cleanup function to disconnect the observer and clear timeouts
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
        resizeObserver = null
      }
      if (stableSizeTimeout) {
        clearTimeout(stableSizeTimeout)
        stableSizeTimeout = null
      }
    }
  }, [sourceId, document.documentType])

  function findDeepestContainingElement(
    element: HTMLElement,
    text: string,
  ): HTMLElement | null {
    // Base case: If the element has no child elements
    if (element.children.length === 0) {
      return element.textContent?.includes(text) ? element : null
    }

    // Recursive case: Iterate over child elements
    for (const child of Array.from(element.children)) {
      const childElement = child as HTMLElement
      if (childElement.textContent?.includes(text)) {
        const deeperElement = findDeepestContainingElement(childElement, text)
        return deeperElement || childElement
      }
    }

    return null
  }
  useEffect(() => {
    if (!isLoading && sourceId !== undefined) {
      const ref = cardRefs.current[sourceId]
      if (ref) {
        ref.scrollIntoView({ behavior: 'smooth' })
      }
    }
    if (initialPage) pdfViewerRef.current?.jumpToPage(initialPage)
  }, [isLoading, sourceId])

  const renderMarkdown = (data: any, templateString: string): boolean => {
    try {
      const result = renderHandlebarsTemplate(templateString, data)
      setRenderedMarkdown(result)
      return true
    } catch (e) {
      setRenderedMarkdown('Error rendering markdown')
      return false
    }
  }

  // Add this useEffect to handle rendering markdown when document changes
  useEffect(() => {
    if (document.documentType === DocumentType.Tool) {
      renderMarkdown(document.content, document.markdownTemplate!)
    }
  }, [document])

  useEffect(() => {
    if (document.documentType === DocumentType.Tool) {
      let parsedData = null
      try {
        const parsed = JSON.parse(document.content!)
        parsedData = parsed
      } catch (e) {
        try {
          const parser = new XMLParser()
          const parsed = parser.parse(document.content!)
          parsedData = parsed
        } catch (e) {
          parsedData = null
          setRenderedMarkdown('')
        }
      }
      if (parsedData !== null) {
        setParsedData(parsedData)
      }
    }
  }, [document])

  useEffect(() => {
    if (parsedData) {
      renderMarkdown(parsedData, document.markdownTemplate!)
    }
  }, [parsedData])

  const downloadCard = () => {
    const chunksBySourceId: { [sourceId: number]: iDocumentChunk[] } = {}
    if (document._documentChunks) {
      document._documentChunks.forEach((chunk) => {
        const sourceId = chunk.sourceId
        if (!chunksBySourceId[sourceId]) {
          chunksBySourceId[sourceId] = []
        }
        chunksBySourceId[sourceId].push(chunk)
      })
    }

    const filename = file?.documentName || document.refPath || ''
    const extension = getFileExtension(filename)
    const language = languageMap[extension] || ''

    const cards = Object.entries(chunksBySourceId).map(([sourceIdStr, chunks]) => {
      const sourceId = parseInt(sourceIdStr, 10)
      // Collect unique references
      const allReferences = chunks.flatMap((chunk) => chunk.references || [])
      const uniqueReferencesDescriptions = Array.from(
        new Set(allReferences.map((ref) => ref.description)),
      )

      const markdownContents = chunks
        .map((chunk) => chunk.excerpt.content)
        .join('\n')
      const markdownContent = language
        ? `\`\`\`${language}\n${markdownContents}\n\`\`\``
        : markdownContents

      return (
        <div
          key={sourceId}
          ref={(el) => {
            cardRefs.current[sourceId] = el
          }}
        >
          <Card className="w-full">
            <CardHeader>
              <div className="mr-5"></div>
              <div className="flex flex-row">
                <p className="text-md mr-2">[{sourceId}]</p>
                <div className={'text-default-500 text-sm'}>
                  {uniqueReferencesDescriptions.map((refDesc, idx) => (
                    <p key={idx}> - {refDesc}</p>
                  ))}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Markdown value={markdownContent} />
            </CardBody>
          </Card>
        </div>
      )
    })

    return <>{cards}</>
  }

  const pdfViewerRef = useRef<PdfViewerHandler>(null)
  const getRenderer = () => {
    switch (document.documentType) {
      case DocumentType.Pdf:
        if (!file) {
          return <Skeleton className="rounded-large w-full h-[700px]" />
        }
        return (
          <PDFViewer
            ref={pdfViewerRef}
            url={file.accessUrl}
            documentChunks={document._documentChunks ?? []}
            initialPage={initialPage}
          />
        )

      case DocumentType.Video: {
        const videoUrl = file?.accessUrl
        return videoUrl ? (
          <video width="600" controls>
            <source src={videoUrl} />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div>No video found.</div>
        )
      }
      case DocumentType.Tool:
        return (
          <div id={'handlebarsMarkdown'}>
            {' '}
            <Markdown value={renderedMarkdown} />
          </div>
        )
      default:
        return downloadCard()
    }
  }

  return (
    <div className="space-y-4 mt-4">
      <div className="flex items-center gap-3 m-6">
        {getDocumentIcon(document.documentType)}
        <div className="flex-1">
          <h2 className="text-xl flex-1 font-semibold">
            {document.documentType == DocumentType.Tool
              ? document.title.replace('.tool', '')
              : document.title}
          </h2>

          <div className={'text-default-500 text-sm'}> {document.refPath} </div>
        </div>
        <Button
          isIconOnly
          variant="light"
          onPress={() => window.open(file?.accessUrl, '_blank')}
        >
          <ArrowDownOnSquareIcon className="h-5 w-5" />
        </Button>
      </div>
      {isLoading ? (
        <Skeleton className="rounded-large w-full h-44" />
      ) : (
        <div className="m-12">{getRenderer()}</div>
      )}
    </div>
  )
}

export default ChatDocumentsDetailView
