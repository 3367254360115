import Handlebars from 'handlebars'
import useUserProfileStore from '@states/userProfileState.ts'
import { getAccessToken } from '@/utils/authentication.ts'

/**
 * Replaces known user tokens (e.g. @user_jwt, @user_email, @user_name) in templateString
 * with values from the userProfile store, then compiles and returns via Handlebars.
 */
export function renderHandlebarsTemplate(templateString: string, data: any): string {
  const userProfile = useUserProfileStore.getState().userProfile

  if (userProfile) {
    templateString = templateString
      .replace(/@user_jwt/g, getAccessToken() || '')
      .replace(/@user_email/g, userProfile.email || '')
      .replace(/@user_name/g, userProfile.name || '')
  }

  const template = Handlebars.compile(templateString)
  return template(data)
}
