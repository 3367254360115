import React, { useRef, useState } from 'react'

import { iAssistant } from '@/interfaces/iAssistantTypes.ts'

import AssistantDetailView, {
  AssistantDetailViewHandle,
} from '@components/assistants/AssistantDetailView.tsx'
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import { ICON_ASSISTANT } from '@/constants/icons.tsx'

type AssistantDetailModalProps = {
  assistant: iAssistant
  isOpen: boolean
}
function AssistantDetailModal({ assistant }: AssistantDetailModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const viewRef = useRef<AssistantDetailViewHandle>(null)

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    viewRef.current
      ?.saveAssistant()
      .then(() => {
        onOpenChange()
      })
      .finally(() => setIsLoading(false))
  }
  return (
    <>
      <Button
        variant={'bordered'}
        className="mt-3 min-w-fit w-full ml-1"
        onPress={onOpen}
      >
        <ICON_ASSISTANT />
        Save as new Assistant
      </Button>
      <Modal
        isOpen={isOpen}
        classNames={{
          base: '!max-w-[1200px] w-fit',
        }}
      >
        <ModalContent>
          <Form validationBehavior="native" onSubmit={handleSave}>
            <ModalHeader>{assistant.title}</ModalHeader>
            <ModalBody>
              <AssistantDetailView
                ref={viewRef}
                value={assistant}
              ></AssistantDetailView>
            </ModalBody>
            <ModalFooter className="w-full">
              <Button onPress={onOpenChange} isDisabled={isLoading}>
                Close
              </Button>
              <Button
                type="submit"
                color="primary"
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AssistantDetailModal
