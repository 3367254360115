import { useState } from 'react'
import { Card, Accordion, AccordionItem } from '@nextui-org/react'
import { iToolCall } from '@/interfaces/iMessage.ts'
import JsonDisplay from '@components/basic/jsonViewer/JSONDisplay.tsx'
import HttpToolCall from '@components/toolCalls/HttpToolCall.tsx'

type ToolCallProps = {
  initialToolCall: iToolCall
}

export const ToolCall = ({ initialToolCall }: ToolCallProps) => {
  const [toolCall] = useState(initialToolCall)
  return (
    <Card className="p-4 dark:bg-default">
      <h3 className="text-xl text-primary mb-2">
        {toolCall.toolCallDisplayName || toolCall.toolCallName}
      </h3>
      <Accordion>
        <AccordionItem key="parameters" aria-label="Parameters" title="Parameters">
          <JsonDisplay jsonString={toolCall.toolCallArguments} />
        </AccordionItem>
        {!!toolCall.toolCallContent ? (
          <AccordionItem key="content" aria-label="Content" title="Content">
            <JsonDisplay jsonString={toolCall.toolCallContent} />
          </AccordionItem>
        ) : null}
      </Accordion>
      {toolCall.toolCallHttpContext && (
        <HttpToolCall httpContext={toolCall.toolCallHttpContext} />
      )}
    </Card>
  )
}
