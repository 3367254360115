import React, { useState, useRef } from 'react'
import { Card, CardBody, Button } from '@nextui-org/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { motion, useMotionValue, animate } from 'framer-motion'

interface QuickPromptProps {
  prompts: string[]
  onPromptSelect: (prompt: string) => void
}

const QuickPromptCard: React.FC<{
  text: string
  onSelect: () => void
  isDragging: React.MutableRefObject<boolean>
}> = ({ text, onSelect, isDragging }) => (
  <Card
    isPressable
    onPress={() => !isDragging.current && onSelect()}
    className="shadow-lg w-[180px] h-[55px] flex-shrink-0 mb-4 p-1 opacity-90 hover:opacity-100 transition-all duration-200"
  >
    <CardBody className="p-2">
      <p className="text-sm line-clamp-2">{text}</p>
    </CardBody>
  </Card>
)

const QuickPrompts: React.FC<QuickPromptProps> = ({ prompts, onPromptSelect }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isHovered, setIsHovered] = useState(false)
  const x = useMotionValue(0)
  const isDragging = useRef(false)
  const cardWidth = 180,
    gapWidth = 16,
    totalCardWidth = cardWidth + gapWidth
  const maxIndex = prompts.length - 3

  const updatePosition = (newIndex: number) => {
    setCurrentIndex(newIndex)
    animate(x, -newIndex * totalCardWidth, {
      type: 'spring',
      stiffness: 300,
      damping: 30,
    })
  }

  const handleDragEnd = () => {
    isDragging.current = false
    const newIndex = Math.max(
      0,
      Math.min(Math.round(-x.get() / totalCardWidth), maxIndex),
    )
    updatePosition(newIndex)
  }

  return (
    <div
      className="mb-6 transition-all duration-200 ease-in-out"
      style={{ opacity: isHovered ? 1 : 0.85 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center justify-center space-x-4">
        <Button
          isIconOnly
          variant="light"
          onPress={() => updatePosition(Math.max(currentIndex - 1, 0))}
          disabled={currentIndex === 0}
          className="opacity-75 hover:opacity-100 transition-opacity duration-200"
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </Button>
        <div className="overflow-x-hidden w-[590px] px-4">
          <motion.div
            drag="x"
            onDragStart={() => (isDragging.current = true)}
            onDragEnd={handleDragEnd}
            style={{ x }}
            className="flex gap-4 cursor-grab active:cursor-grabbing"
          >
            {prompts.map((prompt, index) => (
              <motion.div key={`${prompt}-${index}`} className="flex-shrink-0">
                <QuickPromptCard
                  text={prompt}
                  onSelect={() => onPromptSelect(prompt)}
                  isDragging={isDragging}
                />
              </motion.div>
            ))}
          </motion.div>
        </div>
        <Button
          isIconOnly
          variant="light"
          onPress={() => updatePosition(Math.min(currentIndex + 1, maxIndex))}
          disabled={currentIndex >= maxIndex}
          className="opacity-75 hover:opacity-100 transition-opacity duration-200"
        >
          <ChevronRightIcon className="h-5 w-5" />
        </Button>
      </div>
    </div>
  )
}

export default QuickPrompts
