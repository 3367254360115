import { createWithEqualityFn } from 'zustand/traditional'
import ToolService from '../services/toolService.ts'
import { AccessRight, Role } from '../interfaces/iShared.ts'
import { iTool } from '../interfaces/iTool.ts'
import { getUserKey } from '../utils/authentication.ts'
import { iDefaultStore } from '@/interfaces/stores/iDefaultStore.ts'

interface iToolState {
  storeIsInitialized: boolean
  isLoading: boolean
  tools: iTool[]
  search: { text: string }
  isLoadingSharedTools: boolean
  isLoadingManagedTools: boolean
  sharedTools: { data: iTool[]; total: number; offset: number }
  managedTools: { data: iTool[]; total: number; offset: number }
}
export interface iToolStore extends iToolState, Omit<iDefaultStore, 'init'> {
  getToolRole: (toolId: string) => Role | undefined
  setIsLoading: (isLoading: boolean) => void
  deleteTool: (id: string) => Promise<void>
  createTool: (tool: iTool) => Promise<iTool>
  updateTool: (tool: iTool) => Promise<iTool>
  hasRightTo: (minRole: Role, toolId: string) => boolean
  setTools: (teams: iTool[]) => void
  loadTools: (options?: {
    accessRight: AccessRight
    searchTerm: string
    offset?: number
  }) => Promise<iTool[]>
  setSearch: (search: { text: string }) => void
}

const initialState: iToolState = {
  isLoading: false,
  storeIsInitialized: false,
  tools: [],
  search: { text: '' },
  sharedTools: {
    data: [],
    total: 0,
    offset: 0,
  },
  managedTools: {
    data: [],
    total: 0,
    offset: 0,
  },
  isLoadingSharedTools: true,
  isLoadingManagedTools: true,
}

const useToolStore = createWithEqualityFn<iToolStore>((set, getState) => ({
  ...initialState,
  reset: () => {
    set(initialState)
  },
  init: async () => {
    getState().reset()
    set({ storeIsInitialized: true })
  },
  setTools: (tools: iTool[]) => set(() => ({ tools })),
  getToolRole: (toolId) => {
    const tool = getState().tools.find((p: iTool) => p.id === toolId)
    const shared = tool?.shared?.find((s) => s.id === getUserKey())

    return shared?.role
  },
  setSearch: (search: { text: string }) => {
    set(() => ({ search }))
  },
  hasRightTo: (minRole: Role, toolId) => {
    const tool = getState().tools.find((p: iTool) => p.id === toolId)
    const isOwner = tool?.ownerId === getUserKey()
    if (isOwner) return true
    const role = getState().getToolRole(toolId)
    if (role === undefined) return false
    return role <= minRole
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  updateTool: async (tool: iTool) => {
    await ToolService.updateTool(tool)
    const updatedManagedTools = getState().managedTools.data.map((a) => {
      if (a.id === tool.id) {
        return { ...a, ...tool }
      }
      return a
    })
    set({
      managedTools: {
        ...getState().managedTools,
        data: updatedManagedTools,
      },
    })

    return tool
  },
  loadTools: async (options?: {
    accessRight?: AccessRight
    searchTerm?: string
    offset?: number
  }) => {
    const offset = options?.offset ?? 0

    if (options?.accessRight === AccessRight.Write) {
      set({
        managedTools: {
          ...getState().managedTools,
          offset,
        },
      })
      set({ isLoadingManagedTools: true })
    }
    if (options?.accessRight === AccessRight.ReadOnly) {
      set({
        sharedTools: {
          ...getState().sharedTools,
          offset,
        },
      })
      set({ isLoadingSharedTools: true })
    }

    const knowledgeContainers = await ToolService.getTools({
      accessRight: options?.accessRight,
      searchValue: options?.searchTerm,
    })
    if (options?.accessRight === AccessRight.Write) {
      const newTools: any =
        offset === 0
          ? knowledgeContainers
          : {
              data: [...getState().managedTools.data, ...knowledgeContainers.data],
              total: knowledgeContainers.total,
            }
      newTools.offset = offset
      set({ managedTools: newTools })
      set({ isLoadingManagedTools: false })
    }
    if (options?.accessRight === AccessRight.ReadOnly) {
      const newTools: any =
        offset === 0
          ? knowledgeContainers
          : {
              data: [...getState().sharedTools.data, ...knowledgeContainers.data],
              total: knowledgeContainers.total,
            }
      newTools.offset = offset

      set({ sharedTools: newTools })
      set({ isLoadingSharedTools: false })
    }
    return knowledgeContainers.data
  },
  deleteTool: async (id) => {
    await ToolService.deleteTool(id)
    const sharedTools = getState().sharedTools.data.filter((tool) => tool.id !== id)
    const managedTools = getState().managedTools.data.filter(
      (tool) => tool.id !== id,
    )
    set((store) => ({
      sharedTools: {
        ...store.sharedTools,
        data: sharedTools,
        total: store.sharedTools.total - 1,
      },
    }))
    set((store) => ({
      managedTools: {
        ...store.managedTools,
        data: managedTools,
        total: store.managedTools.total - 1,
      },
    }))
  },
  createTool: async (tool: iTool) => {
    const newTool = await ToolService.createTool(tool)
    const tmpTools = [...getState().managedTools.data, newTool]
    set((store) => ({
      managedTools: {
        ...store.managedTools,
        data: tmpTools,
        total: store.managedTools.total + 1,
      },
    }))
    return newTool
  },
}))

export default useToolStore
