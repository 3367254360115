import React, { useEffect, useRef, useState } from 'react'

import { TEST_PAGE_DETAIL_ASSISTANT } from '@/constants/testingIds.ts'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AssistantService from '@/services/assistantService.ts'
import AssistantDetailView, {
  AssistantDetailViewHandle,
} from '@components/assistants/AssistantDetailView.tsx'
import SkeletonDetailPage from '@components/skeletons/SkeletonDetailPage.tsx'
import useAssistantModelStore from '@states/assistantModelStore.ts'
import { getUser, getUserEmail } from '@/utils/authentication.ts'
import { ROUTE_MARKETPLACE_ASSISTANTS } from '@/constants/routes.ts'
import { Role } from '@/interfaces/iShared.ts'

const AssistantDetailPage = () => {
  const { state } = useLocation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [assistant, setAssistant] = useState<iAssistant | undefined>()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const { id } = useParams()
  const viewRef = useRef<AssistantDetailViewHandle>(null)
  const assistantModels = useAssistantModelStore().assistantModels
  const defaultModel = useAssistantModelStore().getDefaultModel()
  const navigate = useNavigate()

  useEffect(() => {
    if (!assistantModels.length) return
    // create an empty default assistant
    if (!id || id === 'new') {
      setIsEditMode(true)
      const defaultAssistant: iAssistant = {
        title: 'Default Assistant',
        ownerEmail: getUserEmail() as string,
        ownerName: getUser().displayName as string,
        configuration: {
          tools: { visible: true, editable: true },
          knowledgeContainer: { visible: true, editable: true },
          modelSettings: { visible: true, editable: true },
        },
        settings: {
          modelId: defaultModel.id,
          maxTokens: defaultModel.maxOutputTokens,
          temperature: 1,
          topP: 0.95,
          transferLength: 21,
          synthesizeAudio: false,
          stream: true,
        },
      }
      setAssistant(defaultAssistant)
      setIsLoading(false)
      return
    } else {
      AssistantService.getAssistant(id)
        .then((data) => {
          setAssistant(data)
        })
        .finally(() => setIsLoading(false))
    }
  }, [id, assistantModels.length])

  useEffect(() => {
    if (state?.isEditMode) setIsEditMode(state?.isEditMode)
  }, [state])

  return (
    <DefaultPageLayout
      notFound={!assistant && !isLoading}
      editMode={isEditMode}
      title={assistant?.title ?? 'Assistant'}
      data-testid={TEST_PAGE_DETAIL_ASSISTANT}
      editAble={assistant?.userRole! >= Role.Manager}
      deleteAble={assistant?.userRole! === Role.Owner}
      copyToClipboard={assistant?.id}
      onDeleteClick={() => {
        viewRef.current?.deleteAssistant().then((assistant) => {
          navigate(`${ROUTE_MARKETPLACE_ASSISTANTS}`)
        })
      }}
      onCancelClick={() => {
        if (!id) navigate(-1)
      }}
      onSaveClick={async () => {
        viewRef.current?.saveAssistant().then((assistant) => {
          if (assistant)
            navigate(`${ROUTE_MARKETPLACE_ASSISTANTS}/${assistant.id}`, {
              replace: true,
            })
          setAssistant(assistant)
        })
      }}
    >
      {(selectedTab, isEditMode) => {
        return isLoading ? (
          <SkeletonDetailPage></SkeletonDetailPage>
        ) : (
          <AssistantDetailView
            isDisabled={!isEditMode}
            ref={viewRef}
            value={assistant!}
          ></AssistantDetailView>
        )
      }}
    </DefaultPageLayout>
  )
}

export default AssistantDetailPage
