import React from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  Checkbox,
} from '@nextui-org/react'

interface GenerateTemplateModalProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  templatePrompt: string
  setTemplatePrompt: (value: string) => void
  includeExistingTemplate: boolean
  setIncludeExistingTemplate: (value: boolean) => void
  isLoading: boolean
  onGenerate: () => void
}

/**
 * Modal for generating Handlebars templates via assistant.
 */
const GenerateTemplateModal: React.FC<GenerateTemplateModalProps> = ({
  isOpen,
  onOpenChange,
  templatePrompt,
  setTemplatePrompt,
  includeExistingTemplate,
  setIncludeExistingTemplate,
  isLoading,
  onGenerate,
}) => {
  return (
    <Modal
      isDismissable={false}
      isKeyboardDismissDisabled={true}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Generate Handlebars Template
            </ModalHeader>
            <ModalBody>
              <Textarea
                label="Prompt for Styling (Optional)"
                value={templatePrompt}
                onChange={(e) => setTemplatePrompt(e.target.value)}
                placeholder="Enter any additional instructions for styling"
                rows={4}
              />
              <Checkbox
                className="mt-4"
                isSelected={includeExistingTemplate}
                onValueChange={setIncludeExistingTemplate}
              >
                Include existing template for modifications
              </Checkbox>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                variant="light"
                onPress={() => {
                  setTemplatePrompt('')
                  onClose()
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                isLoading={isLoading}
                onPress={() => {
                  onGenerate()
                  setTemplatePrompt('')
                  onClose()
                }}
              >
                Generate
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default GenerateTemplateModal
