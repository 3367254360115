export const ROUTE_MARKETPLACE = '/marketplace'
export const ROUTE_MARKETPLACE_ASSISTANTS = `${ROUTE_MARKETPLACE}/assistants`
export const ROUTE_MARKETPLACE_ASSISTANTS_NEW = `${ROUTE_MARKETPLACE_ASSISTANTS}/new`
export const ROUTE_MARKETPLACE_ASSISTANTS_ID = `${ROUTE_MARKETPLACE_ASSISTANTS}/:id`

export const ROUTE_MARKETPLACE_TOOLS = `${ROUTE_MARKETPLACE}/tools`
export const ROUTE_MARKETPLACE_TOOLS_NEW = `${ROUTE_MARKETPLACE_TOOLS}/new`
export const ROUTE_MARKETPLACE_TOOLS_ID = `${ROUTE_MARKETPLACE_TOOLS}/:id`

export const ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS = `${ROUTE_MARKETPLACE}/knowledge-containers`
export const ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS_NEW = `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/new`
export const ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS_ID = `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/:id`

export const ROUTE_FORBIDDEN = `/forbidden`
export const ROUTE_FAQ = `/faq`
export const ROUTE_USER_SETTINGS = `/user-settings`
export const ROUTE_UPS = `/ups`
export const ROUTE_ADMIN_PANEL = `/admin-panel`

export const ROUTE_FEATURE_VOTING = `/feature-voting`
