import { createWithEqualityFn } from 'zustand/traditional'
import { AccessRight, Role } from '../interfaces/iShared.ts'
import { iKnowledgeContainer } from '../interfaces/iKnowledgeContainer.ts'
import { getUserKey } from '../utils/authentication.ts'
import { iDefaultStore } from '@/interfaces/stores/iDefaultStore.ts'
import KnowledgeContainerService from '../services/knowledgeContainerService.ts'

interface iKnowledgeContainerState {
  storeIsInitialized: boolean
  isLoading: boolean
  search: { text: string }
  isLoadingSharedKnowledgeContainers: boolean
  isLoadingManagedKnowledgeContainers: boolean
  sharedKnowledgeContainers: {
    data: iKnowledgeContainer[]
    total: number
    offset: number
  }
  managedKnowledgeContainers: {
    data: iKnowledgeContainer[]
    total: number
    offset: number
  }
}
interface iKnowledgeContainerStore
  extends iKnowledgeContainerState,
    Omit<iDefaultStore, 'init'> {
  getKnowledgeContainerRole: (container: iKnowledgeContainer) => Role | undefined
  hasRightTo: (minRole: Role, container: iKnowledgeContainer) => boolean
  deleteKnowledgeContainer: (id: string) => Promise<void>
  updateKnowledgeContainer: (
    knowledgeContainer: iKnowledgeContainer,
  ) => Promise<iKnowledgeContainer>
  createKnowledgeContainer: (
    knowledgeContainer: iKnowledgeContainer,
  ) => Promise<iKnowledgeContainer>

  loadKnowledgeContainers: (options?: {
    accessRight?: AccessRight
    searchTerm?: string
    offset?: number
  }) => Promise<iKnowledgeContainer[]>
  setSearch: (search: { text: string }) => void
}

const initialState: iKnowledgeContainerState = {
  storeIsInitialized: false,
  isLoading: true,
  search: { text: '' },
  sharedKnowledgeContainers: {
    data: [],
    total: 0,
    offset: 0,
  },
  managedKnowledgeContainers: {
    data: [],
    total: 0,
    offset: 0,
  },
  isLoadingSharedKnowledgeContainers: true,
  isLoadingManagedKnowledgeContainers: true,
}
const useKnowledgeContainerStore = createWithEqualityFn<iKnowledgeContainerStore>(
  (set, getState) => ({
    ...initialState,
    reset: () => {
      set(initialState)
    },
    init: async () => {
      getState().reset()
    },
    setSearch: (search: { text: string }) => {
      set(() => ({ search }))
    },
    getKnowledgeContainerRole: (container: iKnowledgeContainer) => {
      const shared = container?.shared?.find((s) => s.id === getUserKey())

      return shared?.role
    },
    hasRightTo: (minRole: Role, container: iKnowledgeContainer) => {
      const isOwner = container?.ownerId === getUserKey()
      if (isOwner) return true
      const role = getState().getKnowledgeContainerRole(container)
      if (role === undefined) return false
      return role <= minRole
    },
    loadKnowledgeContainers: async (options?: {
      accessRight?: AccessRight
      searchTerm?: string
      offset?: number
    }) => {
      const offset = options?.offset ?? 0

      if (options?.accessRight === AccessRight.Write) {
        set({
          managedKnowledgeContainers: {
            ...getState().managedKnowledgeContainers,
            offset,
          },
        })
        set({ isLoadingManagedKnowledgeContainers: true })
      }
      if (options?.accessRight === AccessRight.ReadOnly) {
        set({
          sharedKnowledgeContainers: {
            ...getState().sharedKnowledgeContainers,
            offset,
          },
        })
        set({ isLoadingSharedKnowledgeContainers: true })
      }

      const knowledgeContainers =
        await KnowledgeContainerService.getKnowledgeContainers({
          accessRight: options?.accessRight,
          searchValue: options?.searchTerm,
        })
      if (options?.accessRight === AccessRight.Write) {
        //TODO: fix any
        const newKnowledgeContainers: any =
          offset === 0
            ? knowledgeContainers
            : {
                data: [
                  ...getState().managedKnowledgeContainers.data,
                  ...knowledgeContainers.data,
                ],
                total: knowledgeContainers.total,
              }
        newKnowledgeContainers.offset = offset
        set({ managedKnowledgeContainers: newKnowledgeContainers })
        set({ isLoadingManagedKnowledgeContainers: false })
      }
      if (options?.accessRight === AccessRight.ReadOnly) {
        //TODO: fix any
        const newKnowledgeContainers: any =
          offset === 0
            ? knowledgeContainers
            : {
                data: [
                  ...getState().sharedKnowledgeContainers.data,
                  ...knowledgeContainers.data,
                ],
                total: knowledgeContainers.total,
              }
        newKnowledgeContainers.offset = offset

        set({ sharedKnowledgeContainers: newKnowledgeContainers })
        set({ isLoadingSharedKnowledgeContainers: false })
      }
      return knowledgeContainers.data
    },
    deleteKnowledgeContainer: async (id) => {
      await KnowledgeContainerService.deleteKnowledgeContainer(id)
      const sharedKnowlegdeContainers =
        getState().sharedKnowledgeContainers.data.filter(
          (assistant) => assistant.id !== id,
        )
      const managedKnowledgeContainers =
        getState().managedKnowledgeContainers.data.filter(
          (assistant) => assistant.id !== id,
        )
      set((store) => ({
        sharedKnowledgeContainers: {
          ...store.sharedKnowledgeContainers,
          data: sharedKnowlegdeContainers,
          total: store.sharedKnowledgeContainers.total - 1,
        },
      }))
      set((store) => ({
        managedKnowledgeContainers: {
          ...store.managedKnowledgeContainers,
          data: managedKnowledgeContainers,
          total: store.managedKnowledgeContainers.total - 1,
        },
      }))
    },
    updateKnowledgeContainer: async (knowledgeContainer: iKnowledgeContainer) => {
      await KnowledgeContainerService.updateKnowledgeContainer(knowledgeContainer)
      const updatedManagedTools = getState().managedKnowledgeContainers.data.map(
        (a) => {
          if (a.id === knowledgeContainer.id) {
            return { ...a, ...knowledgeContainer }
          }
          return a
        },
      )
      set({
        managedKnowledgeContainers: {
          ...getState().managedKnowledgeContainers,
          data: updatedManagedTools,
        },
      })

      return knowledgeContainer
    },
    createKnowledgeContainer: async (knowledgeContainer: iKnowledgeContainer) => {
      const newKnowledgeContainer =
        await KnowledgeContainerService.createKnowledgeContainer(knowledgeContainer)
      const tmpKnowledgeContainer = [
        ...getState().managedKnowledgeContainers.data,
        newKnowledgeContainer,
      ]
      set((store) => ({
        managedKnowledgeContainers: {
          ...store.managedKnowledgeContainers,
          data: tmpKnowledgeContainer,
          total: store.managedKnowledgeContainers.total + 1,
        },
      }))
      return newKnowledgeContainer
    },
  }),
)

export default useKnowledgeContainerStore
