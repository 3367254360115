import React from 'react'

import { Select, SelectItem, Tooltip } from '@nextui-org/react'
import { InformationCircleIcon } from '@heroicons/react/24/solid'

const tooltipDocumentAnalysis =
  'The "read" model costs about 1.41€ per 1000 pages and is suitable for extracting text and structure. The "layout" model costs around 14,98€ per 1000 pages, provides detailed layout information including tables and forms, supports math formula recognition within PDFs, and converts the output to Markdown for better understanding and extraction by language models.'

export type DocumentAnalysisModelId = 'prebuilt-layout' | 'prebuilt-read'

type DocumentAnalysisSelectionProps = React.HTMLAttributes<HTMLDivElement> & {
  onSelectionChange: (analysisType: DocumentAnalysisModelId) => void
  isRequired?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  validate?: (
    value: string | string[],
  ) => string | string[] | true | null | undefined
}

function DocumentAnalysisSelection({
  onSelectionChange,
  defaultValue,
  isLoading,
  validate,
  isRequired,
  isDisabled,
}: DocumentAnalysisSelectionProps) {
  const documentAnalysisItems: { value: DocumentAnalysisModelId; text: string }[] = [
    { value: 'prebuilt-read', text: 'Read' },
    { value: 'prebuilt-layout', text: 'Layout' },
  ]

  return (
    <>
      <div className={'w-full '}>
        <label className="label ">
          <span className="label-text flex"></span>
          <Select
            label="Document Analysis Method"
            startContent={
              <Tooltip content={tooltipDocumentAnalysis} className={'max-w-96'}>
                <InformationCircleIcon
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.0}
                  stroke="currentColor"
                  className="w-4 h-4 ml-1"
                />
              </Tooltip>
            }
            aria-label="Select PDF Document Analysis"
            validate={validate}
            defaultSelectedKeys={[defaultValue] as Iterable<string | number>}
            items={documentAnalysisItems}
            className="w-1/2 max-w-xs"
            placeholder={'Select PDF Document Analysis'}
            isRequired={isRequired}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onChange={(e) =>
              onSelectionChange(e.target.value as DocumentAnalysisModelId)
            }
          >
            {(shareType) => (
              <SelectItem
                key={shareType.value}
                textValue={shareType.text}
                value={shareType.value}
              >
                <div className="flex gap-2 items-center">
                  <div className="flex flex-col truncate">
                    <span className="text-small truncate">{shareType.text}</span>
                  </div>
                </div>
              </SelectItem>
            )}
          </Select>
        </label>
      </div>
    </>
  )
}

export default DocumentAnalysisSelection
