import React, { useMemo } from 'react'
import { Card } from '@nextui-org/react'

type JsonDisplayProps = {
  jsonString?: string
  jsonData?: any
}

const JsonDisplay: React.FC<JsonDisplayProps> = ({ jsonString, jsonData }) => {
  const data = useMemo(() => {
    if (jsonData) {
      return jsonData
    } else if (jsonString) {
      let data = null
      try {
        data = JSON.parse(jsonString)
      } catch (error) {
        data = jsonString
      }
      return data
    }
  }, [jsonData, jsonString])
  const renderJson = (obj: any, depth = 0): JSX.Element => {
    if (obj === null) return <span className="text-error">null</span>
    if (typeof obj === 'boolean')
      return <span className="text-default">{obj.toString()}</span>
    if (typeof obj === 'number') return <span className="text-warning">{obj}</span>
    if (typeof obj === 'string') return <span className=" dark:text">"{obj}"</span>

    if (Array.isArray(obj)) {
      return (
        <span>
          [
          <div style={{ paddingLeft: '20px' }}>
            {obj.map((item, index) => (
              <div key={index}>
                {renderJson(item, depth + 1)}
                {index < obj.length - 1 && ','}
              </div>
            ))}
          </div>
          ]
        </span>
      )
    }

    if (typeof obj === 'object') {
      return (
        <span>
          {'{'}
          <div style={{ paddingLeft: '20px' }}>
            {Object.entries(obj).map(([key, value], index, array) => (
              <div key={key}>
                <span className="text-blue-600 dark:text-teal-300">"{key}"</span>:{' '}
                {renderJson(value, depth + 1)}
                {index < array.length - 1 && ','}
              </div>
            ))}
          </div>
          {'}'}
        </span>
      )
    }

    return <span>{String(obj)}</span>
  }

  return (
    <Card className="p-2 bg-content1">
      <div className="max-h-96 overflow-auto">
        <pre className="font-mono text-sm whitespace-pre-wrap">
          {data ? renderJson(data) : 'Invalid JSON'}
        </pre>
      </div>
    </Card>
  )
}

export default JsonDisplay
