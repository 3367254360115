import React, { useState } from 'react'
import { ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline'
import './copyToClipboardStyle.css'
import { Button, Tooltip } from '@nextui-org/react'
import { twMerge } from 'tailwind-merge'
import { toast } from 'react-toastify'

type ClipboardCopyButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  textToCopy: string
  tooltip?: string
  isDisabled?: boolean
  label?: string
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | undefined
  variant?:
    | 'light'
    | 'shadow'
    | 'solid'
    | 'bordered'
    | 'flat'
    | 'faded'
    | 'ghost'
    | undefined
  Callback?: () => void
}

function ClipboardCopyButton({
  className,
  tooltip,
  isDisabled,
  textToCopy,
  Callback,
  variant = 'light',
  color,
  label,
}: ClipboardCopyButtonProps) {
  const [copyState, setCopyState] = useState(0)

  const copyToClipboard = async () => {
    try {
      navigator.clipboard.writeText(textToCopy).then(
        () => {
          // toast.success('Copied to clipboard')
        },
        (err) => {
          console.error('Failed to copy text: ', err)
          toast.error('Failed to copy')
        },
      )
      if (Callback) Callback()
      setCopyState(1)
      setTimeout(() => setCopyState(2), 3000) // Reset the copied stores after 3 seconds
      setTimeout(() => setCopyState(0), 4000) // Reset the copied stores after 4 seconds
    } catch (err) {
      console.error('Failed to copy text to clipboard', err)
    }
  }
  const classes = twMerge('', className)
  return (
    <Tooltip content={tooltip} isDisabled={!tooltip} className={'max-w-96'}>
      <Button
        isIconOnly={!label}
        className={classes}
        isDisabled={isDisabled}
        variant={variant}
        color={color}
        onPress={copyToClipboard}
      >
        {copyState === 1 ? (
          <CheckIcon className="w-5 h-5 icon-rotate" color="green" />
        ) : copyState === 2 ? (
          <ClipboardIcon className="w-5 h-5 icon-rotate" />
        ) : (
          <ClipboardIcon className="w-5 h-5" />
        )}
        {label}
      </Button>
    </Tooltip>
  )
}

export default ClipboardCopyButton
