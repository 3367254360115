import React, { useCallback, useEffect, useState } from 'react'
import {
  MagnifyingGlassIcon,
  PencilIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import UserManagementUsage from './UserManagementUsage.tsx'
import UserManagementCreateEdit from './UserManagementCreateEdit.tsx'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Progress,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import { SettingsProps } from '@/interfaces/settingsProps.ts'
import useTeamStore from '@/states/teamStore.ts'
import { iTeam } from '@/interfaces/iTeam.ts'
import { UserManagementState } from '@/interfaces/iUserManagement.ts'
import { AuthRole, getUserKey } from '@/utils/authentication.ts'
import { iShared, Role } from '@/interfaces/iShared.ts'
import TeamService from '@/services/teamService.ts'
import useUserProfileStore from '@/states/userProfileState.ts'
import { ICON_DELETE } from '@/constants/icons.tsx'

const UserManagementList = ({ closeFunction }: SettingsProps) => {
  const { teams, getTeams, deleteTeam } = useTeamStore(
    (state) => ({
      teams: state.teams,
      getTeams: state.getTeams,
      deleteTeam: state.deleteTeam,
    }),
    shallow,
  )
  const [userTeamLoading, setTeamLoading] = useState<boolean>(false)
  const [localTeams, setLocalTeams] = useState<iTeam[]>([])
  const [userManagementState, setUserManagementState] =
    useState<UserManagementState>(UserManagementState.List)
  const [selectedTeam, setSelectedTeam] = useState<iTeam | undefined>(undefined)
  const userProfile = useUserProfileStore().userProfile
  useEffect(() => {
    setLocalTeams(teams)
    setTeamLoading(false)
  }, [teams])

  const handleCancelButtonClick = () => {
    if (userManagementState === UserManagementState.List) {
      closeFunction()
      return
    }
    setUserManagementState(UserManagementState.List)
  }

  const handleOnSaveButtonClick = async () => {
    await getTeams()
    setUserManagementState(UserManagementState.List)
  }

  const deleteTeamByName = async (team: iTeam) => {
    if (!confirm('Are you sure you want to delete this team?')) {
      return
    }
    await deleteTeam(team.id!)
  }

  const addTeamFunction = () => {
    setUserManagementState(UserManagementState.Create)
  }

  const setLoading = () => {
    setTeamLoading(true)
  }
  const defaultTeam: iTeam = {
    name: '',
    description: '',
    costCenter: '',
    spent: 0,
    budget: 0,
    shareLinks: [],
    shared: [],
    resourceRestrictions: {
      assistantIds: null,
      knowledgeContainerIds: null,
      modelIds: null,
      toolIds: null,
    },
    ownerId: getUserKey(),
    roles: [
      { type: Role.Manager, permissions: [] },
      { type: Role.Member, permissions: [] },
      { type: Role.LimitedMember, permissions: [] },
    ],
  }

  const allowEdit = useCallback((team: iTeam) => {
    const userId = getUserKey()
    const user = team.shared.find((user: iShared) => user.id === userId)
    return user?.role === Role.Manager || team.ownerId === userId
  }, [])

  const allowDelete = useCallback((team: iTeam) => {
    const userId = getUserKey()
    return team.ownerId === userId
  }, [])
  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={handleCancelButtonClick}
      size={undefined}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
    >
      <ModalContent>
        <>
          {userManagementState === UserManagementState.List && (
            <>
              <ModalHeader>
                <div className={'flex flex-col'}>
                  <h1 className={'text-xl font-semibold mb-2'}>Teams</h1>
                  <p className={''}>Manage your teams and their budgets.</p>
                  {userProfile!.role === AuthRole.Admin && (
                    <div className={'text-red-500'}>
                      You are logged in as admin.
                      <Button
                        color="danger"
                        className="m-3 "
                        onPress={() => {
                          setUserManagementState(UserManagementState.Usage)
                          const adminAll = {
                            name: 'adminAll',
                            id: 'supergeheimeradmin',
                          } as iTeam
                          setSelectedTeam(adminAll)
                        }}
                      >
                        Show All Tokens
                      </Button>
                      <Button
                        color="danger"
                        className="m-3 "
                        onPress={() => {
                          TeamService.getTeams(true).then((response) => {
                            setLocalTeams(response.data)
                            setTeamLoading(false)
                          })
                        }}
                      >
                        Show All Teams
                      </Button>
                    </div>
                  )}
                </div>
              </ModalHeader>
              <ModalBody>
                <>
                  {/* <div className={"w-full "}>
                    <input type="text" placeholder="Search for Team" className="input input-bordered w-full " />
                </div> */}
                  {userTeamLoading && (
                    <div className={'w-full grid'}>
                      <span className="loading loading-spinner loading-lg place-self-center"></span>
                    </div>
                  )}
                  {!userTeamLoading && localTeams.length > 0 && (
                    <div className="overflow-x-auto">
                      <Table
                        removeWrapper
                        aria-label="Example static collection table"
                      >
                        <TableHeader>
                          <TableColumn>Name</TableColumn>
                          <TableColumn>Cost Center</TableColumn>
                          <TableColumn>Monthly Bugdet</TableColumn>
                          <TableColumn>Actions</TableColumn>
                        </TableHeader>
                        <TableBody>
                          {localTeams.map((team, key) => {
                            return (
                              <TableRow key={key}>
                                <TableCell>{team.name}</TableCell>
                                <TableCell>{team.costCenter}</TableCell>
                                <TableCell>
                                  <Tooltip
                                    content={team.spent + ' / ' + team.budget + ' €'}
                                    className={'max-w-96'}
                                  >
                                    <Progress
                                      className="w-60"
                                      size="md"
                                      aria-label="Loading..."
                                      value={team.spent}
                                      maxValue={team.budget}
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell>
                                  <div className="relative flex items-center gap-2">
                                    <Button
                                      isIconOnly
                                      variant={'light'}
                                      onPress={() => {
                                        setUserManagementState(
                                          UserManagementState.Usage,
                                        )
                                        setSelectedTeam(team)
                                      }}
                                    >
                                      <MagnifyingGlassIcon className="w-5 h-5" />
                                    </Button>
                                    {allowEdit(team) && (
                                      <Button
                                        isIconOnly
                                        variant={'light'}
                                        onPress={() => {
                                          setUserManagementState(
                                            UserManagementState.Edit,
                                          )
                                          setSelectedTeam(team)
                                        }}
                                      >
                                        <PencilIcon className="w-5 h-5" />
                                      </Button>
                                    )}
                                    {allowDelete(team) && (
                                      <Button
                                        isIconOnly
                                        variant={'light'}
                                        color={'danger'}
                                        onPress={() => {
                                          deleteTeamByName(team)
                                        }}
                                      >
                                        <ICON_DELETE />
                                      </Button>
                                    )}
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {
                    // only admins and billingGroupManagers can add new teams
                    userProfile!.role === AuthRole.Admin && (
                      <div className="w-full  grid place-items-center">
                        <Button
                          className={'m-1'}
                          fullWidth
                          isIconOnly
                          variant={'light'}
                          color={'primary'}
                          onPress={addTeamFunction}
                        >
                          <PlusCircleIcon className="w-8 h-8" />
                        </Button>
                      </div>
                    )
                  }
                </>
              </ModalBody>
              <ModalFooter>
                <Button onPress={handleCancelButtonClick}>Close</Button>
              </ModalFooter>
            </>
          )}
          {userManagementState === UserManagementState.Usage && (
            <>
              <UserManagementUsage
                closeFunction={handleCancelButtonClick}
                submitFunction={handleOnSaveButtonClick}
                setLoading={setLoading}
                team={selectedTeam}
              />
            </>
          )}
          {userManagementState === UserManagementState.Edit && (
            <>
              <UserManagementCreateEdit
                submitFunction={handleOnSaveButtonClick}
                closeFunction={handleCancelButtonClick}
                setLoading={setLoading}
                mode={UserManagementState.Edit}
                team={selectedTeam}
              />
            </>
          )}
          {userManagementState === UserManagementState.Create && (
            <>
              <UserManagementCreateEdit
                submitFunction={handleOnSaveButtonClick}
                closeFunction={handleCancelButtonClick}
                setLoading={setLoading}
                mode={UserManagementState.Create}
                team={defaultTeam}
              />
            </>
          )}
        </>
      </ModalContent>
    </Modal>
  )
}

export default UserManagementList
