import React, { useEffect, useRef, useState, useCallback } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import ChatMessage from './ChatMessage'
import useUserProfileStore from '@/states/userProfileState'
import useChatStore from '@/states/chatStore'
import './chatWindow.css'
import { Skeleton } from '@nextui-org/react'
import { shallow } from 'zustand/shallow'

type ChatMessageWrapperProps = {
  isLoading?: boolean
  chatWindowRef: React.RefObject<HTMLDivElement>
}

const ChatMessageWrapper = ({
  isLoading,
  chatWindowRef,
}: ChatMessageWrapperProps) => {
  // Chat data
  const userProfile = useUserProfileStore().userProfile
  const {
    transferLength,
    messages,
    setMessagesInView,
    messageToScrollToId,
    goToMessageById,
  } = useChatStore(
    (state) => ({
      transferLength: state.assistant?.settings?.transferLength,
      messages: state.messages,
      setMessagesInView: state.setMessagesInView,
      messageToScrollToId: state.messageToScrollToId,
      goToMessageById: state.goToMessageById,
    }),
    shallow,
  )

  const [hoveredMessageId, setHoveredMessageId] = useState<string | null>(null)
  const messageRefs = useRef<{ [id: string]: HTMLDivElement | null }>({})

  const willMessageTransferred = useCallback(
    (index: number, single: boolean = false) => {
      if (!transferLength) return false
      if (transferLength === 21) return !single
      return single
        ? index === messages.length - transferLength
        : index >= messages.length - transferLength
    },
    [transferLength, messages.length],
  )

  useEffect(() => {
    if (!chatWindowRef.current) return

    const observer = new IntersectionObserver(
      (entries) => {
        const visibleAssistant: { id: string; top: number }[] = []
        for (const entry of entries) {
          if (entry.isIntersecting) {
            const elMsgId = entry.target.getAttribute('data-message-id')!
            const msg = messages.find((m) => m.id === elMsgId)
            if (msg?.role === 'assistant') {
              visibleAssistant.push({
                id: elMsgId,
                top: entry.boundingClientRect.top,
              })
            }
          }
        }

        if (visibleAssistant.length === 0) {
          return
        }

        setMessagesInView(visibleAssistant.map((m) => m.id))
      },
      {
        root: chatWindowRef.current,
        rootMargin: '10% 0% 0% 0%', // 10% from the bottom
        threshold: 0.1, // 10% of the message must be visible
      },
    )

    Object.values(messageRefs.current).forEach((node) => {
      if (node) observer.observe(node)
    })

    return () => {
      observer.disconnect()
    }
  }, [hoveredMessageId, messages, chatWindowRef])

  const handleMouseEnter = (msgId: string) => {
    const msg = messages.find((m) => m.id === msgId)
    if (!msg || msg.role !== 'assistant') {
      return
    }
    setHoveredMessageId(msgId)
  }

  // If the user wants to jump to a specific message:
  useEffect(() => {
    if (messageToScrollToId) {
      const el = messageRefs.current[messageToScrollToId]
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      goToMessageById(null)
    }
  }, [messageToScrollToId, goToMessageById])

  return (
    <div className="flex-1 flex flex-col justify-start">
      {isLoading ? (
        <>
          <Skeleton className="h-5 w-full rounded" />
          <Skeleton className="h-5 w-full" />
        </>
      ) : (
        messages
          .slice()
          .reverse() // So index=0 is actually the last original message
          .map((msg, index) => {
            const key = msg._tmpId ?? msg.id
            const setRef = (node: HTMLDivElement | null) => {
              messageRefs.current[msg.id!] = node
            }
            return (
              <div
                key={key}
                ref={setRef}
                data-message-id={msg.id}
                onMouseEnter={() => handleMouseEnter(msg.id!)}
              >
                {willMessageTransferred(index, true) && (
                  <div className="divider pt-6 transition">
                    <div className="flex flex-col items-center justify-center text-gray-400">
                      <div className="flex-1">Transferred</div>
                      <ChevronDownIcon className="flex-1 w-5 h-5" />
                    </div>
                  </div>
                )}
                <ChatMessage
                  transfered={willMessageTransferred(index)}
                  message={{ ...msg }}
                  isLastMessage={index === messages.length - 1}
                  user={userProfile!}
                />
              </div>
            )
          })
      )}
    </div>
  )
}

export default React.memo(ChatMessageWrapper)
