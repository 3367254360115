import React from 'react'
import { Select, Input, SelectItem } from '@nextui-org/react'

interface Parameter {
  name: string
  parameter: {
    enum?: string[]
    required?: boolean
  }
}

interface ParameterFieldsProps {
  parameters: Parameter[]
  parameterValues: Record<string, any>
  onParameterChange: (name: string, value: any) => void
}

/**
 * Renders input or dropdown fields for an array of parameters.
 */
const ParameterFields: React.FC<ParameterFieldsProps> = ({
  parameters,
  parameterValues,
  onParameterChange,
}) => {
  return (
    <>
      {parameters.map((param) => {
        const { enum: enumValues, required } = param.parameter
        if (enumValues && enumValues.length > 0) {
          return (
            <div key={param.name} className="m-1">
              <Select
                isRequired={required}
                className="w-full"
                label={param.name}
                placeholder={`Select ${param.name}`}
                selectedKeys={
                  parameterValues[param.name]
                    ? new Set([parameterValues[param.name]])
                    : undefined
                }
                onSelectionChange={(keys) =>
                  onParameterChange(param.name, Array.from(keys)[0])
                }
              >
                {enumValues.map((option: string) => (
                  <SelectItem key={option}>{option}</SelectItem>
                ))}
              </Select>
            </div>
          )
        } else {
          return (
            <div key={param.name} className="m-1">
              <Input
                isRequired={required}
                label={param.name}
                value={parameterValues[param.name] || ''}
                onChange={(e) => onParameterChange(param.name, e.target.value)}
              />
            </div>
          )
        }
      })}
    </>
  )
}

export default ParameterFields
