import React, { useEffect, useState } from 'react'
import {
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Menu,
  MenuItem,
  Button,
} from '@nextui-org/react'
import FaqItem from '../components/faq/FaqItem.tsx'
import { iParsedFaq } from '../interfaces/iFAQ.ts'
import { TEST_PAGE_FAQ } from '../constants/testingIds.ts'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import { getFAQs } from '@/utils/getLocalFaqs.ts'
import { ICON_SEARCH } from '@/constants/icons.tsx'

function FaqPage() {
  const [searchValue, setSearchValue] = useState('')
  const [allFaqs, setAllFaqs] = useState<iParsedFaq[]>([])
  const [filteredFaqs, setFilteredFaqs] = useState<iParsedFaq[]>([])
  const [searchField, setSearchField] = useState('all') // State for selected search field
  const [isPopoverOpen, setIsPopoverOpen] = useState(false) // State for Popover open/close

  useEffect(() => {
    const parsedFaqs = getFAQs()
    setAllFaqs(parsedFaqs)
    setFilteredFaqs(parsedFaqs)
  }, [])

  const handleSearch = (text: string) => {
    setSearchValue(text)

    const lowerText = text.toLowerCase()

    const filtered = allFaqs.filter((faq) => {
      if (searchField === 'all') {
        // Search in all fields
        const faqText =
          `${faq.title} ${faq.description} ${faq.content} ${faq.categories.join(
            ' ',
          )}`.toLowerCase()
        return faqText.includes(lowerText)
      } else {
        // Search in selected field
        let faqFieldValue
        if (searchField === 'category') {
          faqFieldValue = faq.categories.join(' ').toLowerCase()
        } else {
          faqFieldValue = faq[searchField as keyof iParsedFaq]
          faqFieldValue =
            typeof faqFieldValue === 'string' ? faqFieldValue.toLowerCase() : ''
        }
        return faqFieldValue.includes(lowerText)
      }
    })
    setFilteredFaqs(filtered)
  }

  const searchFields = ['all', 'title', 'category', 'content', 'description']

  return (
    <DefaultPageLayout title="FAQs" data-testid={TEST_PAGE_FAQ}>
      <div className="flex-1 mr-2 my-6">
        <div className="relative">
          <Input
            isClearable
            placeholder={`Search ${
              searchField === 'all'
                ? ''
                : 'in ' + searchField.charAt(0).toUpperCase() + searchField.slice(1)
            }...`}
            className="flex-1"
            classNames={{
              inputWrapper: 'h-12 shadow-none',
            }}
            value={searchValue}
            onValueChange={(value) => handleSearch(value)}
            startContent={
              <Popover
                placement="bottom-start"
                isOpen={isPopoverOpen}
                onOpenChange={setIsPopoverOpen}
              >
                <PopoverTrigger>
                  <Button
                    isIconOnly
                    variant="light"
                    className="focus:outline-none"
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <ICON_SEARCH />
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <Menu
                    aria-label="Search Fields"
                    onAction={(field) => {
                      setSearchField(field as string)
                      setIsPopoverOpen(false) // Close the Popover after selection
                    }}
                    selectedKeys={new Set([searchField])}
                    disallowEmptySelection
                    selectionMode="single"
                  >
                    {searchFields.map((field) => (
                      <MenuItem key={field}>
                        {field === 'all'
                          ? 'All Fields'
                          : field.charAt(0).toUpperCase() + field.slice(1)}
                      </MenuItem>
                    ))}
                  </Menu>
                </PopoverContent>
              </Popover>
            }
          />
        </div>
      </div>
      <div className="flex flex-col items-center w-full pb-24  gap-4">
        {filteredFaqs.map((faq) => (
          <div key={faq.title} className="flex flex-col w-full">
            <FaqItem markdown={faq.markdown} />
          </div>
        ))}
      </div>
    </DefaultPageLayout>
  )
}

export default FaqPage
