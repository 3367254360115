import React, { useState } from 'react'
import UserService from '../../services/UserService.ts'
import { EntityType, iRole, iShared, Role } from '@/interfaces/iShared.ts'
import {
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import MemberRoleSelection from './MemberRoleSelection.tsx'
import useTeamStore from '@/states/teamStore.ts'
import { shallow } from 'zustand/shallow'
import { iUser } from '@/interfaces/iUser.ts'
import { iTeam } from '@/interfaces/iTeam.ts'
import UserDisplay from '@components/user/UserDisplay.tsx'
import { ICON_DELETE } from '@/constants/icons.tsx'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'
import ItemSelection from '@components/tools/ItemSelection.tsx'
import { iItem } from '@/interfaces/iItem.ts'
import TeamService from '@/services/teamService.ts'

const tooltipImportMembers =
  'Import members by any text-based file. The members must be separated by a comma or semicolon. The list may contain email addresses or names. The members will be added to the list of members. You can add members by email address or name. You can add Dräger members by email address as placeholder.'

type MembersSelectionProps = {
  shared: iShared[]
  onSelectionChange: (members: iShared[]) => void
  writeOnly?: boolean
  ownerId?: string
  roles?: iRole[]
  isDisabled?: boolean
}

/**
 * Component to select members for a session or a team.
 * @param shared List of already shared members
 * @param setShared Function to set the list of shared members in the parent component
 @param writeOnly If true, the access right is always write. Used for ShareType.Public
 * @param ownerId detects and filters owner out of memberslist
 */
const MembersSelection = ({
  shared,
  onSelectionChange,
  ownerId,
  writeOnly = false,
  isDisabled,
  roles,
}: MembersSelectionProps) => {
  const [search, setSearch] = useState('')
  const [addingNewDraegerEmail, setAddingNewDraegerEmail] = useState(false)

  const { editableTeams } = useTeamStore(
    (state) => ({
      editableTeams: state.editableTeams,
    }),
    shallow,
  )

  const addMember = (user: iUser) => {
    if (shared.find((m) => m.id === user.id)) {
      return
    }
    const newMember: iShared = {
      name: user.name!,
      id: user.id!,
      email: user.email!,
      entityType: EntityType.User,
      role: writeOnly ? Role.Manager : Role.Member,
    }
    onSelectionChange([...shared, newMember])
  }

  const addTeam = (team: iTeam) => {
    if (shared.find((m) => m.id === team.id)) {
      return
    }
    const newTeam: iShared = {
      name: team.name!,
      id: team.id!,
      entityType: EntityType.Team,
      role: writeOnly ? Role.Manager : Role.Member,
    }
    onSelectionChange([...shared, newTeam])
  }

  const handleSelectionChange = (value: number, s: iShared) => {
    s.role = value
    onSelectionChange([...shared])
  }

  const handleItemSelection = (shareds: any[]) => {
    const newShared = shareds.map((shared) => {
      return {
        name: shared.name!,
        id: shared.id!,
        entityType:
          (shared.entityType ?? 'resourceRestrictions' in shared)
            ? EntityType.Team
            : EntityType.User,
        role: writeOnly ? Role.Manager : Role.Member,
      }
    })
    onSelectionChange([...newShared])
  }

  const getRowStyle = (index: number) => ({
    transition: 'transform 0.5s ease, opacity 0.5s ease',
    transitionDelay: `${index * 0.1}s`, // staggered delay
    opacity: 1,
    transform: 'translateY(0)',
  })
  return (
    <div>
      <SectionCard
        title={'Available for'}
        description={'Share with Members or Teams'}
      >
        <div className={'flex'}>
          <ItemSelection
            selectedItems={shared}
            hideSelectionList
            imageObject={'image'}
            ownerObject={'email'}
            disableInfinityScroll
            label={''}
            placeholder={'Add Member or Team'}
            onChange={async (selectedItems: iItem[]) => {
              handleItemSelection(selectedItems as iShared[])
            }}
            loadFunc={async ({
              limit,
              offset,
              searchValue,
            }: {
              limit: number
              offset: number
              searchValue?: string
            }) => {
              const users = await UserService.getUsers({
                limit,
                offset,
                searchValue,
              })
              const teams = await TeamService.getTeams(
                false,
                1,
                searchValue,
                limit,
                offset,
              )
              const t = {
                data: [...users.data, ...teams.data],
                total: teams.total + users.total,
              }
              return t
            }}
          ></ItemSelection>
        </div>
        <div className="max-h-40 overflow-y-auto">
          <Table
            classNames={{
              wrapper: 'px-0',
            }}
            className={'w-full'}
            shadow="none"
          >
            <TableHeader>
              <TableColumn>Name</TableColumn>
              <TableColumn>Role</TableColumn>
              <TableColumn>Actions</TableColumn>
            </TableHeader>
            <TableBody>
              {shared.map((member, index) => (
                <TableRow
                  style={getRowStyle(index)}
                  className={'content-center'}
                  key={index}
                >
                  {member.entityType === EntityType.User ? (
                    <TableCell>
                      <UserDisplay
                        description={member.email!}
                        displayName={member.name}
                        email={member.email!}
                      ></UserDisplay>
                    </TableCell>
                  ) : member.entityType === EntityType.Team ? (
                    <TableCell>
                      <div key={member.id} className="flex items-center m-1">
                        <Avatar
                          alt={member.name}
                          className="flex-shrink-0"
                          size="sm"
                          icon={<UserGroupIcon className={'h-5 w-5'} />}
                        />
                        <div className="flex-wrap flex w-full justify-between">
                          <div className={'pl-2 w-full text-sm'}>
                            {member.name} (Team)
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  <TableCell align="left" className={'flex justify-end '}>
                    <div className={'flex items-center min-w-52 h-full mt-1'}>
                      <MemberRoleSelection
                        isDisabled={isDisabled}
                        value={member.role}
                        roles={roles}
                        writeOnly={writeOnly}
                        onChange={(value) => handleSelectionChange(value, member)}
                      ></MemberRoleSelection>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {!isDisabled && (
                      <Button
                        isIconOnly
                        variant={'light'}
                        className="hover:text-error ml-4"
                        onPress={() =>
                          onSelectionChange(shared.filter((m) => m.id !== member.id))
                        }
                      >
                        <ICON_DELETE />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </SectionCard>
    </div>
  )
}

export default MembersSelection
