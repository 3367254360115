import React, { useCallback, useMemo, useState } from 'react'
import { iFile } from '@/interfaces/iSourceFileCitations.ts'
import { iMessage } from '@/interfaces/iMessage.ts'
import { Badge, Card, CardBody, Divider, Tab, Tabs } from '@nextui-org/react'
import { ToolCall } from '@components/toolCalls/toolCall.tsx'
import { iDocumentChunk } from '@/interfaces/iDocumentChunk.ts'
import useChatStore from '@states/chatStore.ts'
import DocumentItemCard from '@components/session/sessionChatControlViews/documents/DocumentItemCard.tsx'
import { iDocumentItem } from '@/interfaces/iDocumentItem.ts'
import { DocumentType } from '@/enums/DocumentType.ts'
import { addBearer } from '@/utils/checkDomains.ts'

export type ChatMessageViewProps = {
  navigation?: any
  message: iMessage
  selectedTab?: 'general' | 'artifacts' | 'toolCalls'
  selectedCitation?: iDocumentChunk
}

const InfoItem = ({ label, value }: { label: string; value: number | string }) => (
  <div className="flex flex-col">
    <span className="text-sm text-gray-500 dark:text-gray-400">{label}</span>
    <span className="text-lg font-semibold">{value}</span>
  </div>
)

const ChatMessageDetailView = ({
  navigation,
  message,
  selectedTab,
  selectedCitation,
}: ChatMessageViewProps) => {
  const [file, setFile] = useState<iFile>()
  const [isLoading, setIsLoading] = useState<boolean>()

  const documentItems = useChatStore().session?.documentItems
  const documentChunks = useChatStore().session?.documentChunks

  const documents = useMemo(() => {
    if (!documentItems?.length) return []

    const existingChunks = documentChunks?.filter((chunk) =>
      chunk.references.some((ref) => ref.messageId === message.id),
    )
    const docsMap = new Map()
    existingChunks?.forEach((chunk) => {
      const docRefPath = chunk.indexedDocumentRefPath
      if (!docsMap.has(docRefPath)) {
        let doc = documentItems.find((d) => d.refPath === docRefPath)
        if (doc) {
          doc = { ...doc, _documentChunks: [], _references: [] }
          docsMap.set(docRefPath, doc)
        }
      }
      const doc = docsMap.get(docRefPath)
      if (doc) {
        doc._documentChunks.push(chunk)
        doc._references = doc._references.concat(
          chunk.references.map((ref) => ({
            ...ref,
            chunkId: chunk.excerpt.id,
          })),
        )
      }
    })
    return Array.from(docsMap.values())
  }, [documentChunks, documentItems, message.id])
  const handleItemSelect = useCallback((item: iDocumentItem, sourceId?: number) => {
    if (item.documentType === DocumentType.WebPage) {
      const addBearerToken = addBearer(item.refPath!)
      window.open(`${item.refPath}${addBearerToken}`, '_blank')
    } else {
      navigation && navigation.push('Detail', { document: item, sourceId: sourceId })
    }
  }, [])

  return (
    <Tabs variant="underlined" defaultSelectedKey={selectedTab}>
      <Tab key={'general'} title={'General'}>
        <Card className="dark:bg-default">
          <CardBody>
            <div className="grid grid-cols-2 gap-4">
              <InfoItem
                label="Completion Tokens"
                value={message.completionTokenCount?.toString() ?? 0}
              />
              <InfoItem
                label="Prompt Tokens"
                value={message.promptTokenCount?.toString() ?? 0}
              />
            </div>
            {message.generationTime && (
              <>
                <Divider className="my-4" />
                <div className="grid grid-cols-2 gap-4">
                  <InfoItem
                    label="Backend Time"
                    value={`${message.generationTime.backend.toFixed(2)}s`}
                  />
                  <InfoItem
                    label="End-to-End Time"
                    value={`${message.generationTime.end2end.toFixed(2)}s`}
                  />
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Tab>
      <Tab
        key={'artifacts'}
        title={
          <Badge content={documents?.length}>
            <div className="flex items-center space-x-2">
              <span>Documents</span>
            </div>
          </Badge>
        }
      >
        <div className="md:grid-cols-2 gap-4 w-full">
          {documents?.map((item) => {
            return (
              <DocumentItemCard
                key={item.refPath}
                item={item}
                onPress={handleItemSelect}
              />
            )
          })}
        </div>
      </Tab>
      <Tab
        key={'toolCalls'}
        title={
          <Badge content={message.toolCalls?.length}>
            <div className="flex items-center space-x-2">
              <span>Tool-Calls</span>
            </div>
          </Badge>
        }
      >
        {message.toolCalls?.map((t) => {
          return <ToolCall key={t.toolCallId} initialToolCall={t}></ToolCall>
        })}
      </Tab>
    </Tabs>
  )
}

export default ChatMessageDetailView
