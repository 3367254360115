import React, { useCallback, useMemo } from 'react'

import { DiDatabase } from 'react-icons/di'
import { iDocumentChunk } from '@/interfaces/iDocumentChunk.ts'
import DocumentItemCard from '@components/session/sessionChatControlViews/documents/DocumentItemCard.tsx'
import { iDocumentItem } from '@/interfaces/iDocumentItem.ts'
import useChatStore from '@states/chatStore.ts'
import { DocumentType } from '@/enums/DocumentType.ts'
import { addBearer } from '@/utils/checkDomains.ts'

type ChatDocumentsGeneralViewProps = {
  documentChunks: iDocumentChunk[]
  documentItems: iDocumentItem[]
}

// Main Component
const ChatDocumentsGeneralView = ({
  documentChunks = [],
  documentItems,
}: ChatDocumentsGeneralViewProps) => {
  const setChatControlContentRoute = useChatStore(
    (state) => state.setChatControlContentRoute,
  )
  const handleItemSelect = useCallback((item: iDocumentItem, sourceId?: number) => {
    if (item.documentType === DocumentType.WebPage) {
      const addBearerToken = addBearer(item.refPath!)
      window.open(`${item.refPath}${addBearerToken}`, '_blank')
    } else {
      setChatControlContentRoute('artifacts', 'Detail', {
        document: item,
        sourceId: sourceId,
      })
    }
  }, [])

  const documents = useMemo(() => {
    if (!documentItems.length) return []
    const existingChunks = documentChunks.filter(
      (chunk) => chunk.references.length > 0,
    )
    const docsMap = new Map()
    existingChunks.forEach((chunk) => {
      const docRefPath = chunk.indexedDocumentRefPath
      if (!docsMap.has(docRefPath)) {
        let doc = documentItems.find((d) => d.refPath === docRefPath)
        if (doc) {
          doc = { ...doc, _documentChunks: [], _references: [] }
          docsMap.set(docRefPath, doc)
        }
      }
      const doc = docsMap.get(docRefPath)
      if (doc) {
        doc._documentChunks.push(chunk)
        doc._references = doc._references.concat(
          chunk.references.map((ref) => ({
            ...ref,
            chunkId: chunk.excerpt.id,
          })),
        )
      }
    })
    return Array.from(docsMap.values())
  }, [documentChunks, documentItems])

  return (
    <div>
      <div className="space-y-6 mt-4 p-4 min-w-[350px]">
        <section>
          <h3 className="text-lg font-medium mb-3 flex items-center gap-2">
            <DiDatabase className="w-5 h-5" />
            References
          </h3>
          <div className="md:grid-cols-2 gap-4 w-full">
            {documents.map((item) => {
              return (
                <DocumentItemCard
                  key={item.refPath}
                  item={item}
                  onPress={handleItemSelect}
                />
              )
            })}
          </div>
        </section>
      </div>
    </div>
  )
}

export default ChatDocumentsGeneralView
