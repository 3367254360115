import { iAssistantConfiguration } from '@/interfaces/iAssistantTypes.ts'
import { Card, CardBody, Switch } from '@nextui-org/react'
import React, { useCallback, useEffect, useState } from 'react'
import {
  ICON_KNOWLEDGE_CONTAINER,
  ICON_MODEL_SETTINGS,
  ICON_TOOL,
} from '@/constants/icons.tsx'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'

type AssistantDisplaySectionProps = {
  value: iAssistantConfiguration
  isDisabled?: boolean
  onChange?: (configuration: iAssistantConfiguration) => void
}
const AssistantDisplaySection = ({
  value,
  isDisabled,
  onChange,
}: AssistantDisplaySectionProps) => {
  const [sections, setSections] = useState({
    tools: {
      title: 'Tools',
      icon: ICON_TOOL,
      visible: value.tools.visible,
      editable: value.tools.editable,
    },
    knowledgeContainer: {
      title: 'Knowledge-Container',
      icon: ICON_KNOWLEDGE_CONTAINER,
      visible: value.knowledgeContainer.visible,
      editable: value.knowledgeContainer.editable,
    },
    modelSettings: {
      title: 'Model Settings',
      icon: ICON_MODEL_SETTINGS,
      visible: value.modelSettings.visible,
      editable: value.modelSettings.editable,
    },
  })

  useEffect(() => {
    if (onChange) {
      value.tools.editable = sections.tools.editable
      value.tools.visible = sections.tools.visible

      value.knowledgeContainer.visible = sections.knowledgeContainer.visible
      value.knowledgeContainer.editable = sections.knowledgeContainer.editable

      value.modelSettings.visible = sections.modelSettings.visible
      value.modelSettings.editable = sections.modelSettings.editable

      onChange(value)
    }
  }, [sections])
  // Toggle handlers
  const toggleVisibility = (id: keyof typeof sections) => {
    const section = sections[id]
    section.visible = !section.visible
    section.editable = section.editable && section.visible
    setSections((prev) => ({ ...prev, [id]: section }))
  }

  const toggleEditable = (id: keyof typeof sections) => {
    const section = sections[id]
    section.editable = !section.editable
    setSections((prev) => ({ ...prev, [id]: section }))
  }

  const getExplainationText = useCallback(
    (section: any) => {
      if (!section.visible) {
        return `${section.title} are not visible for the user`
      } else {
        return section.editable
          ? `Editing ${section.title} content... `
          : `View-only ${section.title} content`
      }
    },
    [sections],
  )
  // Section component
  const Section = ({ section, id }: { id: keyof typeof sections; section: any }) => (
    <Card className="mb-4 w-full">
      <CardBody className="p-4">
        <div className="flex flex-col gap-4">
          {/* Header with controls */}
          <div className="flex items-center justify-between border-b pb-4">
            <div className="flex items-center gap-2">
              <section.icon />
              <h3 className="text-lg font-semibold">{section.title}</h3>
            </div>

            {/* Controls */}
            <div className="flex items-center gap-6">
              {/* Visibility control */}
              <div className="flex items-center gap-2">
                <span className="text-sm">Visible</span>
                <Switch
                  isDisabled={isDisabled}
                  isSelected={section.visible}
                  onValueChange={() => toggleVisibility(id)}
                  className="data-[state=checked]:bg-green-500"
                />
              </div>

              {/* Edit control - only shown when visible */}
              <div className="flex items-center gap-2">
                <span className="text-sm">Edit</span>
                <Switch
                  isDisabled={!section.visible || isDisabled}
                  isSelected={section.editable}
                  onValueChange={() => toggleEditable(id)}
                  className="data-[state=checked]:bg-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Content - only shown when visible */}
          <div
            className={`transition-all duration-200 ${section.editable ? 'opacity-100' : 'opacity-50'}`}
          >
            <div className="bg-gray-100 p-4 rounded">
              {getExplainationText(section)}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )

  return (
    <SectionCard
      title={'Modify settings'}
      description={'Check how much users are allowed to modify your assistant.'}
    >
      <div className="w-full mx-auto p-4 space-y-4">
        {Object.keys(sections).map((key) => (
          <Section
            key={key}
            section={sections[key as keyof typeof sections]}
            id={key as keyof typeof sections}
          />
        ))}
      </div>
    </SectionCard>
  )
}
export default AssistantDisplaySection
