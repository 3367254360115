import React, { useState } from 'react'

import {
  Cog6ToothIcon,
  FolderIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import DialogSessionFolderModal from './DialogSessionFolderModal.tsx'
import DialogFolderOptions from './DialogFolderOptions.tsx'
import useFolderStore from '@states/folderStore.ts'
import { shallow } from 'zustand/shallow'
import { iFolder } from '../../interfaces/iFolder.ts'
import OptionsMenu from '../basic/optionsMenu/OptionsMenu.tsx'
import { ICON_DELETE } from '@/constants/icons.tsx'
type SessionFolderTitleProps = React.HTMLAttributes<HTMLButtonElement> & {
  folder: iFolder
}
const SessionFolderTitle = ({ folder }: SessionFolderTitleProps) => {
  const deleteFolder = useFolderStore((state) => state.deleteFolder, shallow)

  const [dialogSessionFolderModalDialog, setDialogSessionFolderModalDialog] =
    useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [dialogSendMessageToFolder, setDialogFolderOptionsModal] =
    useState<boolean>(false)

  const handleFolderDelete = async (folder: iFolder) => {
    if (!confirm('Are you sure you want to delete this folder?')) {
      return
    }
    setIsLoading(true)
    await deleteFolder(folder)
    setIsLoading(false)
  }

  /**
   * Event when creating a new folder/folder
   * @param newFolder
   */
  const handleCreateFolder = async () => {}

  /**
   * Reset the variables for the folder modal on modal close
   */
  const onFolderModalClose = () => {
    setDialogSessionFolderModalDialog(false)
  }

  return (
    <>
      {/* Send to folder dialog */}
      <DialogFolderOptions
        folder={folder}
        open={dialogSendMessageToFolder}
        onClose={() => setDialogFolderOptionsModal(false)}
      />

      {/* Select User folder for Chat folder */}
      <DialogSessionFolderModal
        open={dialogSessionFolderModalDialog}
        folder={folder}
        onCreated={handleCreateFolder}
        onClose={onFolderModalClose}
      />

      <div className={'flex justify-between items-center'}>
        <div>
          <p className={''}>{folder.title}</p>
          <p className="text-small text-default-500">{folder._count}</p>
        </div>
        {/* options menu must be on the right */}
        <div className={''}>
          <OptionsMenu
            isLoading={isLoading}
            items={[
              {
                label: 'Edit Folder',
                method: async () => setDialogSessionFolderModalDialog(true),
                icon: <PencilSquareIcon className={'h-5 w-5'} />,
              },
              {
                label: 'Options',
                method: () => {
                  setDialogFolderOptionsModal(true)
                },
                icon: <Cog6ToothIcon className={'h-5 w-5'} />,
                showDivider: true,
              },
              {
                label: 'Delete',
                method: async () => await handleFolderDelete(folder),
                className: 'text-danger',
                icon: <ICON_DELETE />,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default SessionFolderTitle
