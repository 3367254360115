import { iKnowledgeContainer } from './iKnowledgeContainer.ts'

export interface iDocumentContainerView {
  closeFunction: () => void
  addContainerFunction: () => void
  editContainerFunction: (container: iKnowledgeContainer) => void
  manageContainerFunction: (container: iKnowledgeContainer) => void
  variant: DocumentContainerViewVariant
}

export enum DocumentContainerViewVariant {
  Action,
  Selection,
}

export enum DocumentViewTab {
  Containers,
  Edit,
  ManageFiles,
  Selection,
}

export interface iDocumentContainer extends iKnowledgeContainer {
  selected: boolean
}

export interface iDocumentList {
  path: string
  type: ContainerType
  lastModified: string | null
  size: number
  children: iDocumentList[]

  showChildren: boolean
  file: File | null
  newStatus: NewStatus
}

export interface iDocumentTree {
  documents: iDocumentTree[]
  created?: string
  lastModified?: string | null
  id?: string
  name?: string
  path: string
  nodeType: ContainerType
  status?: IndexingStatus
  wasCompletedOnce?: boolean

  _newStatus?: NewStatus
  _showChildren?: boolean
  _file?: File | null
}

export interface iDocumentDictionary {
  [key: string]: iDocumentTree
}

enum IndexingStatus {
  Unknown,
  Pending,
  Storing,
  Analyzing,
  Chunking,
  Completed,
  Failed,
}

export enum ContainerType {
  Folder,
  File,
}

export enum NewStatus {
  None,
  Existing,
  New,
  Deleted,
  Updated,
}

export enum SavingStatus {
  None,
  Uploading,
  Analyzing,
  Indexing,
  Deleting,
  Completed,
  Failed,
}

export interface iProgressUpdate {
  status: ProgressUpdateStatus
  progress: number
  filename: string
}

export enum ProgressUpdateStatus {
  uploading = 'uploading',
  analyzing = 'analyzing',
  indexing = 'indexing',
  error = 'error',
  completed = 'completed',
}

export enum DocumentUploadEvents {
  uploading = 'knowledgeContainerUploading',
  analyzing = 'knowledgeContainerAnalyzing',
  indexing = 'knowledgeContainerIndexing',
}
