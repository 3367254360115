import React, { useEffect, useState } from 'react'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Select,
  SelectItem,
  Button,
  Pagination,
  Input,
} from '@nextui-org/react'
import { iUser } from '@/interfaces/iUser'
import UserService from '@/services/UserService.ts'
import { ICON_DELETE } from '@/constants/icons'
import { AuthRole } from '@/utils/authentication.ts'
import useUserProfileStore from '@states/userProfileState.ts'

const AdminPanel: React.FC = () => {
  return (
    <DefaultPageLayout
      title="Admin Panel"
      editAble={false}
      headerContent={null}
      tabs={[{ title: 'Manage Users', key: 'users' }]}
    >
      {(selectedTabKey) => {
        switch (selectedTabKey) {
          case 'users':
            return <ManageUsers />
          default:
            return null
        }
      }}
    </DefaultPageLayout>
  )
}

const ManageUsers: React.FC = () => {
  const [users, setUsers] = useState<iUser[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const userProfile = useUserProfileStore().userProfile
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const pageSize = 10

  useEffect(() => {
    setCurrentPage(1)
  }, [searchValue])

  useEffect(() => {
    loadUsers(currentPage)
  }, [currentPage, searchValue])

  async function loadUsers(page: number) {
    setLoading(true)
    try {
      const offset = (page - 1) * pageSize
      const { data, total } = await UserService.getUsers({
        limit: pageSize,
        offset,
        searchValue,
      })
      setUsers(data)
      setTotal(total)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const handleRoleChange = async (userId: string, newRole: AuthRole) => {
    try {
      await UserService.updateUserAsAdmin(userId, newRole)
      setUsers((prev) =>
        prev.map((u) => (u.id === userId ? { ...u, role: newRole } : u)),
      )
    } catch (error) {
      console.error(error)
    }
  }

  const removeUser = async (userId: string) => {
    setUsers((prev) => prev.filter((u) => u.id !== userId))
  }

  return (
    userProfile?.role === AuthRole.Admin && (
      <div className="space-y-4 max-w-4xl">
        <SectionCard
          className="min-w-96"
          title="Manage Users"
          description={'Assign roles - Total users: ' + total}
        >
          <div className="mb-4">
            <Input
              placeholder="Search by name or email..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              <Table aria-label="Users table" className="w-full" shadow="none">
                <TableHeader>
                  <TableColumn>User</TableColumn>
                  <TableColumn>Role</TableColumn>
                  <TableColumn className="text-right">Actions</TableColumn>
                </TableHeader>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>
                        {user.name} ({user.email})
                      </TableCell>
                      <TableCell>
                        <Select
                          // Use numeric enum as a string key
                          selectedKeys={[String(user.role)]}
                          onSelectionChange={(keys) => {
                            const newVal = Number([...keys][0])
                            handleRoleChange(user.id as string, newVal)
                          }}
                        >
                          <SelectItem
                            key={String(AuthRole.Blocked)}
                            value={String(AuthRole.Blocked)}
                          >
                            Blocked
                          </SelectItem>
                          <SelectItem
                            key={String(AuthRole.User)}
                            value={String(AuthRole.User)}
                          >
                            User
                          </SelectItem>
                          <SelectItem
                            key={String(AuthRole.Developer)}
                            value={String(AuthRole.Developer)}
                          >
                            Developer
                          </SelectItem>
                          <SelectItem
                            key={String(AuthRole.Admin)}
                            value={String(AuthRole.Admin)}
                          >
                            Admin
                          </SelectItem>
                        </Select>
                      </TableCell>
                      <TableCell className="flex justify-end">
                        <Button
                          isIconOnly
                          variant="light"
                          onPress={() => removeUser(user.id as string)}
                        >
                          <ICON_DELETE />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                page={currentPage}
                total={Math.ceil(total / pageSize)}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
        </SectionCard>
      </div>
    )
  )
}

export default AdminPanel
