import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Tooltip,
} from '@nextui-org/react'
import { InformationCircleIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import useChatStore from '@states/chatStore.ts'
import { Role } from '@/interfaces/iShared.ts'
import useAssistantModelStore from '@states/assistantModelStore.ts'
import useTeamStore from '@states/teamStore.ts'
import { itemAllowedInTeam } from '@/utils/itemAllowedInTeam.ts'
import IconFetcher from '@components/basic/icon/IconFetcher.tsx'
import { ICON_DELETE, ICON_EDIT } from '@/constants/icons.tsx'
import CopyToClipboard from '@components/basic/copyToClipboard/CopyToClipboard.tsx'

type AssistantCardProps = {
  value: iAssistant
  handleApply?: (preSetting: iAssistant) => void
  onClick?: () => void
  onDelete?: (assistantId: string) => Promise<void>
  onEditClick?: () => void
  applyLabel?: string
}

const AssistantCard = React.memo(
  ({
    value,
    onDelete,
    handleApply,
    onClick,
    onEditClick,
    applyLabel,
  }: AssistantCardProps) => {
    const [isApplied, setIsApplied] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const { selectedTeam } = useTeamStore()
    const { assistant: activeChatAssistant, session: activeSession } = useChatStore()
    const { getAssistantModelsByContext } = useAssistantModelStore()

    useEffect(() => {
      setIsApplied(activeChatAssistant?.baseAssistantId === value.id)
    }, [value.id, activeChatAssistant?.baseAssistantId])

    const apply = useCallback(() => {
      if (isApplied && activeSession?.id === value.id) return
      handleApply?.(value)
    }, [isApplied, activeSession?.id, value, handleApply])

    const assistantAllowed = useCallback(
      (assistant: iAssistant) => {
        return itemAllowedInTeam(assistant, selectedTeam?.id!)
      },
      [selectedTeam],
    )

    const modelAllowed = useCallback(
      (assistant: iAssistant): boolean => {
        const models = getAssistantModelsByContext('team', selectedTeam?.id!)
        return models.some((model) => model.id === assistant.settings?.modelId)
      },
      [getAssistantModelsByContext, selectedTeam],
    )

    const disableReasonText = useMemo(() => {
      if (selectedTeam) {
        if (!modelAllowed(value)) {
          return 'The Assistant uses a model that has not been approved by your team.'
        }
        if (!assistantAllowed(value) && value.id) {
          return 'The Assistant is not allowed in your team.'
        }
      }
      return undefined
    }, [value, selectedTeam, modelAllowed, assistantAllowed])

    const handleDelete = useCallback(async () => {
      if (!onDelete) return
      if (!window.confirm('Are you sure you want to delete this Assistant?')) return
      setIsDeleting(true)
      try {
        await onDelete(value.id!)
      } finally {
        setIsDeleting(false)
      }
    }, [onDelete, value.id])

    return (
      <Card
        isPressable={!!onClick && !isDeleting}
        isHoverable={!!onClick && !isDeleting}
        isDisabled={isDeleting}
        className="min-w-[240px] h-[170px] max-h-[170px]"
        onPress={onClick}
      >
        <CardHeader className="justify-between">
          <div className="flex gap-5 min-w-0 overflow-hidden">
            <IconFetcher
              entityId={value.id!}
              entityType="Assistant"
              imageId={value.image}
            />
            <div className="flex flex-col justify-center min-w-0 overflow-hidden">
              <h4 className="text-small text-left font-semibold leading-none text-default-600 truncate pb-1">
                {value.title}
              </h4>
              <h5 className="text-small text-left tracking-tight text-default-400 truncate pb-1">
                {value.ownerName || value.ownerEmail}
              </h5>
            </div>
          </div>
          <div className="flex items-center gap-1">
            <CopyToClipboard tooltip="Copy assistant id" textToCopy={value.id!} />
            {value.userRole! >= Role.Manager && onEditClick && (
              <Tooltip content="Edit assistant" className="max-w-96">
                <Button
                  isIconOnly
                  variant="light"
                  isDisabled={isDeleting}
                  onPress={onEditClick}
                >
                  <ICON_EDIT />
                </Button>
              </Tooltip>
            )}
            {value.userRole! >= Role.Owner && onDelete && (
              <Tooltip content="Delete assistant" className="max-w-96">
                <Button
                  isDisabled={isDeleting}
                  isLoading={isDeleting}
                  isIconOnly
                  variant="light"
                  color="danger"
                  onPress={handleDelete}
                >
                  <ICON_DELETE />
                </Button>
              </Tooltip>
            )}
          </div>
        </CardHeader>
        <CardBody className="px-3 py-0 text-small text-default-400">
          <span className="pt-2 text-default-400 text-small whitespace-pre-wrap line-clamp-2">
            {value.description}
          </span>
        </CardBody>
        <CardFooter className="flex justify-between items-center gap-3">
          <div className="flex gap-1 items-center">
            <p className="text-small text-default-400">{value.requestCount}</p>
            <RocketLaunchIcon className="w-5 h-5 text-default-400" />
          </div>
          <div className="flex items-center gap-2">
            {disableReasonText && (
              <Tooltip content={disableReasonText} className="max-w-96">
                <InformationCircleIcon className="w-6 h-6" />
              </Tooltip>
            )}
            {handleApply && (
              <Button
                variant={isApplied ? undefined : 'bordered'}
                color={isApplied ? 'default' : undefined}
                isDisabled={!!disableReasonText || isDeleting}
                onPress={apply}
              >
                {isApplied ? applyLabel || 'Applied' : applyLabel || 'Apply'}
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    )
  },
)

export default AssistantCard
