import { create } from 'zustand'
import KnowledgeContainerService from '@/services/knowledgeContainerService.ts'
import { iFile } from '@/interfaces/iSourceFileCitations.ts'

interface IDocumentAccessStore {
  filesMap: Record<string, iFile | null> // Key: "knowledgeContainerId/refPath" => iFile object or null
  loadingKeys: Record<string, boolean> // Track loading states per key (optional)
  getDocumentAccessFile: (kcId: string, refPath: string) => Promise<iFile | null>
}

const useDocumentAccessStore = create<IDocumentAccessStore>((set, get) => ({
  filesMap: {},
  loadingKeys: {},

  // This function will fetch the iFile from KnowledgeContainerService if not already cached
  getDocumentAccessFile: async (kcId: string, refPath: string) => {
    const key = `${kcId}/${refPath}`
    const { filesMap, loadingKeys } = get()

    // If we already have a file (or we tried loading before with no success), return it
    if (filesMap[key]) {
      return filesMap[key]
    }
    // If we are already loading this key, just wait until it resolves
    if (loadingKeys[key]) {
      // Optional: poll or do some concurrency handling
      // For simplicity, we’ll return what we have now
      return filesMap[key] ?? null
    }

    // Mark as loading
    set((state) => ({
      loadingKeys: { ...state.loadingKeys, [key]: true },
    }))

    try {
      // KnowledgeContainerService expects an array of paths
      const data =
        await KnowledgeContainerService.getKnowledgeContainerDocumentsAccessUrls(
          kcId,
          [key],
        )
      const file = data?.[0] ?? null

      set((state) => ({
        filesMap: { ...state.filesMap, [key]: file },
        loadingKeys: { ...state.loadingKeys, [key]: false },
      }))
      return file
    } catch (error) {
      console.error('Error fetching iFile:', error)
      set((state) => ({
        filesMap: { ...state.filesMap, [key]: null }, // or set in an error state
        loadingKeys: { ...state.loadingKeys, [key]: false },
      }))
      return null
    }
  },
}))

export default useDocumentAccessStore
