import React, { useState, useMemo } from 'react'
import HttpConstantSetEditor from './HttpConstantSetEditor'
import HttpParameterSetEditor from './HttpParameterSetEditor'
import { InputField, SelectField, TextField } from '../../basic/inputfields/model'
import { FieldsEditor } from '../../basic/inputfields/FieldEditors'
import {
  HttpConstantSet,
  HttpMethod,
  httpMethods,
  HttpParameterSet,
  iHttpTemplate,
} from '../../../interfaces/iHttpParameter'

const tooltipMethod =
  'Auto: GAIA will determine the method based on the request. GET: Retrieve information from an endpoint. POST: Send information to an endpoint. PUT: Update information on an endpoint. DELETE: Remove information from an endpoint.'
const tooltipUrl =
  'The URL of the request. Can contain path parameters in the form of {parameterName}.'

interface HttpTemplateEditorProps {
  currentHttpTemplate: iHttpTemplate
  onChange: (httpTemplate: iHttpTemplate) => void
  isDisabled?: boolean
}

const HttpTemplateEditor: React.FC<HttpTemplateEditorProps> = ({
  currentHttpTemplate,
  onChange,
  isDisabled = false,
}) => {
  // Initialize state based on currentHttpTemplate
  const [internalTemplate, setInternalTemplate] =
    useState<iHttpTemplate>(currentHttpTemplate)

  // Update internal state if currentHttpTemplate changes
  if (currentHttpTemplate !== internalTemplate) {
    setInternalTemplate(currentHttpTemplate)
  }

  const { method, url, parameters, constants } = internalTemplate
  // Define a function to update the httpTemplate and call onChange
  const updateHttpTemplate = (
    updatedMethod: HttpMethod,
    updatedUrl: string,
    updatedParameters: HttpParameterSet,
    updatedConstants: HttpConstantSet,
  ) => {
    const updatedHttpTemplate: iHttpTemplate = {
      ...internalTemplate,
      method: updatedMethod,
      url: updatedUrl,
      parameters: updatedParameters,
      constants: updatedConstants,
    }
    setInternalTemplate(updatedHttpTemplate)
    onChange(updatedHttpTemplate)
  }

  // Handle field changes
  const handleFieldsChange = (updatedFields: InputField[]) => {
    const updatedMethodField = updatedFields.find((f) => f.key === 'method') as
      | SelectField
      | undefined
    const updatedUrlField = updatedFields.find((f) => f.key === 'url') as
      | TextField
      | undefined

    const newMethod = updatedMethodField
      ? (updatedMethodField.value.key as HttpMethod)
      : method
    const newUrl = updatedUrlField ? updatedUrlField.value : url

    updateHttpTemplate(newMethod, newUrl, parameters, constants)
  }

  // Handle parameter and constant changes
  const handleParametersChange = (updatedParameters: HttpParameterSet) => {
    updateHttpTemplate(method, url, updatedParameters, constants)
  }

  const handleConstantsChange = (updatedConstants: HttpConstantSet) => {
    updateHttpTemplate(method, url, parameters, updatedConstants)
  }

  // Define fields for method and url
  const fields: InputField[] = useMemo(
    () => [
      new SelectField({
        key: 'method',
        name: 'Method',
        value: { key: method },
        options: httpMethods.map((meth) => ({ key: meth })),
        info: tooltipMethod,
      }),
      new TextField({
        key: 'url',
        name: 'URL',
        value: url,
        info: tooltipUrl,
      }),
    ],
    [method, url],
  )

  return (
    <>
      <FieldsEditor
        isDisabled={isDisabled}
        fields={fields}
        onChange={handleFieldsChange}
      />
      <div className="mt-4">
        <HttpConstantSetEditor
          isDisabled={isDisabled}
          constantSet={constants}
          onChange={handleConstantsChange}
        />
        <HttpParameterSetEditor
          isDisabled={isDisabled}
          parameterSet={parameters}
          onChange={handleParametersChange}
        />
      </div>
    </>
  )
}

export default HttpTemplateEditor
