import React, { useMemo } from 'react'

import { Avatar, AvatarGroup, Card, CardHeader, Tooltip } from '@nextui-org/react'

import { ChevronRightIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import useTeamStore from '@/states/teamStore.ts'
import { shallow } from 'zustand/shallow'
import { EntityType, iShared } from '@/interfaces/iShared.ts'
import { getUser, getUserKey } from '@/utils/authentication.ts'
import ProfileImage from '../basic/profileImage/ProfileImage.tsx'
import TeamIcon from '@components/team/TeamIcon.tsx'

type JoinInviteCardProps = React.HTMLAttributes<HTMLDivElement> & {
  variant: 'team' | 'user'
  isDisabled?: boolean
  shared: iShared[]
  alreadyJoined?: boolean
  onClick?: (e: any) => void //TODO: check correct event type
}
export default function JoinInviteCard({
  variant,
  isDisabled,
  onClick,
  shared,
}: JoinInviteCardProps) {
  const { editableTeams } = useTeamStore(
    (state) => ({
      editableTeams: state.editableTeams,
    }),
    shallow,
  )

  const headerText = useMemo(() => {
    return variant === 'user' ? 'Join as User' : 'Join as Team'
  }, [variant])

  const descriptionText = useMemo(() => {
    return variant === 'user'
      ? 'Join this resource with your own user'
      : 'As the owner or manager of a team, you have the option of adding your team to the resource.'
  }, [variant])

  const remainingTeamsToJoin = useMemo(() => {
    return editableTeams.filter(
      (team) =>
        !shared.find((s) => s.entityType === EntityType.Team && s.id === team.id),
    )
  }, [])
  const userAlreadyJoined = useMemo(() => {
    return !!shared.find(
      (s) => s.entityType === EntityType.User && s.id === getUserKey(),
    )
  }, [])
  const { email } = getUser()
  return (
    <>
      <Card
        className="w-full"
        shadow={'sm'}
        isPressable
        isDisabled={isDisabled}
        onPress={onClick}
      >
        <CardHeader className="flex gap-3">
          {variant === 'user' ? (
            <ProfileImage contact={email}></ProfileImage>
          ) : (
            <Avatar
              className={'flex-shrink-0'}
              fallback={<UserGroupIcon color={'white'} className="w-6 h-6" />}
            ></Avatar>
          )}
          <div className="flex flex-col flex-grow-1 flex-1 left-0 ">
            <p className="text-md text-left">{headerText}</p>
            <p className="text-small text-left text-default-500">
              {descriptionText}
            </p>
            {variant === 'team' && (
              <AvatarGroup isBordered isGrid max={4} className={'pt-4'}>
                {remainingTeamsToJoin.map((team, index) => {
                  return (
                    <Tooltip key={index} content={team?.name} className={'max-w-96'}>
                      <TeamIcon team={team}></TeamIcon>
                    </Tooltip>
                  )
                })}
              </AvatarGroup>
            )}
          </div>
          <ChevronRightIcon
            className={'flex-shrink-0 flex-grow-0 w-10 h-5'}
          ></ChevronRightIcon>
        </CardHeader>
      </Card>
    </>
  )
}
