import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  Breadcrumbs,
  BreadcrumbItem,
  Link,
  Tabs,
  Tab,
  Form,
  Button,
  Spinner,
} from '@nextui-org/react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ICON_CANCEL,
  ICON_CREATE,
  ICON_DELETE,
  ICON_EDIT,
  ICON_SAVE,
} from '@/constants/icons.tsx'
import CopyToClipboard from '@components/basic/copyToClipboard/CopyToClipboard.tsx'
type DefaultPageLayoutProps = {
  title: string
  headerContent?: React.ReactNode
  tabs?: { title: string; key: string }[]
  children?:
    | string
    | ReactNode
    | ((selectedTab: string, isEditMode?: boolean) => ReactNode)
  deleteAble?: boolean
  editAble?: boolean
  notFound?: boolean
  createAble?: boolean
  copyToClipboard?: string
  editMode?: boolean
  onSaveClick?: (data: any) => Promise<void>
  onCreateClick?: (data: any) => Promise<void>
  onCancelClick?: () => void
  onDeleteClick?: () => void
}
const DefaultPageLayout = ({
  title,
  children,
  headerContent,
  tabs,
  editMode = false,
  deleteAble,
  editAble,
  createAble,
  onSaveClick,
  notFound,
  onDeleteClick,
  onCancelClick,
  copyToClipboard,
  onCreateClick,
}: DefaultPageLayoutProps) => {
  const [isEditMode, setIsEditMode] = useState<boolean>()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string | undefined>(
    tabs && tabs?.length > 0 ? tabs[0].key : undefined,
  )
  const location = useLocation()
  const navigate = useNavigate()

  const breadcrumbs = useMemo(() => {
    const pathSegments = location.pathname.split('/').filter((segment) => segment)
    return pathSegments.map((segment, index) => {
      const href = `/${pathSegments.slice(0, index + 1).join('/')}`
      return {
        href,
        label: segment.charAt(0).toUpperCase() + segment.slice(1),
      }
    })
  }, [location.pathname])

  const onReset = () => {
    onCancelClick && onCancelClick()
    setIsEditMode(false)
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsSaving(true)
    const data = Object.fromEntries(new FormData(e.currentTarget))
    onSaveClick &&
      onSaveClick(data)
        .then(() => {
          setIsEditMode(false)
        })
        .finally(() => {
          setIsSaving(false)
        })
  }

  useEffect(() => {
    setIsEditMode(editMode)
  }, [editMode])
  return (
    <div className="flex justify-center w-full h-full overflow-auto pt-20">
      <div className="w-10/12 max-w-4xl">
        <Form
          autoCapitalize="off"
          autoComplete="off"
          className="block relative"
          validationBehavior="native"
          onSubmit={onSubmit}
          onReset={onReset}
        >
          {/* Default Header */}
          <div className="flex mb-8">
            <div className="flex-1">
              <h1 className="text-2xl font-bold mb-2">{title}</h1>
              <Breadcrumbs>
                <BreadcrumbItem>
                  <Link onPress={() => navigate('/')}>Home</Link>
                </BreadcrumbItem>
                {breadcrumbs.map((crumb, index) => (
                  <BreadcrumbItem key={crumb.href}>
                    {index === breadcrumbs.length - 1 ? (
                      <span>{title}</span>
                    ) : (
                      <Link onPress={() => navigate(crumb.href)}>{crumb.label}</Link>
                    )}
                  </BreadcrumbItem>
                ))}
              </Breadcrumbs>
            </div>

            <div>
              <div className="flex items-center space-x-2">
                {isEditMode ? (
                  <>
                    <Button size="md" color="danger" type="reset">
                      <ICON_CANCEL className="mr-1" />
                      Cancel
                    </Button>
                    <Button size="md" isDisabled={isSaving} type="submit">
                      {isSaving ? <Spinner /> : <ICON_SAVE className="mr-1" />}
                      {isSaving ? 'Saving' : 'Save'}
                    </Button>
                  </>
                ) : (
                  <>
                    {createAble && (
                      <Button size="md" color="primary" onPress={onCreateClick}>
                        <ICON_CREATE />
                        Create
                      </Button>
                    )}
                    {copyToClipboard && (
                      <CopyToClipboard
                        variant="solid"
                        color="primary"
                        textToCopy={copyToClipboard}
                        label="Copy to clipboard"
                      ></CopyToClipboard>
                    )}
                    {editAble && (
                      <Button
                        size="md"
                        color="primary"
                        onClick={(e) => {
                          //TODO: figure out why onPress just make icon clickable and ignore the rest of the button. HINT: maybe an form issue?
                          e.preventDefault()
                          setIsEditMode(true)
                        }}
                        type="button"
                      >
                        <ICON_EDIT className="mr-1" />
                        Edit
                      </Button>
                    )}
                    {deleteAble && (
                      <Button
                        size="md"
                        color="danger"
                        isIconOnly
                        onPress={onDeleteClick}
                      >
                        <ICON_DELETE />
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {headerContent && (
            <div className="flex items-center mb-8">{headerContent}</div>
          )}
          {tabs && selectedTab && (
            <div className="flex items-center mb-8">
              {
                <Tabs
                  variant="underlined"
                  selectedKey={selectedTab}
                  aria-label="Tabs"
                  onSelectionChange={(key) => setSelectedTab(key as string)}
                >
                  {tabs.map((tab) => {
                    return <Tab key={tab.key} title={tab.title} />
                  })}
                </Tabs>
              }
            </div>
          )}
          <div>
            {notFound ? (
              <div>Nothing found</div>
            ) : typeof children === 'function' ? (
              children(selectedTab!, isEditMode)
            ) : (
              children
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

export default DefaultPageLayout
