import React, { useCallback } from 'react'
import { Route, useNavigate, Routes, useLocation } from 'react-router-dom'
import DefaultLayout from './layouts/Default.tsx'
import NotFoundPage from './pages/NotFoundPage.tsx'
import ForbiddenPage from './pages/ForbiddenPage.tsx'
import FaqPage from './pages/FaqPage.tsx'
import { LoginCallback, Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { RequiredAuth } from './components/routes/SecureRoute.tsx'
import LoadingScreen from './components/loadingScreen/LoadingScreen.tsx'
import { oktaAuthClient } from './utils/authentication.ts'
import SessionContentPage from './pages/ChatPage.tsx'
import { AnimatePresence } from 'framer-motion'
import UpsPage from './pages/UpsPage.tsx'
import UserProfilePage from './pages/UserProfilePage.tsx'
import AssistantsListPage from '@/pages/marketplace/AssistantsListPage.tsx'
import AssistantDetailPage from '@/pages/marketplace/AssistantDetailPage.tsx'
import ToolsListPage from '@/pages/marketplace/ToolsListPage.tsx'
import KnowledgeContainersListPage from '@/pages/marketplace/KnowledgeContainersListPage.tsx'
import ToolDetailPage from '@/pages/marketplace/ToolDetailPage.tsx'
import KnowledgeContainerDetailPage from '@/pages/marketplace/KnowledgeContainerDetailPage.tsx'
import {
  ROUTE_ADMIN_PANEL,
  ROUTE_FAQ,
  ROUTE_FEATURE_VOTING,
  ROUTE_FORBIDDEN,
  ROUTE_MARKETPLACE,
  ROUTE_MARKETPLACE_ASSISTANTS,
  ROUTE_MARKETPLACE_ASSISTANTS_ID,
  ROUTE_MARKETPLACE_ASSISTANTS_NEW,
  ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS,
  ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS_ID,
  ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS_NEW,
  ROUTE_MARKETPLACE_TOOLS,
  ROUTE_MARKETPLACE_TOOLS_ID,
  ROUTE_MARKETPLACE_TOOLS_NEW,
  ROUTE_UPS,
  ROUTE_USER_SETTINGS,
} from '@/constants/routes.ts'
import { Bounce, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTheme } from 'next-themes'
import FeatureVoting from '@/pages/FeatureVotingPage.tsx'
import AdminPanel from "@components/adminPanel/adminPanel.tsx";
const App = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { theme } = useTheme()
  const restoreOriginalUri = useCallback(
    (oktaAuth: OktaAuth, originalUri = '/') => {
      const url = toRelativeUrl(originalUri, globalThis.location.origin)
      navigate(url)
    },
    [navigate],
  )
  return (
    <main className="text-foreground bg-background">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
        transition={Bounce}
      />
      <AnimatePresence mode={'wait'}>
        <Security oktaAuth={oktaAuthClient} restoreOriginalUri={restoreOriginalUri}>
          <Routes location={location}>
            <Route
              path="/login/callback"
              element={
                <LoginCallback
                  errorComponent={UpsPage}
                  loadingElement={<LoadingScreen isLoading={true} />}
                />
              }
            />
            <Route path="/" element={<RequiredAuth />}>
              <Route element={<DefaultLayout />}>
                <Route index element={<SessionContentPage />} />
                <Route path={ROUTE_MARKETPLACE} element={<></>} />
                <Route
                  path={ROUTE_MARKETPLACE_ASSISTANTS}
                  element={<AssistantsListPage />}
                />
                <Route
                  path={ROUTE_MARKETPLACE_ASSISTANTS_NEW}
                  element={<AssistantDetailPage />}
                />
                <Route
                  path={ROUTE_MARKETPLACE_ASSISTANTS_ID}
                  element={<AssistantDetailPage />}
                />

                <Route path={ROUTE_MARKETPLACE_TOOLS} element={<ToolsListPage />} />
                <Route
                  path={ROUTE_MARKETPLACE_TOOLS_NEW}
                  element={<ToolDetailPage />}
                />
                <Route
                  path={ROUTE_MARKETPLACE_TOOLS_ID}
                  element={<ToolDetailPage />}
                />

                <Route
                  path={ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}
                  element={<KnowledgeContainersListPage />}
                />
                <Route
                  path={ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS_NEW}
                  element={<KnowledgeContainerDetailPage />}
                />
                <Route
                  path={ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS_ID}
                  element={<KnowledgeContainerDetailPage />}
                />
                <Route path={ROUTE_FORBIDDEN} element={<ForbiddenPage />} />
                <Route path={ROUTE_FAQ} element={<FaqPage />} />
                <Route path={ROUTE_USER_SETTINGS} element={<UserProfilePage />} />
                <Route path={ROUTE_FEATURE_VOTING} element={<FeatureVoting />} />
                <Route path={ROUTE_ADMIN_PANEL} element={<AdminPanel />} />
              </Route>
            </Route>
            <Route path={ROUTE_UPS} element={<UpsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Security>
      </AnimatePresence>
    </main>
  )
}

export default App
