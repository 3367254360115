import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  PaperAirplaneIcon,
  PaperClipIcon,
  PhotoIcon,
  StopIcon,
} from '@heroicons/react/24/outline'
import {
  Alert,
  Button,
  Kbd,
  Spinner,
  Tooltip,
  useDisclosure,
} from '@nextui-org/react'
import useSignalRStore from '@/states/signalRState.ts'
import './chatWindow.css'
import useAssistantModelStore from '@states/assistantModelStore.ts'
import { AssistantModelAttribute } from '@/interfaces/iAssistantModel.ts'
import documentService from '@/services/documentService.ts'
import useTeamStore from '@/states/teamStore.ts'
import { Role } from '@/interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'
import useUserProfileStore from '@/states/userProfileState.ts'
import useMainStore from '@/states/mainState.ts'
import { iSession } from '@/interfaces/iSession.ts'
import { SignalRStatus } from '@/enums/SignalRStatus.ts'
import { ContentItemType, iContentItem, iMessage } from '@/interfaces/iMessage.ts'
import { Popup } from '@/enums/PopupTypes.ts'
import { isDefaultTeam } from '@/interfaces/iTeam.ts'
import { iDelta, iDocument } from '@/interfaces/iFile.ts'
import { AssistantStatus } from '@/enums/AssistantStatus.ts'
import { uuidv4 } from '@/utils/uuidv4.ts'
import useChatStore from '@states/chatStore.ts'
import IconFetcher from '@components/basic/icon/IconFetcher.tsx'
import QuickPrompts from '@components/chat/QuickPromptCard.tsx'
import { ICON_CHEVRON_DOUBLE_DOWN } from '@/constants/icons.tsx'
import useSessionStore from '@states/sessionStore.ts'
import AssistantWelcomeMessageDisplay from '@components/assistants/AssistantWelcomeMessageDisplay.tsx'
import AssistantSelectDrawer from '@components/assistants/AssistantSelectDrawer.tsx'

const ChatWindowMessageBar = () => {
  const {
    modelId,
    session,
    setSession,
    setIsAssistantGenerating,
    setMessages,
    messages,
    isAssistantGenerating,
    setIsAssistantRunning,
    isAssistantRunning,
    currentAssistantAction,
    createMessage,
    assistant,
    inheritedAssistant,
  } = useChatStore(
    (state) => ({
      modelId: state.assistant?.settings?.modelId,
      setSession: state.setSession,
      session: state.session!,
      currentAssistantAction: state.currentAssistantAction,
      isAssistantRunning: state.isAssistantRunning,
      setIsAssistantRunning: state.setIsAssistantRunning,
      isAssistantGenerating: state.isAssistantGenerating,
      setIsAssistantGenerating: state.setIsAssistantGenerating,
      messages: state.messages,
      setMessages: state.setMessages,
      createMessage: state.createMessage,
      assistant: state.assistant,
      inheritedAssistant: state.inheritedAssistant,
    }),
    shallow,
  )
  const updateSessionInternal = useSessionStore().updateSessionInternal
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()

  const { hasRightTo, selectedTeam } = useTeamStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )

  const { signalRstatus } = useSignalRStore(
    (state) => ({
      signalRstatus: state.signalRstatus,
    }),
    shallow,
  )
  const { setPopup } = useMainStore((state) => ({
    setPopup: state.setPopup,
    // setPopupArgs: state.setPopupArgs,
  }))
  const assistantModels = useAssistantModelStore(
    (state) => state.assistantModels,
    shallow,
  )

  const userProfile = useUserProfileStore((state) => state.userProfile, shallow)
  const startAssistant = useSignalRStore((state) => state.startAssistant, shallow)

  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    if (messageFieldInputRef.current) {
      if (session._messageInputFieldValue) {
        messageFieldInputRef.current.innerHTML =
          session._messageInputFieldValue ?? ''
        setEditorElement(messageFieldInputRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!isAssistantGenerating) messageFieldInputRef.current?.focus()
  }, [isAssistantGenerating])

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined
    if (signalRstatus !== SignalRStatus.Connected) {
      // Set a timer to show the alert after 5 seconds
      timer = setTimeout(() => {
        setShowAlert(true)
      }, 5000)
    } else {
      // Clear the alert if the status is connected
      setShowAlert(false)
    }

    // Cleanup the timer when the component is unmounted or when the status changes
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [signalRstatus])

  // const [content, setContent] = React.useState('');
  const [deltaContent, setDeltaContent] = useState<iDelta[]>([])
  const [editorElement, setEditorElement] = useState<HTMLElement | null>(null)
  const [documents, setDocuments] = useState<iDocument[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const messageFieldInputRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault() // Prevent default Enter behavior
      handleSubmit()
    } else if (e.key === 'Enter' && e.shiftKey) {
      // Insert a new line
      document.execCommand('insertHTML', false, '<br>')
    }
  }

  const dataUrlToFile = (dataUrl: string): File => {
    // convert a data url to a File
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)![1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    const blob = new Blob([u8arr], { type: mime })
    return new File([blob], 'filename', { type: mime })
  }

  const insertImage = (src: string) => {
    const image = new Image()
    image.style.maxWidth = '100px'
    image.style.maxHeight = '100px'
    image.src = src

    if (messageFieldInputRef) {
      const selection = window.getSelection()
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        range.collapse(false) // Collapse the range to the end point
        if (messageFieldInputRef?.current?.contains(range.startContainer)) {
          range.insertNode(image) // Insert the image at the collapsed range
        } else {
          messageFieldInputRef?.current?.appendChild(image) // Append to the end if no selection
        }
      } else {
        messageFieldInputRef?.current?.appendChild(image) // Append to the end if no selection
      }

      // Trigger a reflow and repaint to ensure the image is rendered
      messageFieldInputRef?.current?.offsetHeight

      // Adjust cursor position after image insertion
      const newRange = document.createRange()
      newRange.setStartAfter(image)
      newRange.collapse(true)
      selection!.removeAllRanges()
      selection!.addRange(newRange)

      // Scroll to the newly inserted image if needed
      image.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    // Update the deltaContent stores with the new image
    setDeltaContent([...deltaContent, { type: 'image', value: src }])
  }

  const insertDocuments = (docs: iDocument[]) => {
    // Insert a document into the message field
    docs.forEach((doc) => {
      const fileName = doc.metadata?.attributes?.fileName || 'Document'
      const badge = document.createElement('span')
      badge.textContent = `${fileName} (${doc.size?.tokenCount} tokens)`
      badge.style.display = 'inline-block'
      badge.style.padding = '5px'
      badge.style.margin = '2px'
      badge.style.border = '1px solid #ccc'
      badge.style.borderRadius = '4px'
      badge.style.backgroundColor = '#f0f0f0'
      badge.style.cursor = 'pointer'

      // You may want to add some additional styles to make it look like a badge
      badge.style.fontSize = '12px'
      badge.style.color = '#333'
      badge.setAttribute('data-content-type', 'document')
      badge.setAttribute('data-document-id', doc.id || '')

      // Ensures the badge acts like a single character
      badge.setAttribute('contenteditable', 'false')

      if (messageFieldInputRef) {
        const selection = window.getSelection()
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0)
          range.collapse(false) // Collapse the range to the end point
          if (messageFieldInputRef?.current?.contains(range.startContainer)) {
            range.insertNode(badge) // Insert the badge at the collapsed range
          } else {
            messageFieldInputRef?.current?.appendChild(badge) // Append to the end if no selection
          }
        } else {
          messageFieldInputRef?.current?.appendChild(badge) // Append to the end if no selection
        }

        // Trigger a reflow and repaint to ensure the badge is rendered
        messageFieldInputRef?.current?.offsetHeight

        // Adjust cursor position after badge insertion
        const newRange = document.createRange()
        newRange.setStartAfter(badge)
        newRange.collapse(true)
        selection!.removeAllRanges()
        selection!.addRange(newRange)

        // Scroll to the newly inserted badge if needed
        badge.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    })

    // Update the deltaContent stores with the new document
    setDocuments([...documents, ...docs])
  }

  const hasModelAttribute = useCallback((attribute: AssistantModelAttribute) => {
    const model = assistantModels.find((model) => model.id === modelId)
    return model?.attributes.includes(attribute)
  }, [])

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault() // Prevent the default paste behavior
    const items = e.clipboardData.items
    const currentTarget = e.currentTarget
    for (const item of items) {
      if (item.kind === 'string' && item.type === 'text/plain') {
        item.getAsString((text) => {
          const selection = window.getSelection()
          if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0)
            range.deleteContents() // Delete the current selection

            const textNode = document.createTextNode(text)
            range.insertNode(textNode)

            // Move the cursor after the inserted text
            range.setStartAfter(textNode)
            range.collapse(true)
            selection.removeAllRanges()
            selection.addRange(range)
          } else if (editorElement) {
            // Fallback: append at the end if there's no selection
            messageFieldInputRef?.current?.appendChild(document.createTextNode(text))
          }
          setEditorElement(currentTarget)
          messageFieldInputRef!.current!.scrollTop = currentTarget.scrollHeight
        })
      } else if (item.kind === 'file' && item.type.startsWith('image/')) {
        const file = item.getAsFile()
        if (file) {
          const reader = new FileReader()
          reader.onload = (event) => {
            insertImage(event.target!.result as string)
          }
          reader.readAsDataURL(file)
        }
      }
    }
  }

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files[0]) {
      const reader = new FileReader()
      reader.onload = (event) => {
        insertImage(event.target!.result as string)
      }
      reader.readAsDataURL(files[0])

      // Clear the file input to allow the same file to be selected again
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  // Function to handle input events and update content stores
  const handleSubmit = async () => {
    if (
      isAssistantGenerating ||
      signalRstatus !== SignalRStatus.Connected ||
      (!editorElement && !deltaContent.length)
    ) {
      return
    }

    // Set the session as generating to prevent multiple submissions
    setIsAssistantGenerating(true)
    setIsAssistantRunning(true)
    if (messageFieldInputRef?.current) {
      const htmlContent = messageFieldInputRef?.current?.innerHTML
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = htmlContent

      // Construct the Delta objects based on the content
      const newDeltaContent: iDelta[] = []
      tempDiv.childNodes.forEach((node: ChildNode) => {
        if (node.nodeType === Node.TEXT_NODE) {
          // Text node
          const textValue = node.nodeValue || ''
          if (textValue.trim() !== '') {
            newDeltaContent.push({ type: 'text', value: textValue })
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          // Element node (e.g., images, documents)
          const element = node as HTMLElement
          if (element.tagName === 'IMG' && element.getAttribute('src')) {
            const src = element.getAttribute('src') || ''
            newDeltaContent.push({ type: 'image', value: src })
          } else if (
            element.tagName === 'SPAN' &&
            element.getAttribute('data-content-type') === 'document'
          ) {
            const documentId = element.getAttribute('data-document-id') || ''
            const document = documents.find((doc) => doc.id === documentId)
            if (document) {
              newDeltaContent.push({
                type: 'document',
                value: JSON.stringify(document),
              })
            }
          }
        }
      })

      setDeltaContent(newDeltaContent)
      messageFieldInputRef.current.innerHTML = ''
      // reset saved text value
      session._messageInputFieldValue = ''
      updateSessionInternal(session)

      setDocuments([])
      setEditorElement(null)
      const contentPromises = newDeltaContent.map(
        (delta, index) =>
          new Promise<iContentItem>((resolve) => {
            if (delta.type === 'image') {
              const file = dataUrlToFile(delta.value!)
              documentService
                .uploadDocument([file])
                .then((uploadResponse: iDocument[]) => {
                  const imageDocument = uploadResponse[0]
                  resolve({
                    type: ContentItemType.Image,
                    content: imageDocument.id || '',
                    index: index,
                    mimeType: imageDocument.contentType,
                  })
                })
            } else if (delta.type === 'document') {
              resolve({
                type: ContentItemType.Document,
                content: delta.value ?? '',
                index: index,
              })
            } else {
              resolve({
                type: ContentItemType.Text,
                content: delta.value ?? '',
                index: index,
              })
            }
          }),
      )

      Promise.all(contentPromises).then((contentItems) => {
        if (contentItems.length > 0) {
          handleSendMessage(undefined, contentItems, session!, null).then(() => {
            createDummyAssistantMessage()
          })
        }
      })
    }
  }

  const createDummyAssistantMessage = () => {
    const dummyMessage: iMessage = {
      role: 'assistant',
      sessionId: session.id!,
      _tmpId: uuidv4(),
    }
    setIsAssistantGenerating(true)
    setMessages([dummyMessage, ...messages])
    setSession(session)
  }

  const handleSendMessage = async (
    content: string | undefined,
    contentItem: iContentItem[] | undefined,
    session: iSession,
    file: File | Blob | null,
  ) => {
    try {
      const uM: iMessage = {
        role: 'user',
        content: content,
        contentItems: contentItem,
        contentType: 'userMessage',
        sessionId: session.id!,
        ownerId: userProfile!.id!,
        isAudio: !!file,
      }
      await createMessage(uM)
      startAssistant(session.id!, userProfile!.id!)
    } catch (error) {
      console.error('Error: ', error)
    } finally {
      //updateSessionInternal(session)
    }
  }

  const handleStopGenerating = async () => {
    if (!isAssistantGenerating) return
    if (session) {
      session.assistantStatus = AssistantStatus.Cancelled
      //TODO: malte means that stop generation dont work anymore
      // updateSession(session).then(() => {
      //   session._isGenerating = false
      //   setIsGenerating(false)
      //   updateSessionInternal(session)
      // })
    }
  }

  const handlePromptSelect = (prompt: string) => {
    if (
      messageFieldInputRef.current &&
      messageFieldInputRef.current.innerHTML !== prompt
    ) {
      messageFieldInputRef.current.innerHTML = prompt
      setEditorElement(messageFieldInputRef.current)
      return
    }
    if (messageFieldInputRef.current) {
      messageFieldInputRef.current.innerHTML = prompt
      setEditorElement(messageFieldInputRef.current)
      handleSubmit()
    }
  }
  const isCleanSession = !messages.length

  return (
    <div
      className={`${isCleanSession ? 'bottom-[40%]' : ' bottom-0 z-10 '} mt-8 sticky ease-in-out duration-700`}
    >
      {isCleanSession && (
        <div className="flex flex-col items-center gap-5">
          <IconFetcher
            entityId={assistant?.baseAssistantId ?? assistant!.id!}
            entityType={'Assistant'}
            imageId={assistant?.image}
          />
          <div className="flex flex-col items-center truncate ml-2">
            <span className="text-2xl bold truncate max-w-full">
              {assistant?.title}
            </span>
            <span className="text-tiny align-start text-default-400 max-w-full">
              by {(inheritedAssistant || assistant)?.ownerEmail}
            </span>
          </div>

          <AssistantWelcomeMessageDisplay
            assistant={assistant!}
          ></AssistantWelcomeMessageDisplay>

          {assistant?.quickPrompts && (
            <div className="flex justify-center">
              {assistant?.quickPrompts && (
                <QuickPrompts
                  prompts={assistant.quickPrompts}
                  onPromptSelect={handlePromptSelect}
                />
              )}
            </div>
          )}
        </div>
      )}
      <div className={'z-10 relative'}>
        {/* Stop button */}
        {showAlert ? (
          <div className={'flex mt-3 justify-center'}>
            <Alert
              classNames={{
                base: 'max-w-fit',
              }}
              variant="flat"
              color="primary"
              title={`${signalRstatus}...`}
              description="Trying to fix connection"
              icon={<Spinner></Spinner>}
            ></Alert>
          </div>
        ) : (
          <div
            className={'absolute -top-9 w-full grid grid-cols-2 mt-3 content-end'}
          >
            <div className={'self-end'}></div>
            <div className={'flex justify-end'}>
              {isAssistantRunning && (
                <div className={'flex justify-center items-center text-sm'}>
                  {currentAssistantAction?.text ?? 'Doing something'} ...
                  <Spinner className="pl-2" size="sm" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={'chat-message-window bg-content1 rounded-lg shadow-medium'}>
        {/* Message window */}
        <form
          className={'w-full mt-2 relative'}
          onSubmit={() => handleSubmit()}
          key={'submit_' + session.id}
        >
          <div
            className={'flex items-center space-x-2 w-full relative'}
            key={'div_' + session.id}
          >
            <div className="flex-grow">
              <div
                ref={messageFieldInputRef}
                onInput={(e) => {
                  const val = e.currentTarget.innerHTML ? e.currentTarget : null
                  session._messageInputFieldValue = val?.innerHTML
                  updateSessionInternal(session)
                  setEditorElement(val)
                }}
                contentEditable={!isAssistantGenerating}
                onPaste={handlePaste}
                onKeyDown={async (e) => await handleKeyDown(e)}
                className={`transition-all max-w-full p-4  textarea break-words break-all whitespace-pre-wrap min-h-12 max-h-72 overflow-auto w-full resize-none cursor-text `}
                data-placeholder="Type your message here..."
              ></div>
              <input
                type="file"
                accept="image/*"
                onChange={handleAddImage}
                ref={fileInputRef}
                style={{ display: 'none' }} // Hide the input, but keep it in the DOM
              />
            </div>
          </div>
          <div className="flex items-start p-2">
            <div>
              {(hasRightTo(Role.Member) || isDefaultTeam(selectedTeam!)) && (
                <>
                  <Tooltip content={'Include Files'} className={'max-w-96'}>
                    <Button
                      isDisabled={isAssistantRunning}
                      isIconOnly
                      variant={'light'}
                      onPress={() => {
                        setPopup(Popup.Files, { onSelect: insertDocuments })
                      }}
                    >
                      <PaperClipIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 "
                      />
                    </Button>
                  </Tooltip>
                  {hasModelAttribute(AssistantModelAttribute.imageProcessing) && (
                    <Tooltip content={'Add Image'} className={'max-w-96'}>
                      <Button
                        onPress={() => fileInputRef.current?.click()}
                        isIconOnly
                        variant={'light'}
                      >
                        <PhotoIcon
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 "
                        />
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
            </div>

            <div className="flex items-center ml-auto ">
              Use <Kbd className="mx-2" keys={['shift', 'enter']}></Kbd> for new
              Line.
              {isAssistantGenerating ? (
                <Tooltip content={'Stop generating'} className={'max-w-96'}>
                  <Button
                    // isLoading={stopGeneratingInProgress}
                    // isDisabled={stopGeneratingInProgress}
                    variant="light"
                    isIconOnly
                    key={'stopButton_' + session.id}
                    onPress={handleStopGenerating}
                  >
                    <StopIcon
                      color={'error'}
                      fill="red"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-red-500"
                      key={'stopIcon_' + session.id}
                    />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip content={'Send prompt'} className={'max-w-96'}>
                  <Button
                    isIconOnly
                    isDisabled={
                      isAssistantRunning ||
                      signalRstatus !== SignalRStatus.Connected ||
                      (!editorElement && !deltaContent.length)
                    }
                    variant={'light'}
                    key={'paperAirplaneButton_' + session.id}
                    onPress={async () => handleSubmit()}
                  >
                    <PaperAirplaneIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                      key={'paperAirplaneIcon_' + session.id}
                    />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </form>
        {/* Buttons under Message Window */}
      </div>
      {isCleanSession && (
        <div className="mt-7 flex justify-center">
          <Button color="primary" className="h-fit" onPress={onOpen}>
            <div className="flex flex-col items-center py-2">
              <span className="mb-2">Discover more Assistants</span>
              <ICON_CHEVRON_DOUBLE_DOWN />
            </div>
          </Button>
        </div>
      )}
      <AssistantSelectDrawer
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
      ></AssistantSelectDrawer>
    </div>
  )
}

export default React.memo(ChatWindowMessageBar)
