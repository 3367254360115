import React from 'react'
import GaiaLogo from '../components/basic/logo/GaiaLogo.tsx'
import { Alert, Button } from '@nextui-org/react'
import { Link } from 'react-router-dom'
import { TEST_PAGE_UPS } from '../constants/testingIds.ts'
type UpsPageProps = {
  error?: Error
}
function UpsPage({ error }: UpsPageProps) {
  return (
    <div
      data-testid={TEST_PAGE_UPS}
      className={'fix flex-col flex justify-center items-center w-[100vw] h-[100vh]'}
    >
      <GaiaLogo className={'w-44 h-44'} />
      <h1 className={'gaia-fade-in font-bold text-7xl'}>GAIA</h1>
      <div className={'mt-5 text-2xl'}>Ups - something went wrong</div>
      {error && (
        <div className={'mt-5 max-w-96'}>
          <Alert
            variant="solid"
            color={'warning'}
            title={error?.name}
            description={error?.message}
          ></Alert>
        </div>
      )}
      <Link to="/" className={'mt-5'}>
        <Button>Retry</Button>
      </Link>
    </div>
  )
}

export default UpsPage
