import { jwtDecode } from 'jwt-decode'
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js'

const CLIENT_ID = import.meta.env.VITE_CLIENT_ID
const CALLBACK_PATH = import.meta.env.VITE_CALLBACK_PATH
const ISSUER = import.meta.env.VITE_ISSUER
const HOST = import.meta.env.VITE_HOST
const REDIRECT_URI = `${HOST}${CALLBACK_PATH}`
const SCOPES = import.meta.env.VITE_SCOPES
const TOKEN_URL = import.meta.env.VITE_TOKEN_URL
const REVOKE_URL = import.meta.env.VITE_REVOKE_URL

if (!SCOPES || !CLIENT_ID || !CALLBACK_PATH || !ISSUER || !HOST) {
  throw new Error('All environmental variables must be set')
}

const oktaConfig: OktaAuthOptions = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
  tokenUrl: `${import.meta.env.VITE_BACKEND_URL}${TOKEN_URL}`,
  revokeUrl: `${import.meta.env.VITE_BACKEND_URL}${REVOKE_URL}`,
  responseType: 'code',
  devMode: true,
  tokenManager: {
    autoRenew: true,
  },
  services: {
    autoRenew: false,
    autoRemove: false,
  },
  cookies: {
    secure: import.meta.env.MODE === 'production',
    sameSite: true,
  },
}

export const oktaAuthClient = new OktaAuth(oktaConfig)

export enum AuthRole {
  Blocked,
  User,
  Developer,
  Admin,
}

interface IJWTResponse {
  authLevel: string
  firstname: string
  lastname: string
  displayname: string
  country: string
  email_address: string
  b2cid: string
  sub: string
}
export function getUser() {
  const decoded = jwtDecode<IJWTResponse>(getToken())
  return {
    firstname: decoded.firstname,
    lastname: decoded.lastname,
    email: decoded.email_address,
    displayName: decoded.displayname,
  }
}

export function getUserEmail() {
  try {
    const decoded = jwtDecode<IJWTResponse>(getToken())
    return decoded.email_address
  } catch (error) {
    return false
  }
}

export function getUserKey(): string {
  try {
    const decoded = jwtDecode<IJWTResponse>(getToken())
    return decoded.b2cid || decoded.sub
  } catch (error) {
    throw new Error()
  }
}

export function getToken(): string {
  return oktaAuthClient.getIdToken() as string
}

export function getAccessToken(): string {
  return oktaAuthClient.getAccessToken() as string
}

export function getRefreshToken() {
  return oktaAuthClient.getRefreshToken() as string
}

export function getUserCountry() {
  try {
    const decoded = jwtDecode<IJWTResponse>(getToken())
    return decoded.country.toLowerCase()
  } catch (error) {
    return false
  }
}
