import React, { useState } from 'react'
import {
  LifebuoyIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { Divider } from '@nextui-org/react'
import TeamSelect from '../../team/TeamSelect.tsx'
import { useLocation, useNavigate } from 'react-router-dom'
import GaiaLogo from '../../basic/logo/GaiaLogo.tsx'
import SideNav from '../../basic/sidenav/Sidenav.tsx'
import SidenavItem from '../../basic/sidenav/SidenavItem.tsx'
import useMainStore from '../../../states/mainState.ts'
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next'
import AccountMenu from '../../user/AccountMenu.tsx'
import SidenavDetailContent from '../../basic/sidenav/SidenavDetailContent.tsx'
import AppSidenavFolderContent from './contents/AppSidenavFolderContent.tsx'
import { Popup } from '@/enums/PopupTypes.ts'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  ICON_ASSISTANT,
  ICON_CHAT,
  ICON_FOLDER,
  ICON_KNOWLEDGE_CONTAINER,
  ICON_TEAM,
  ICON_TOOL,
} from '@/constants/icons.tsx'
import useTeamStore from '@states/teamStore.ts'
import {
  ROUTE_FAQ,
  ROUTE_MARKETPLACE_ASSISTANTS,
  ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS,
  ROUTE_MARKETPLACE_TOOLS,
} from '@/constants/routes.ts'
import SupportMenu from '@components/user/SupportMenu.tsx'

const AppSideNav = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [selectedSidenavItem, setSelectedSidenavItem] = useState<string | null>('')
  const {
    setIsSidebarOpen,
    isSidebarOpen,
    setIsSidebarContentOpen,
    isSidebarContentOpen,
    setIsSidebarPinned,
    isSidebarPinned,
  } = useMainStore(
    (state) => ({
      isSidebarOpen: state.isSidebarOpen,
      setIsSidebarOpen: state.setIsSidebarOpen,
      isSidebarContentOpen: state.isSidebarContentOpen,
      setIsSidebarContentOpen: state.setIsSidebarContentOpen,
      isSidebarPinned: state.isSidebarPinned,
      setIsSidebarPinned: state.setIsSidebarPinned,
    }),
    shallow,
  )

  const { isLoading: isTeamLoading } = useTeamStore(
    (state) => ({
      isLoading: state.isLoading,
    }),
    shallow,
  )

  // useHotkeys('ctrl+s, meta+s', (event) => {
  //   event.preventDefault() // Prevent default browser behavior
  //   setPopup(Popup.Spotlight)
  // })

  const openSidenavDetailContent = (contentType: string) => {
    setSelectedSidenavItem(contentType)
    if (selectedSidenavItem === contentType)
      setIsSidebarContentOpen(!isSidebarContentOpen)
    else setIsSidebarContentOpen(true)
  }
  const setPopup = useMainStore((state) => state.setPopup)

  return (
    <SideNav
      className={'z-30'}
      contentWidth="300px"
      open={isSidebarOpen}
      pinnedDetailContent={isSidebarPinned}
      openDetailContent={isSidebarContentOpen}
      onOpenDetailContent={setIsSidebarContentOpen}
      detailContent={
        <SidenavDetailContent title={'Your Sessions'}>
          {selectedSidenavItem === 'sessionFolders' && (
            <AppSidenavFolderContent></AppSidenavFolderContent>
          )}
        </SidenavDetailContent>
      }
      onUpdatePinned={setIsSidebarPinned}
      header={
        <div className={'flex flex-col items-center gap-2'}>
          <SidenavItem
            onClick={() => navigate('/')}
            icon={<GaiaLogo className="h-10 w-10" />}
          ></SidenavItem>
          <SidenavItem
            isLoading={isTeamLoading}
            tooltip={'Switch team'}
            icon={
              <TeamSelect
                isIconOnly
                className={'flex-grow-0'}
                classNames={{
                  trigger: 'bg-ghost shadow-none p-0 h-auto min-h-1 w-fit h-auto',
                  mainWrapper: 'w-fit',
                  base: 'w-fit',
                  innerWrapper: 'w-auto',
                  selectorIcon: 'hidden',
                  popoverContent: 'w-auto',
                }}
              />
            }
          ></SidenavItem>
          <div className="w-full">
            <Divider className={'my-2'}></Divider>
          </div>
        </div>
      }
      footer={
        <>
          <Divider className={'mb-2'}></Divider>
          {/*<SidenavItem*/}
          {/*  tooltip={'Search'}*/}
          {/*  onClick={() => setPopup(Popup.Spotlight)}*/}
          {/*  icon={<MagnifyingGlassIcon className={'w-5 h-5'} />}*/}
          {/*></SidenavItem>*/}
          <SidenavItem
            tooltip="FAQ´s"
            isActive={location.pathname === ROUTE_FAQ}
            onClick={() => navigate(ROUTE_FAQ)}
            icon={<QuestionMarkCircleIcon className="h-5 w-5" />}
          ></SidenavItem>
          <SidenavItem tooltip="Support">
            <SupportMenu />
          </SidenavItem>
          <SidenavItem tooltip="User Settings">
            <AccountMenu />
          </SidenavItem>
        </>
      }
    >
      <SidenavItem
        tooltip={'Current active session'}
        onClick={() => navigate('/')}
        isActive={location.pathname === '/'}
        icon={<ICON_CHAT />}
      ></SidenavItem>
      <SidenavItem
        tooltip={'Your Sessions'}
        onClick={() => openSidenavDetailContent('sessionFolders')}
        icon={<ICON_FOLDER className={'w-5 h-5'} />}
      ></SidenavItem>
      <Divider></Divider>
      <SidenavItem
        tooltip={'Manage your teams'}
        isActive={location.pathname === '/marketplace/teams'}
        onClick={() => setPopup(Popup.UserManagement)}
        icon={<ICON_TEAM className={'w-5 h-5'} />}
      ></SidenavItem>
      <SidenavItem
        tooltip={'All tools'}
        onClick={() => navigate(ROUTE_MARKETPLACE_TOOLS)}
        isActive={location.pathname === ROUTE_MARKETPLACE_TOOLS}
        icon={<ICON_TOOL />}
      ></SidenavItem>
      <SidenavItem
        tooltip={'All Knowledge-Containers'}
        isActive={location.pathname === ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}
        onClick={() => navigate(ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS)}
        icon={<ICON_KNOWLEDGE_CONTAINER />}
      ></SidenavItem>
      <SidenavItem
        tooltip={'All Assistants'}
        isActive={location.pathname === ROUTE_MARKETPLACE_ASSISTANTS}
        onClick={() => navigate(ROUTE_MARKETPLACE_ASSISTANTS)}
        icon={<ICON_ASSISTANT />}
      ></SidenavItem>
    </SideNav>
  )
}

export default AppSideNav
