import { useEffect, useRef, useState } from 'react'
import { SignalRStatus } from '@/enums/SignalRStatus.ts'
import { iMessage } from '@/interfaces/iMessage.ts'
import { AssistantStatus } from '@/enums/AssistantStatus.ts'
import { iAssistantAction } from '@/interfaces/iAssistantAction.ts'
import useSignalRStore from '@states/signalRState.ts'
import { shallow } from 'zustand/shallow'
import {
  signalRJoinSession,
  signalRLeaveSession,
  signalRStartAssistant,
} from '@/utils/signalR.ts'
import useChatStore from '@states/chatStore.ts'
import SessionService from '@/services/sessionService.ts'
import { upsertChatMessage } from '@/utils/upsertChatMessage.ts'
import { iDocumentChunk } from '@/interfaces/iDocumentChunk.ts'
import { iDocumentItem } from '@/interfaces/iDocumentItem.ts'
import useSessionStore from '@states/sessionStore.ts'
import { toast } from 'react-toastify'

type useSignalRProps = {
  userId: string
}
const useSignalR = ({ userId }: useSignalRProps) => {
  const messageContentMap = useRef<
    Array<{ messageId: string; content: string; contentChunkStartIndex: number }>
  >([])
  const updateScheduled = useRef(false)
  const [autoScrolling, setAutoScrolling] = useState(true)
  const [hasReconnected, setHasReconnected] = useState(false)
  const updateSessionInternal = useSessionStore().updateSessionInternal
  const {
    session,
    setIsAssistantGenerating,
    setIsAssistantRunning,
    setMessages,
    setCurrentAssistantAction,
    setSession,
  } = useChatStore(
    (state) => ({
      session: state.session!,
      setIsAssistantGenerating: state.setIsAssistantGenerating,
      setIsAssistantRunning: state.setIsAssistantRunning,
      setMessages: state.setMessages,
      setSession: state.setSession,
      setCurrentAssistantAction: state.setCurrentAssistantAction,
    }),
    shallow,
  )
  const { connection, signalRstatus, leaveSession, joinSession } = useSignalRStore(
    (state) => ({
      connection: state.connection,
      signalRstatus: state.signalRstatus,
      joinSession: state.joinSession,
      leaveSession: state.leaveSession,
    }),
    shallow,
  )
  useEffect(() => {
    if (signalRstatus === SignalRStatus.Reconnecting) {
      setHasReconnected(true)
    }

    // get messages after reconnection
    if (signalRstatus === SignalRStatus.Connected && hasReconnected) {
      SessionService.getMessages(session.id!).then((messages) => {
        setIsAssistantGenerating(false)
        setMessages(messages.data)
        setHasReconnected(false)
      })
    }
  }, [signalRstatus])

  const upsertChunkMessage = (
    messageId: string,
    messages: iMessage[],
    contentChunk: string,
    contentChunkStartIndex: number,
  ) => {
    const messageIndex = messages.findIndex((m) => m.id === messageId)

    if (messageIndex === -1) {
      // Handle the case where the message is not found
      console.error(`Message with ID ${messageId} not found.`)
      return messages
    }

    // Get the current content of the message
    let currentContent = messages[messageIndex]?.content
    // Ensure currentContent is a valid string and not 'undefined'
    if (!currentContent) {
      currentContent = ''
    }

    // Ensure contentChunk is a valid string and not 'undefined'
    if (!contentChunk) {
      contentChunk = ''
    }
    // Ensure that contentChunkStartIndex is within the bounds of the current content
    const validStartIndex = Math.min(contentChunkStartIndex, currentContent.length)
    // Update the content starting from contentChunkStartIndex
    const beforeChunk = currentContent.substring(0, validStartIndex)
    const updatedContent = beforeChunk + contentChunk
    // Update the message content
    messages[messageIndex] = {
      ...messages[messageIndex],
      content: updatedContent,
    }
    return messages
  }

  const processMessageUpdates = () => {
    const messages = useChatStore.getState().messages ?? []
    let updatedMessages = messages.slice()

    while (messageContentMap.current.length > 0) {
      const chunk = messageContentMap.current.shift()
      if (!chunk) return
      updatedMessages = upsertChunkMessage(
        chunk.messageId,
        updatedMessages,
        chunk.content,
        chunk.contentChunkStartIndex,
      )
    }

    setMessages(updatedMessages)
    updateScheduled.current = false
  }

  const scheduleMessageUpdates = () => {
    if (!updateScheduled.current) {
      updateScheduled.current = true
      requestAnimationFrame(processMessageUpdates)
    }
  }

  const onCompletionContentStream = (m: {
    messageId: string
    contentChunk: string
    contentChunkStartIndex: number
  }) => {
    if (!session) return
    if (m.contentChunk === undefined) return
    messageContentMap.current.push({
      messageId: m.messageId,
      content: m.contentChunk,
      contentChunkStartIndex: m.contentChunkStartIndex,
    })
    scheduleMessageUpdates()
  }

  const onCompletionStart = (message: iMessage) => {
    let messages = useChatStore.getState().messages
    if (!session) return
    if (message.sessionId !== session.id) return
    messages = upsertChatMessage(message)
    setIsAssistantGenerating(true)
    setIsAssistantRunning(true)
    setMessages(messages)
  }

  const onCompletionEnd = (message: iMessage) => {
    const session = useChatStore.getState().session
    if (!session) return
    if (message.sessionId !== session.id) return
    const messages = useChatStore.getState().messages
    const messageIndex = messages.findIndex((m) => m.id === message.id)
    if (messages.length && messageIndex > -1) {
      messages[messageIndex] = message
      setIsAssistantGenerating(false)
      setMessages(messages)
    }
  }

  const onCompletionError = (e: string) => {
    const session = useChatStore.getState().session
    if (!session) return
    toast.error(e)
    console.error(e)
  }

  const onAssistantError = (e: string) => {
    const session = useChatStore.getState().session
    if (!session) return
    toast.error(e)
    console.error(e)
    setIsAssistantGenerating(false)
    session.assistantStatus = AssistantStatus.Idle
  }

  const onAssistantCancelled = (e: string) => {
    const session = useChatStore.getState().session
    if (!session) return
    toast.error(e)
    console.error(e)
    setIsAssistantGenerating(false)
    setIsAssistantRunning(false)
    session.assistantStatus = AssistantStatus.Idle
  }
  const onAssistantStart = () => {
    setAutoScrolling(true)
  }

  const onAssistantAction = (action: iAssistantAction) => {
    const session = useChatStore.getState().session
    if (!session) return
    setCurrentAssistantAction(action)
  }
  const sessionDocumentChunk = (m: iDocumentChunk[]) => {
    const session = useChatStore.getState().session
    if (!session) return
    session.documentChunks = m
    setSession(session)
  }

  const sessionDocumentItem = (m: iDocumentItem[]) => {
    const session = useChatStore.getState().session
    if (!session) return
    session.documentItems = m
    setSession(session)
  }

  const onAssistantEnd = () => {
    const session = useChatStore.getState().session
    if (!session) return
    setAutoScrolling(true)
    setIsAssistantGenerating(false)
    setIsAssistantRunning(false)
    setCurrentAssistantAction(null)
  }
  const onTitleUpdate = ({ title }: { title: string; sessionId: string }) => {
    const session = useChatStore.getState().session
    if (!session) return
    session.title = title
    setIsAssistantRunning(false)
    // TODO: update title from sessionListStore
    updateSessionInternal(session)
  }

  // Subscribe to the group (session) when the component mounts and unsubscribe when it unmounts
  useEffect(() => {
    if (!session || !connection || signalRstatus !== SignalRStatus.Connected) return
    joinSession(session.id!, userId)

    connection.on('sessionDocumentItem', sessionDocumentItem)
    connection.on('sessionDocumentChunk', sessionDocumentChunk)
    connection.on('completionContentStream', onCompletionContentStream)
    connection.on('completionStart', onCompletionStart)
    connection.on('completionEnd', onCompletionEnd)
    connection.on('completionError', onCompletionError)
    connection.on('assistantError', onAssistantError)
    connection.on('assistantCancelled', onAssistantCancelled)
    connection.on('assistantStart', onAssistantStart)
    connection.on('assistantEnd', onAssistantEnd)
    connection.on('sessionTitle', onTitleUpdate)
    connection.on('assistantAction', onAssistantAction)
    // Cleanup function
    return () => {
      leaveSession(session.id!, userId)
      connection.off('sessionDocumentItem', sessionDocumentItem)
      connection.off('sessionDocumentChunk', sessionDocumentChunk)
      connection.off('completionContentStream', onCompletionContentStream)
      connection.off('completionStart', onCompletionStart)
      connection.off('completionEnd', onCompletionEnd)
      connection.off('completionError', onCompletionError)
      connection.off('assistantError', onAssistantError)
      connection.off('assistantCancelled', onAssistantCancelled)
      connection.off('assistantStart', onAssistantStart)
      connection.off('assistantEnd', onAssistantEnd)
      connection.off('sessionTitle', onTitleUpdate)
      connection.off('assistantAction', onAssistantAction)
    }
  }, [connection, signalRstatus])

  return {
    upsertChatMessage,
    signalRStartAssistant,
    signalRJoinSession,
    signalRLeaveSession,
    signalRstatus,
    setAutoScrolling,
    autoScrolling,
  }
}
export default useSignalR
