import React, { useEffect, useMemo, useRef } from 'react'

import StackNavigator, {
  StackNavigatorHandle,
} from '@components/basic/stackNavigator/StackNavigator.tsx'
import ChatDocumentsGeneralView from '@components/session/sessionChatControlViews/documents/ChatDocumentsGeneralView.tsx'
import ChatDocumentsDetailView from '@components/session/sessionChatControlViews/documents/ChatDocumentsDetailView.tsx'
import useChatStore from '@states/chatStore.ts'
import ChatMessageDetailView from '@components/session/sessionChatControlViews/ChatMessageDetailView.tsx'

type ChatDocumentsViewProps = {
  routeParams?: {
    route?: 'Overview' | 'Detail' | 'MessageDetail' | string
    value?: Record<string, any>
  } | null
}
// Main Component
const ChatDocumentsView = ({ routeParams }: ChatDocumentsViewProps) => {
  const documentChunks = useChatStore((state) => state.session?.documentChunks)
  const documentItems = useChatStore((state) => state.session?.documentItems)
  const setChatControlContentRoute = useChatStore(
    (state) => state.setChatControlContentRoute,
  )
  const stackNavigatorRef = useRef<StackNavigatorHandle>(null)

  const routes = useMemo(() => {
    return {
      Overview: {
        component: ChatDocumentsGeneralView,
        props: { documentChunks, documentItems },
      },
      Detail: {
        component: ChatDocumentsDetailView,
        props: {},
      },
      MessageDetail: {
        component: ChatMessageDetailView,
        props: {
          message: routeParams?.value?.message,
          selectedTab: routeParams?.value?.selectedTab,
          selectedCitation: routeParams?.value?.selectedCitation,
        },
      },
    }
  }, [documentChunks, documentItems])

  useEffect(() => {
    if (routeParams?.route) {
      const currentRoute = stackNavigatorRef.current?.navigation.getCurrentRoute()
      if (currentRoute === routeParams.route) {
        stackNavigatorRef.current?.navigation.replace(routeParams?.route, {
          ...routeParams.value,
        })
      } else {
        stackNavigatorRef.current?.navigation.push(routeParams?.route, {
          ...routeParams.value,
        })
      }
    }
  }, [routeParams])

  return (
    <StackNavigator
      onBreadCrumClick={(routeName, props) =>
        setChatControlContentRoute('artifacts', routeName, props)
      }
      onPop={() => setChatControlContentRoute('artifacts')}
      ref={stackNavigatorRef}
      initialRoute={'Overview'}
      routes={routes}
    />
  )
}

export default React.memo(ChatDocumentsView)
