import React, { useEffect } from 'react'
import AssistantCard from './AssistantCard.tsx'
import { Button } from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import useChatStore from '@states/chatStore.ts'
import SearchInput from '../basic/searchInput/SearchInput.tsx'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import SkeletonCard from '@components/skeletons/SkeletonCard.tsx'
import useAssistantStore from '@states/assistantStore.ts'
import { AccessRight } from '@/interfaces/iShared.ts'
import { ROUTE_MARKETPLACE_ASSISTANTS } from '@/constants/routes.ts'
import LoadMoreButton from '@components/basic/loadMoreButton/LoadMoreButton.tsx'

type AssistantsListViewProps = {
  variant?: 'default' | 'select'
  onApply?: (item: iAssistant) => void
  navigation?: {
    push: (routeName: string, props: Record<string, any>) => void
    pop: () => void
    reset: (routeName: string, props: Record<string, any>) => void
    replace: (routeName: string, props: Record<string, any>) => void
    getCurrentRoute: () => string
  }
}
function AssistantsListView({
  variant = 'default',
  navigation,
  onApply,
}: AssistantsListViewProps) {
  const navigate = useNavigate()
  const { changeAssistant } = useChatStore(
    (state) => ({
      changeAssistant: state.changeAssistant,
    }),
    shallow,
  )
  const {
    deleteAssistant,
    loadAssistants,
    managedAssistants,
    isLoadingManagedAssistants,
    setSearch,
    search,
    sharedAssistants,
    isLoadingSharedAssistants,
  } = useAssistantStore(
    (state) => ({
      sharedAssistants: state.sharedAssistants,
      isLoadingSharedAssistants: state.isLoadingSharedAssistants,
      managedAssistants: state.managedAssistants,
      isLoadingManagedAssistants: state.isLoadingManagedAssistants,
      loadAssistants: state.loadAssistants,
      search: state.search,
      setSearch: state.setSearch,
      deleteAssistant: state.deleteAssistant,
    }),
    shallow,
  )

  useEffect(() => {
    if (sharedAssistants.total) return
    refresh(0)
  }, [])

  const loadManagedAssistants = (offset: number) => {
    const managedOffset = offset ?? managedAssistants.offset
    loadAssistants({
      accessRight: AccessRight.Write,
      searchTerm: search.text,
      offset: managedOffset,
    }).catch((e) => console.error(e))
  }

  const loadSharedAssistants = (offset: number) => {
    const sharedOffset = offset ?? sharedAssistants.offset
    loadAssistants({
      accessRight: AccessRight.ReadOnly,
      searchTerm: search.text,
      offset: sharedOffset,
    }).catch((e) => console.error(e))
  }

  const refresh = (offset: number) => {
    loadManagedAssistants(offset)
    loadSharedAssistants(offset)
  }

  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    () => {
      refresh(0)
    },
    // delay in ms
    800,
  )

  const handleApply = async (assistant: iAssistant) => {
    if (variant === 'select') {
      changeAssistant(assistant)
      onApply && onApply(assistant)
    }
  }
  const handleEditClick = (assistant: iAssistant) => {
    navigate(`${ROUTE_MARKETPLACE_ASSISTANTS}/${assistant.id}`, {
      state: { isEditMode: true },
    })
  }

  const handleOnClick = (assistant: iAssistant) => {
    if (variant === 'default')
      navigate(`${ROUTE_MARKETPLACE_ASSISTANTS}/${assistant.id}`)
    else
      navigation && navigation.push('Detail', { value: assistant, isDisabled: true })
  }

  return (
    <div className="mx-auto overflow-visible rounded-xl max-w-[1500px] w-full">
      <div className="flex flex-col w-full">
        {/* Search and Filter */}
        <div className="flex justify-between items-center mb-6">
          <SearchInput
            searchTerm={search.text}
            setSearchTerm={(searchTerm) => {
              setSearch({ text: searchTerm })
              debounced()
            }}
            placeholder="Search for Assistant, Owner, Description or Instruction"
          >
            <div className="flex items-center gap-2">
              <Button
                isIconOnly
                variant="light"
                onPress={() => refresh(0)}
                className="mr-2"
              >
                <ArrowPathIcon className="h-6 w-6" />
              </Button>
            </div>
          </SearchInput>
        </div>

        {/* Loading Indicator */}

        <>
          {/* Own Assistants Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">
              Your manageable Assistants
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingManagedAssistants && !managedAssistants.offset ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : managedAssistants.data.length > 0 ? (
                <>
                  {managedAssistants.data.map((assistant) => (
                    <AssistantCard
                      value={assistant}
                      handleApply={variant === 'select' ? handleApply : undefined}
                      onDelete={variant === 'default' ? deleteAssistant : undefined}
                      onEditClick={
                        variant === 'default'
                          ? () => handleEditClick(assistant)
                          : undefined
                      }
                      onClick={() => handleOnClick(assistant)}
                      key={assistant.id}
                    />
                  ))}
                  <LoadMoreButton
                    remainingItems={
                      managedAssistants.total - managedAssistants.data.length
                    }
                    handleLoadMore={() =>
                      loadManagedAssistants(managedAssistants.offset + 10)
                    }
                    isLoading={isLoadingManagedAssistants}
                  ></LoadMoreButton>
                </>
              ) : (
                <p className="text-default-600">You have no assistants.</p>
              )}
            </div>
          </div>

          {/* Shared Assistants Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">Shared Assistants</h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingSharedAssistants && !sharedAssistants.offset ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : sharedAssistants.data.length > 0 ? (
                <>
                  {sharedAssistants.data.map((assistant) => (
                    <AssistantCard
                      value={assistant}
                      handleApply={variant === 'select' ? handleApply : undefined}
                      onClick={() => handleOnClick(assistant)}
                      key={assistant.id}
                    />
                  ))}
                  <LoadMoreButton
                    remainingItems={
                      sharedAssistants.total - sharedAssistants.data.length
                    }
                    handleLoadMore={() =>
                      loadSharedAssistants(sharedAssistants.offset + 10)
                    }
                    isLoading={isLoadingSharedAssistants}
                  ></LoadMoreButton>
                </>
              ) : (
                <p className="text-default-600">No shared assistants available.</p>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default AssistantsListView
