import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  CustomFile,
  CustomFileSystemEntry,
  processDirectory,
} from './ProcessDirectory.tsx'
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import { SettingsProps } from '../../interfaces/settingsProps.ts'
import DocumentAnalysisSelection, {
  DocumentAnalysisModelId,
} from '../knowledgeContainers/DocumentAnalysisSelection.tsx'
import useTeamStore from '@/states/teamStore.ts'
import FileNodeTree from './FileNodeTree.tsx'
import { iDocument, iFileDictionary } from '@/interfaces/iFile.ts'
import DropZone from '@components/basic/dropZone/DropZone.tsx'
import documentService from '@/services/documentService.ts'
import { ICON_DELETE } from '@/constants/icons.tsx'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

const FileSettings = ({ closeFunction, onSelect }: SettingsProps) => {
  const [loadingDocuments, setLoadingDocuments] = useState(false)
  const [hasMoreDocuments, setHasMoreDocuments] = useState(true)
  const [documentsPage, setDocumentsPage] = useState(0)
  const documentsPerPage = 20

  const lastDocumentRef = useRef<HTMLDivElement | null>(null)
  const [selectedFiles, setSelectedFiles] = useState<Set<CustomFile>>(new Set())
  const [documentAnalysis, setDocumentAnalysis] =
    useState<DocumentAnalysisModelId>('prebuilt-read')
  const [originalFiles, setOriginalFiles] = useState<CustomFile[]>([])
  const [fileData, setFileData] = useState<iFileDictionary>({})
  const [isUploading, setIsUploading] = useState(false)
  const [documents, setDocuments] = useState<any[]>([])
  const [selectedDocuments, setSelectedDocuments] = useState<iDocument[]>([])
  const [allSelected, setAllSelected] = useState(false)

  const [isDeleting, setIsDeleting] = useState<{ [key: string]: boolean }>({})
  const { selectedTeam } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )
  const [searchQuery, setSearchQuery] = useState('')

  const observer = useRef<IntersectionObserver | null>(null)

  // Function to load documents
  const getDocumentList = async () => {
    setLoadingDocuments(true)
    try {
      const { data: newDocuments } = await documentService.getDocuments(
        documentsPerPage,
        documentsPage * documentsPerPage,
        searchQuery,
        true,
      )
      setDocuments((prevDocuments) => [...prevDocuments, ...newDocuments])
      if (newDocuments.length < documentsPerPage) {
        setHasMoreDocuments(false)
      }
    } catch (error) {
      console.error('Error loading documents:', error)
    }
    setLoadingDocuments(false)
  }

  const updateDocumentList = useCallback(async () => {
    await getDocumentList()
  }, [documentsPage])

  const debouncedUpdateDocumentList = useDebouncedCallback(() => {
    setDocuments([]) // Clear current documents
    setDocumentsPage(0) // Reset the page to 0
    setHasMoreDocuments(true)
    getDocumentList()
  }, 800)

  useEffect(() => {
    updateDocumentList()
  }, [updateDocumentList])

  useEffect(() => {
    if (loadingDocuments) return
    if (!hasMoreDocuments) return
    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setDocumentsPage((prevPage) => prevPage + 1)
        }
      },
      { threshold: 1.0 },
    )

    if (lastDocumentRef.current) {
      observer.current.observe(lastDocumentRef.current)
    }
  }, [loadingDocuments, hasMoreDocuments])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    debouncedUpdateDocumentList()
  }

  const uploadSelectedFiles = async () => {
    // iterate the set of selected files
    setIsUploading(true)
    const files = []
    for (const file of selectedFiles) {
      files.push(
        new File([file], file.webkitRelativePath || file.name, {
          type: file.type,
          lastModified: file.lastModified,
        }),
      )
    }
    await documentService
      .uploadDocument(files, documentAnalysis, selectedTeam?.id || '')
      .then(() => {
        toast.success('Files uploaded successfully')
      })
      .catch((error) => {
        console.error('Error uploading files:', error)
        toast.error('Failed to upload files')
      })
      .finally(() => {
        setIsUploading(false)
      })
    await updateDocumentList()
    clearFiles()
  }

  const handleAddToChatAndClose = async () => {
    if (onSelect) onSelect(selectedDocuments)
    closeFunction()
  }

  const clearFiles = () => {
    setOriginalFiles([])
    setSelectedFiles(new Set())
    setFileData({})
  }

  const clearUnusedFiles = () => {
    const newOriginalFiles = originalFiles.filter((file) => selectedFiles.has(file))
    setOriginalFiles(newOriginalFiles)
  }

  const handleSelectionChange = (
    path: string,
    name: string,
    isSelected: boolean,
  ) => {
    const file = originalFiles.find(
      (file) => file.webkitRelativePath === path && file.name === name,
    )

    if (file) {
      setSelectedFiles((prevSelectedFiles) => {
        const newSelectedFiles = new Set(prevSelectedFiles)
        if (isSelected) {
          newSelectedFiles.add(file)
        } else {
          newSelectedFiles.delete(file)
        }
        return newSelectedFiles
      })
    }
  }

  /**
   * When files are dropped into the field event, process them
   * @param event
   */
  const onDrop = async (items: DataTransferItemList) => {
    const processedFiles: File[] = []

    for (const item of items) {
      const entry = item.webkitGetAsEntry()! as CustomFileSystemEntry
      if (entry.isDirectory) {
        const files = await processDirectory(entry)
        processedFiles.push(...files)
      } else if (entry.isFile) {
        const file = await new Promise<File>((resolve) => entry.file(resolve))
        // create a custom file object
        const customFile = new CustomFile(
          [file],
          file.name,
          { type: file.type },
          file.name,
        )
        processedFiles.push(customFile)
      }
    }

    const fileList = Object.setPrototypeOf(processedFiles, FileList.prototype)
    handleFilesSelect(fileList)
  }

  /**
   * Pute the selected files into the original files list
   * @param files
   */
  const handleFilesSelect = (files: FileList) => {
    const filesArray = Array.from(files)
    // merge existing files with new files
    const newFiles = filesArray.filter(
      (file) =>
        !originalFiles.find(
          (existingFile) =>
            existingFile.webkitRelativePath === file.webkitRelativePath &&
            existingFile.name === file.name,
        ),
    )
    setOriginalFiles(newFiles.concat(originalFiles))
  }

  const handleDocumentSelectChange = (document: iDocument) => {
    document._selected = !document._selected
    setSelectedDocuments((prevSelected) => {
      if (prevSelected.some((doc) => doc.id === document.id)) {
        return prevSelected.filter((doc) => doc.id !== document.id)
      } else {
        return [...prevSelected, document]
      }
    })
  }

  const handleDeleteDocument = async (documentId: string) => {
    try {
      setIsDeleting((prev) => ({ ...prev, [documentId]: true }))
      await documentService.deleteDocument(documentId)
      // Update documents list
      setDocuments((prevDocuments) =>
        prevDocuments.filter((doc) => doc.id !== documentId),
      )
      // Remove from selected documents if necessary
      setSelectedDocuments((prevSelected) =>
        prevSelected.filter((doc) => doc.id !== documentId),
      )
      toast.success('Document deleted successfully')
    } catch (error) {
      console.error('Error deleting document:', error)
      toast.error('Failed to delete document')
    } finally {
      setIsDeleting((prev) => ({ ...prev, [documentId]: false }))
    }
  }

  const getDocumentDisplayName = (document: iDocument): string => {
    if (!document) return ''
    if (document.path) return document.path
    return (
      document.metadata?.attributes?.displayName ||
      document.metadata?.attributes?.fileName ||
      '(unknown name)'
    )
  }

  const calculateTokenCount = () => {
    return selectedDocuments.reduce(
      (acc, doc) => acc + (doc.size?.tokenCount || 0),
      0,
    )
  }

  useEffect(() => {
    setAllSelected(
      documents.length > 0 &&
        documents.every((doc) => doc._selected) &&
        documents.length === selectedDocuments.length,
    )
  }, [documents, selectedDocuments])

  useEffect(() => {
    // set documents _selected property to true if they are in the selectedDocuments list
    setDocuments((prevDocuments) =>
      prevDocuments.map((doc) => ({
        ...doc,
        _selected: selectedDocuments.some(
          (selectedDoc) => selectedDoc.id === doc.id,
        ),
      })),
    )
  }, [selectedDocuments])

  const handleSelectAll = () => {
    if (allSelected) {
      setDocuments((prevDocuments) =>
        prevDocuments.map((doc) => ({ ...doc, _selected: false })),
      )
      setSelectedDocuments([])
    } else {
      setDocuments((prevDocuments) =>
        prevDocuments.map((doc) => ({ ...doc, _selected: true })),
      )
      setSelectedDocuments([...documents])
    }
  }

  return (
    <Modal
      scrollBehavior="inside"
      backdrop="blur"
      isOpen={true}
      onClose={closeFunction}
      classNames={{
        base: '!max-w-[750px] !w-full',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center w-full justify-between">
            <h1 className="text-xl font-semibold">Local Files</h1>
          </div>
        </ModalHeader>
        <ModalBody>
          {/* Upload Section */}
          <section>
            <h2 className="text-lg font-semibold mb-2">Upload</h2>
            <div className="flex items-center justify-between mb-2">
              <p>
                Upload files as documents. Please only use files that contain text.
              </p>
            </div>
            <DropZone
              onDrop={onDrop}
              handleFilesSelect={handleFilesSelect}
            ></DropZone>
            {(originalFiles.length > 0 && (
              <>
                <div className="mt-3">
                  <h2 className="text-lg font-semibold mb-2">Upload files</h2>
                  <FileNodeTree
                    originalFiles={originalFiles}
                    selectedFiles={Array.from(selectedFiles)}
                    onSelectionChange={handleSelectionChange}
                  />
                  <div className={'flex items-center justify-between mt-4'}>
                    <DocumentAnalysisSelection
                      onSelectionChange={(analysisType) => {
                        setDocumentAnalysis(analysisType)
                      }}
                      defaultValue={documentAnalysis}
                    ></DocumentAnalysisSelection>
                  </div>
                  <ModalFooter>
                    <Button size="sm" color="default" onPress={clearFiles}>
                      Clear Files
                    </Button>
                    <Button size="sm" color="default" onPress={clearUnusedFiles}>
                      Clear unused Files
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      onPress={uploadSelectedFiles}
                      isDisabled={selectedFiles.size === 0}
                      isLoading={isUploading}
                    >
                      Upload
                    </Button>
                  </ModalFooter>
                </div>
              </>
            )) || <div className="text-gray-500 center">No files selected</div>}
          </section>
          {/* Documents Section */}
          <section>
            <div className="mt-4">
              <h2 className="text-lg font-semibold mb-2">Selected Documents</h2>
              <div className="flex items-center justify-between mb-2">
                <p>
                  Add documents to chat to provide their content as context for the
                  model.
                </p>
              </div>
              <Input
                className="p-2 mb-1"
                // isClearable={true}
                placeholder="Search documents"
                value={searchQuery}
                onChange={handleSearchChange}
                width="100%"
              />
              <Table>
                <TableHeader>
                  <TableColumn>
                    <Checkbox
                      onClick={(e) => e.stopPropagation()}
                      isSelected={allSelected}
                      onValueChange={handleSelectAll}
                    />
                  </TableColumn>
                  <TableColumn>Document Name</TableColumn>
                  <TableColumn>Analysis Method</TableColumn>
                  <TableColumn>Tokens</TableColumn>
                  <TableColumn>Actions</TableColumn>
                </TableHeader>
                <TableBody
                  isLoading={loadingDocuments}
                  loadingContent={<Spinner label="Loading..." />}
                  items={documents}
                >
                  {(item: iDocument) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Checkbox
                          isSelected={item._selected}
                          onValueChange={() => handleDocumentSelectChange(item)}
                        />
                      </TableCell>
                      <TableCell>{getDocumentDisplayName(item)}</TableCell>
                      <TableCell>{item.parsingMethod || ''}</TableCell>
                      <TableCell>{item.size?.tokenCount || 0}</TableCell>
                      <TableCell>
                        <div className="flex">
                          <Button
                            isIconOnly
                            variant="light"
                            isLoading={isDeleting[item.id!]}
                            onPress={() => handleDeleteDocument(item.id!)}
                            title="Delete Document"
                          >
                            <ICON_DELETE className="w-5 h-5 hover:text-danger" />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {hasMoreDocuments && (
                <div ref={lastDocumentRef} style={{ height: 1 }}></div>
              )}
              <div className="flex justify-between my-2">
                <div className="text-sm text-gray-500">
                  {selectedDocuments.length} document(s) selected
                  <br />
                  Total tokens: {calculateTokenCount()}
                </div>
              </div>
            </div>
          </section>
        </ModalBody>
        <ModalFooter>
          <Button onPress={closeFunction}>Close</Button>
          <Button
            color="primary"
            onPress={handleAddToChatAndClose}
            isDisabled={selectedDocuments.length === 0}
          >
            Add to Chat
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FileSettings
