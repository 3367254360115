import React, { useEffect } from 'react'
import { Button } from '@nextui-org/react'

import { ArrowPathIcon } from '@heroicons/react/24/outline'
import SearchInput from '../basic/searchInput/SearchInput.tsx'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import SkeletonCard from '@components/skeletons/SkeletonCard.tsx'

import KnowledgeContainerCard from '@components/knowledgeContainers/KnowledgeContainerCard.tsx'
import useKnowledgeContainerStore from '@states/knowledgeContainerStore.ts'
import { AccessRight } from '@/interfaces/iShared.ts'
import { ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS } from '@/constants/routes.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import LoadMoreButton from '@components/basic/loadMoreButton/LoadMoreButton.tsx'
import { shallow } from 'zustand/shallow'

function KnowledgeContainersListView() {
  const navigate = useNavigate()

  const {
    loadKnowledgeContainers,
    managedKnowledgeContainers,
    isLoadingManagedKnowledgeContainers,
    setSearch,
    search,
    sharedKnowledgeContainers,
    isLoadingSharedKnowledgeContainers,
    deleteKnowledgeContainer,
  } = useKnowledgeContainerStore(
    (state) => ({
      sharedKnowledgeContainers: state.sharedKnowledgeContainers,
      isLoadingSharedKnowledgeContainers: state.isLoadingSharedKnowledgeContainers,
      managedKnowledgeContainers: state.managedKnowledgeContainers,
      isLoadingManagedKnowledgeContainers: state.isLoadingManagedKnowledgeContainers,
      search: state.search,
      setSearch: state.setSearch,
      loadKnowledgeContainers: state.loadKnowledgeContainers,
      deleteKnowledgeContainer: state.deleteKnowledgeContainer,
    }),
    shallow,
  )

  useEffect(() => {
    if (sharedKnowledgeContainers.total) return
    refresh(0)
  }, [])

  const loadManagedKnowledgeContainers = (offset: number) => {
    const managedOffset = offset ?? managedKnowledgeContainers.offset
    loadKnowledgeContainers({
      accessRight: AccessRight.Write,
      searchTerm: search.text,
      offset: managedOffset,
    }).catch((e) => console.error(e))
  }

  const loadSharedKnowledgeContainers = (offset: number) => {
    const sharedOffset = offset ?? sharedKnowledgeContainers.offset
    loadKnowledgeContainers({
      accessRight: AccessRight.ReadOnly,
      searchTerm: search.text,
      offset: sharedOffset,
    }).catch((e) => console.error(e))
  }

  const refresh = (offset: number) => {
    loadManagedKnowledgeContainers(offset)
    loadSharedKnowledgeContainers(offset)
  }

  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    () => {
      refresh(0)
    },
    // delay in ms
    800,
  )

  return (
    <div className="mx-auto overflow-visible rounded-xl max-w-[1500px] w-full">
      <div className="flex flex-col w-full">
        {/* Search and Filter */}
        <div className="flex justify-between items-center mb-6">
          <SearchInput
            searchTerm={search.text}
            setSearchTerm={(searchTerm) => {
              setSearch({ text: searchTerm })
              debounced()
            }}
            placeholder="Search for Knowledge-Containers, Owner, Description or Instruction"
          >
            <div className="flex items-center gap-2">
              <Button
                isIconOnly
                variant="light"
                onPress={() => refresh(0)}
                className="mr-2"
              >
                <ArrowPathIcon className="h-6 w-6" />
              </Button>
            </div>
          </SearchInput>
        </div>

        {/* Loading Indicator */}

        <>
          {/* Own KnowledgeContainers Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">
              Your manageable Knowledge-Containers
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingManagedKnowledgeContainers &&
              !managedKnowledgeContainers.offset ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : managedKnowledgeContainers.data.length > 0 ? (
                <>
                  {managedKnowledgeContainers.data.map((knowledgeContainer) => (
                    <KnowledgeContainerCard
                      value={knowledgeContainer}
                      // handleApply={handleApply}
                      onDelete={deleteKnowledgeContainer}
                      onEditClick={() => {
                        navigate(
                          `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/${knowledgeContainer.id}`,
                          {
                            state: { isEditMode: true },
                          },
                        )
                      }}
                      onClick={() => {
                        navigate(
                          `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/${knowledgeContainer.id}`,
                        )
                      }}
                      key={knowledgeContainer.id}
                    />
                  ))}
                  <LoadMoreButton
                    remainingItems={
                      managedKnowledgeContainers.total -
                      managedKnowledgeContainers.data.length
                    }
                    handleLoadMore={() =>
                      loadManagedKnowledgeContainers(
                        managedKnowledgeContainers.offset + 10,
                      )
                    }
                    isLoading={isLoadingManagedKnowledgeContainers}
                  ></LoadMoreButton>
                </>
              ) : (
                <p className="text-default-600">You have no Knowledge-Containers.</p>
              )}
            </div>
          </div>

          {/* Shared Assistants Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">
              Shared Knowledge-Containers
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingSharedKnowledgeContainers &&
              !sharedKnowledgeContainers.offset ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : sharedKnowledgeContainers.data.length > 0 ? (
                <>
                  {sharedKnowledgeContainers.data.map((knowledgeContainer) => (
                    <KnowledgeContainerCard
                      value={knowledgeContainer}
                      // handleApply={handleApply}
                      onDelete={deleteKnowledgeContainer}
                      onEditClick={() => {
                        navigate(
                          `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/${knowledgeContainer.id}`,
                          {
                            state: { isEditMode: true },
                          },
                        )
                      }}
                      onClick={() => {
                        navigate(
                          `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/${knowledgeContainer.id}`,
                        )
                      }}
                      key={knowledgeContainer.id}
                    />
                  ))}
                  <LoadMoreButton
                    remainingItems={
                      sharedKnowledgeContainers.total -
                      sharedKnowledgeContainers.data.length
                    }
                    handleLoadMore={() =>
                      loadSharedKnowledgeContainers(
                        sharedKnowledgeContainers.offset + 10,
                      )
                    }
                    isLoading={isLoadingSharedKnowledgeContainers}
                  ></LoadMoreButton>
                </>
              ) : (
                <p className="text-default-600">
                  No shared Knowledge-Containers available.
                </p>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default KnowledgeContainersListView
