import { OverridableParameterSet } from './iOverridableParameter.ts'
import { iItem } from './iItem.ts'
import { iRole } from './iShared.ts'
import { iHttpTemplate } from './iHttpParameter.ts'

export enum ExecutionEnvironment {
  Local = 0,
  ExternalHttp = 1,
}

export interface iTool extends iItem {
  markdownTemplate?: string
  responseExample?: string
  referenceTagging?: boolean
  refIdPath?: string
  name: string
  description?: string
  ownerEmail?: string
  ownerId?: string
  image?: string
  functionName?: string
  citationRequired?: boolean
  parameterOverrides?: string[] | null
  overridableParameters?: OverridableParameterSet
  executionEnvironment?: ExecutionEnvironment
  httpTemplate?: iHttpTemplate
  httpTemplateString?: string
  settings?: { [key: string]: any } | null
  roles?: iRole[]
  teamReferences?: string[] | null | undefined
  fromBaseAssistantInherited?: boolean
  requestCount?: number
}
