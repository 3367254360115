import React from 'react'
import { Input } from '@nextui-org/react'

type AssistantQuickPromptsProps = {
  message: string
  onChange: (message: string) => void
  isDisabled?: boolean
}

const AssistantWelcomeMessage = ({
  message,
  onChange,
  isDisabled,
}: AssistantQuickPromptsProps) => {
  return (
    <>
      <Input
        value={message}
        readOnly={isDisabled}
        onValueChange={(val) => onChange(val)}
        maxLength={100}
      ></Input>
    </>
  )
}
export default AssistantWelcomeMessage
