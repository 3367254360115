import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { ThemeProvider as NextThemesProvider } from 'next-themes'
import { BrowserRouter } from 'react-router-dom'
import { NextUIProvider } from '@nextui-org/react'
import './i18n.ts'
import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import './utils/monacoLoader.ts'

const rootElement = document.getElementById('root') as HTMLElement
const queryClient = new QueryClient()
ReactDOM.createRoot(rootElement).render(
  <NextUIProvider>
    <BrowserRouter>
      <NextThemesProvider attribute="class" defaultTheme="light">
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </NextThemesProvider>
    </BrowserRouter>
  </NextUIProvider>,
)
