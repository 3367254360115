import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import Markdown from '@components/markdown/Markdown.tsx'
import React from 'react'

type AssistantWelcomeMessageDisplayProps = {
  assistant: iAssistant
}
const AssistantWelcomeMessageDisplay = ({
  assistant,
}: AssistantWelcomeMessageDisplayProps) => {
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()

  return (
    <>
      <h2 className="text-xl font-bold text-center mb-7">
        {assistant.welcomeMessage ?? 'What can I help you with?'}
        <br />
      </h2>

      <Modal
        scrollBehavior={'inside'}
        backdrop={'blur'}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent>
          <ModalHeader>
            <h3 className="font-bold text-lg">Assistant welcome message</h3>
          </ModalHeader>
          <ModalBody>
            <Markdown value={assistant.welcomeMessage ?? ''} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AssistantWelcomeMessageDisplay
