import axiosClient from '../utils/axiosClient'
import { iFeature } from '../interfaces/iFeature.ts'

class FeatureService {
  private static instance: FeatureService

  private constructor() {}

  public static getInstance(): FeatureService {
    if (!FeatureService.instance) {
      FeatureService.instance = new FeatureService()
    }
    return FeatureService.instance
  }

  public async createFeature(feature: iFeature): Promise<iFeature> {
    const response = await axiosClient.post('/features', feature)
    return response.data as iFeature
  }

  public async getFeature(featureId: string): Promise<iFeature> {
    const response = await axiosClient.get(`/features/${featureId}`)
    return response.data as iFeature
  }

  public async getFeatures(options?: { teamId?: string }): Promise<iFeature[]> {
    const response = await axiosClient.get('/features', {
      params: {
        teamId: options?.teamId,
      },
    })
    return response.data as iFeature[]
  }

  public async updateFeature(feature: iFeature): Promise<void> {
    await axiosClient.put(`/features/${feature.id}`, feature)
  }

  public async deleteFeature(featureId: string): Promise<void> {
    await axiosClient.delete(`/features/${featureId}`)
  }

  public async upvoteFeature(featureId: string): Promise<iFeature> {
    const response = await axiosClient.post(`/features/${featureId}/upvote`)
    return response.data as iFeature
  }

  public async downvoteFeature(featureId: string): Promise<iFeature> {
    const response = await axiosClient.post(`/features/${featureId}/downvote`)
    return response.data as iFeature
  }
}

export default FeatureService.getInstance()
