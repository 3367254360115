import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { ChevronUpIcon } from '@heroicons/react/24/outline'
import DefaultPageLayout from '@/pages/DefaultPageLayout'
import { iFeature } from '@/interfaces/iFeature'
import FeatureService from '@/services/featureService.ts'
import useUserProfileStore from '@states/userProfileState.ts'
import { AuthRole } from '@/utils/authentication.ts'
import { ICON_DELETE } from '@/constants/icons.tsx'

export default function FeatureVoting() {
  const [features, setFeatures] = useState<iFeature[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const userProfile = useUserProfileStore((state) => state.userProfile)

  // State fürs Modal (Neues Feature)
  const [newFeature, setNewFeature] = useState({
    title: '',
    description: '',
  })

  // Beim Laden -> Features holen
  useEffect(() => {
    loadFeatures()
  }, [])

  async function loadFeatures() {
    try {
      const all = await FeatureService.getFeatures()
      setFeatures(all)
    } catch (err) {
      console.error('Error loading features:', err)
    }
  }

  // Feature anlegen
  const handleSubmitFeature = async () => {
    if (!newFeature.title.trim() || !newFeature.description.trim()) {
      return
    }

    const feature: iFeature = {
      id: '', // serverseitig generiert
      ownerId: userProfile!.id,
      ownerName: userProfile!.name,
      title: newFeature.title,
      description: newFeature.description,
      votes: 0,
      status: 'proposed',
    }

    try {
      const created = await FeatureService.createFeature(feature)
      setFeatures((prev) => [...prev, created])
    } catch (error) {
      console.error('Error creating feature:', error)
    } finally {
      setNewFeature({ title: '', description: '' })
      setIsOpen(false)
    }
  }

  // Nur 1x upvoten => serverseitig abgesichert, wir rufen die route /upvote
  const handleUpvote = async (featureId: string) => {
    try {
      const updated = await FeatureService.upvoteFeature(featureId)
      setFeatures((prev) => prev.map((f) => (f.id === updated.id ? updated : f)))
    } catch (error) {
      console.error('Error upvoting:', error)
    }
  }

  // Admin-Status-Wechsel
  const handleChangeStatus = async (
    featureId: string,
    newStatus: iFeature['status'],
  ) => {
    const feature = features.find((f) => f.id === featureId)
    if (!feature) return

    const updatedFeature = { ...feature, status: newStatus }
    try {
      await FeatureService.updateFeature(updatedFeature)
      setFeatures((prev) =>
        prev.map((f) => (f.id === featureId ? updatedFeature : f)),
      )
    } catch (error) {
      console.error('Error updating status:', error)
    }
  }

  // Löschen, nur wenn user = owner
  const handleDelete = async (featureId: string) => {
    try {
      await FeatureService.deleteFeature(featureId)
      setFeatures((prev) => prev.filter((f) => f.id !== featureId))
    } catch (error) {
      console.error('Error deleting feature:', error)
    }
  }

  // Status-Badge-Farbe
  function getBadgeStatusColor(status: iFeature['status']) {
    const variants = {
      proposed: 'default',
      planned: 'secondary',
      'in-progress': 'primary',
      completed: 'success',
    } as const
    return variants[status]
  }

  // Highest Votes first
  const sortedFeatures = [...features].sort((a, b) => b.votes - a.votes)

  return (
    <DefaultPageLayout title="Feature Voting">
      <div className="max-w-4xl mx-auto p-4 space-y-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Feature Requests</h1>
          <Button variant="solid" onPress={() => setIsOpen(true)}>
            Submit New Feature
          </Button>
        </div>

        {sortedFeatures.map((feature) => (
          <Card key={feature.id} className="mb-4">
            <CardBody
              className="pt-6"
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                overflowWrap: 'anywhere',
              }}
            >
              <div className="flex gap-4">
                {/* Upvote-Button */}
                <Button
                  variant="light"
                  size="sm"
                  className="flex flex-col items-center h-auto py-2"
                  onPress={() => handleUpvote(feature.id)}
                >
                  <ChevronUpIcon className="h-4 w-4" />
                  <span className="text-sm font-bold">{feature.votes}</span>
                </Button>

                <div className="flex-1">
                  <div className="flex items-center gap-2 mb-1">
                    <h3 className="text-lg font-semibold">{feature.title}</h3>
                    {feature.ownerName && (
                      <span className="text-xs text-gray-500 italic">
                        by {feature.ownerName}
                      </span>
                    )}

                    {/* Badge je nach Status */}
                    <Chip
                      variant="solid"
                      color={getBadgeStatusColor(feature.status)}
                    >
                      {feature.status}
                    </Chip>

                    {/* Falls Admin -> Dropdown zum Status ändern */}
                    {userProfile!.role === AuthRole.Admin && (
                      <Select
                        size="sm"
                        onChange={(e) =>
                          handleChangeStatus(
                            feature.id,
                            e.target.value as iFeature['status'],
                          )
                        }
                      >
                        <SelectItem key="proposed" value="proposed">
                          proposed
                        </SelectItem>
                        <SelectItem key="planned" value="planned">
                          planned
                        </SelectItem>
                        <SelectItem key="in-progress" value="in-progress">
                          in-progress
                        </SelectItem>
                        <SelectItem key="completed" value="completed">
                          completed
                        </SelectItem>
                      </Select>
                    )}
                    {feature.ownerId === userProfile!.id && (
                      <Button
                        color="danger"
                        size="sm"
                        isIconOnly
                        onPress={() => handleDelete(feature.id)}
                      >
                        <ICON_DELETE className={'w-4 h-4'} />
                      </Button>
                    )}
                  </div>

                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            </CardBody>
          </Card>
        ))}

        {/* MODAL - NEUES FEATURE */}
        <Modal isOpen={isOpen} onOpenChange={setIsOpen}>
          <ModalContent>
            <ModalHeader>
              <h1>Submit New Feature Request</h1>
            </ModalHeader>
            <ModalBody>
              <Input
                label="Title (max. 50 chars)"
                maxLength={50}
                placeholder="Feature Title"
                value={newFeature.title}
                onChange={(e) =>
                  setNewFeature({ ...newFeature, title: e.target.value })
                }
              />
              <Textarea
                label="Description (max. 500 chars)"
                placeholder="Describe your feature"
                maxLength={500}
                size={'lg'}
                value={newFeature.description}
                onChange={(e) =>
                  setNewFeature({ ...newFeature, description: e.target.value })
                }
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="light" onPress={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button onPress={handleSubmitFeature}>Submit</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </DefaultPageLayout>
  )
}
