import React, { useState } from 'react'
import {
  ArrowPathIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  SpeakerWaveIcon,
  StopIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
  Tooltip,
} from '@nextui-org/react'
import './chatMessage.css'
import { TextToSpeech } from '@/utils/TextToSpeech.ts'
import useSignalRStore from '@/states/signalRState.ts'
import useUserProfileStore from '@/states/userProfileState.ts'
import { hasFeatureFlag } from '@/utils/featureFlags.ts'
import { shallow } from 'zustand/shallow'
import { iSession } from '@/interfaces/iSession.ts'
import { iMessage } from '@/interfaces/iMessage.ts'
import ClipboardCopyButton from '../basic/copyToClipboard/CopyToClipboard.tsx'
import useChatStore from '@states/chatStore.ts'
import ChatMessageDetailView, {
  ChatMessageViewProps,
} from '@components/session/sessionChatControlViews/ChatMessageDetailView.tsx'

type ChatMessageHeaderProps = {
  session: iSession
  message: iMessage
  disableButtons?: {
    delete?: boolean
    edit?: boolean
    copy?: boolean
    speech?: boolean
    regenerate?: boolean
  }
  hideButtons?: {
    delete?: boolean
    edit?: boolean
    copy?: boolean
    speech?: boolean
    regenerate?: boolean
    details?: boolean
  }
  onEditClick: () => void
}

function ChatMessageHeader({
  session,
  message,
  disableButtons,
  hideButtons,
  onEditClick,
}: ChatMessageHeaderProps) {
  const {
    deleteMessage,
    setIsAssistantGenerating,
    messages,
    isAssistantGenerating,
    setIsAssistantRunning,
    setMessages,
    setChatControlContentRoute,
    setIsChatControlContentOpen,
  } = useChatStore(
    (state) => ({
      deleteMessage: state.deleteMessage,
      messages: state.messages,
      setIsAssistantRunning: state.setIsAssistantRunning,
      setIsAssistantGenerating: state.setIsAssistantGenerating,
      isAssistantGenerating: state.isAssistantGenerating,
      setMessages: state.setMessages,
      setChatControlContentRoute: state.setChatControlContentRoute,
      setIsChatControlContentOpen: state.setIsChatControlContentOpen,
    }),
    shallow,
  )
  const [deletingMessage, setDeletingMessage] = useState<boolean>(false)
  const [isTalking, setIsTalking] = useState<boolean>(false)
  const userProfile = useUserProfileStore((state) => state.userProfile, shallow)
  const startAssistant = useSignalRStore((state) => state.startAssistant, shallow)
  const getJoinedMessageContent = () => {
    return (
      message.content ??
      message.contentItems?.map((item) => item.content).join('\n') ??
      ''
    )
  }

  const handleEdit = () => {
    onEditClick()
  }

  const handleDelete = async () => {
    setDeletingMessage(true)
    try {
      return await deleteMessage(message)
    } finally {
      setDeletingMessage(false)
    }
  }

  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]))

  const synth = new TextToSpeech()

  const handleTextToSpeech = () => {
    if (!message.content) return
    if (!isTalking) {
      const valuesArray = [...selectedKeys.values()]
      const voiceUri = valuesArray[0]
      synth.setDefaultVoice(
        synth.getVoiceCollection().find((v) => v.voiceURI === voiceUri)!,
      )
      synth.talk(message.content, () => {
        setIsTalking(false)
      })
      setIsTalking(true)
    } else {
      synth.cancel()
      setIsTalking(false)
    }
  }

  const handleRegenerate = () => {
    const dummyMessage: iMessage = { role: 'assistant', sessionId: session.id! }
    handleDelete().then(() => {
      setIsAssistantGenerating(true)
      setIsAssistantRunning(true)
      messages.unshift(dummyMessage)
      setMessages(messages)
      startAssistant(session.id!, userProfile!.id!)
    })
  }

  const deleteButton = (
    <Tooltip content="Delete" className={'max-w-96'} delay={500}>
      <Button
        isIconOnly
        isDisabled={deletingMessage || disableButtons?.delete}
        variant={'light'}
        className={`bg-ghost hover:text-error ${message.role === 'user' ? 'mr-0' : 'mr-0'}`}
        onPress={handleDelete}
      >
        <XMarkIcon className="h-5 w-5" />
      </Button>
    </Tooltip>
  )
  const copyButton = (
    <ClipboardCopyButton
      isDisabled={deletingMessage || disableButtons?.copy}
      textToCopy={getJoinedMessageContent()}
      tooltip={'Copy'}
    />
  )
  const editButton = (
    <Tooltip content="Edit" className={'max-w-96'} delay={500}>
      <Button
        isIconOnly
        isDisabled={deletingMessage || disableButtons?.edit}
        variant={'light'}
        className="bg-ghost mr-0"
        onPress={handleEdit}
      >
        <PencilSquareIcon className="h-5 w-5" />
      </Button>
    </Tooltip>
  )

  const textToSpeech = hasFeatureFlag() && (
    <Badge
      variant={'flat'}
      placement={'bottom-right'}
      size={'sm'}
      showOutline={false}
      content={
        <Dropdown isDisabled={isAssistantGenerating}>
          <DropdownTrigger className={'p-0 min-w-0'}>
            <ChevronDownIcon className="cursor-pointer h-5 w-3 min-w-0"></ChevronDownIcon>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Single selection example"
            variant="flat"
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={selectedKeys}
            onSelectionChange={setSelectedKeys as any}
          >
            {synth.getVoiceCollection().map((voice) => {
              return (
                <DropdownItem key={voice.voiceURI}>{voice.voiceURI}</DropdownItem>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      }
    >
      <Tooltip content="Read Aloud" className={'max-w-96'} delay={500}>
        <Button
          isDisabled={deletingMessage || disableButtons?.speech}
          variant={'light'}
          className="bg-ghost min-w-10 px-0"
          onPress={handleTextToSpeech}
        >
          {!isTalking ? (
            <SpeakerWaveIcon className="h-5 w-5" />
          ) : (
            <StopIcon className="h-5 w-5" />
          )}
        </Button>
      </Tooltip>
    </Badge>
  )

  const regenerateAssistantMessage = (
    <Tooltip content="Regenerate" className={'max-w-96'} delay={500}>
      <Button
        isIconOnly
        isDisabled={deletingMessage || disableButtons?.regenerate}
        variant={'light'}
        className="bg-ghost mr-4"
        onPress={handleRegenerate}
      >
        <ArrowPathIcon className="h-5 w-5" />
      </Button>
    </Tooltip>
  )
  const messageDetailsButton = (
    <div className="flex flex-1  gap-2 justify-end items-center text-sm">
      <span>{message.modified && new Date(message.modified!).toLocaleString()}</span>
      <Link
        isBlock
        showAnchorIcon
        href="#"
        color="primary"
        anchorIcon={<ArrowRightIcon className="h-4 w-4" />}
        onPress={() => {
          setIsChatControlContentOpen(true)
          setChatControlContentRoute<ChatMessageViewProps>(
            'artifacts',
            'MessageDetail',
            { message: message },
          )
        }}
      >
        Details
      </Link>
    </div>
  )
  return (
    <div className="chat-header min-w-[90%]">
      <div
        className={`transition group-hover:-translate-y-[0px] translate-y-[100%] ${message.role === 'user' ? 'flex justify-end' : ''}`}
      >
        <ul className={'flex'}>
          {message.role === 'assistant' ? (
            <>
              {deleteButton}
              {copyButton}
              {editButton}
              {hasFeatureFlag() && textToSpeech}
              {!hideButtons?.regenerate && regenerateAssistantMessage}
              {!hideButtons?.details && messageDetailsButton}
            </>
          ) : (
            <>
              {editButton}
              {copyButton}
              {deleteButton}
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

export default ChatMessageHeader
