import React, { useRef } from 'react'
import StackNavigator, {
  StackNavigatorHandle,
} from '@components/basic/stackNavigator/StackNavigator.tsx'
import AssistantsListView from '@components/assistants/AssistantsListView.tsx'
import AssistantDetailView from '@components/assistants/AssistantDetailView.tsx'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
} from '@nextui-org/react'
import { ICON_RESET } from '@/constants/icons.tsx'
import useChatStore from '@states/chatStore.ts'
type AssistantSelectDrawerProps = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onOpenChange: () => void
}
const AssistantSelectDrawer = ({
  isOpen,
  onOpen,
  onClose,
  onOpenChange,
}: AssistantSelectDrawerProps) => {
  const setSessionToDefault = useChatStore().setSessionToDefault

  const stackNavigatorRef = useRef<StackNavigatorHandle>(null)
  const routes = {
    Overview: {
      component: AssistantsListView,
      props: {
        variant: 'select',
        onApply: () => {
          onClose()
        },
      },
    },
    Detail: {
      component: AssistantDetailView,
    },
  }
  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      classNames={{
        base: 'min-h-[70%]',
      }}
      onOpenChange={onOpenChange}
    >
      <DrawerContent>
        {(onClose) => (
          <div className="h-lvh flex flex-col max-w-6xl self-center w-full">
            <DrawerHeader className="flex flex-row gap-1">
              <span className="flex-1 flex text-center">Assistant Marketplace</span>

              <Button className="flex-grow-0" onPress={setSessionToDefault}>
                <ICON_RESET></ICON_RESET>
                Start with empty Assistant
              </Button>
            </DrawerHeader>
            <DrawerBody className="h-auto">
              <StackNavigator
                ref={stackNavigatorRef}
                initialRoute={'Overview'}
                routes={routes}
              />
            </DrawerBody>
          </div>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default AssistantSelectDrawer
