import { iSession } from '../interfaces/iSession.ts'
import axiosClient from '../utils/axiosClient.ts'
import { iSourceFileCitation } from '../interfaces/iSourceFileCitations.ts'
import { iMessage } from '../interfaces/iMessage.ts'

class SessionService {
  private static instance: SessionService

  private constructor() {}

  public static getInstance(): SessionService {
    if (!SessionService.instance) {
      SessionService.instance = new SessionService()
    }

    return SessionService.instance
  }

  public async createSession(session: iSession): Promise<iSession> {
    const ret = (await axiosClient.post(`/sessions`, session)).data
    ret.modified = new Date(ret!.modified)
    ret.created = new Date(ret!.created)
    return ret as iSession
  }

  public async deleteSession(sessionId: string): Promise<void> {
    await axiosClient.delete(`/sessions/${sessionId}`)
  }

  public async getSession(sessionId: string): Promise<iSession> {
    const ret = (await axiosClient.get(`/sessions/${sessionId}`)).data
    ret.modified = new Date(ret!.modified)
    ret.created = new Date(ret!.created)
    return ret as iSession
  }

  public async setSessionToDefault(sessionId: string): Promise<iSession> {
    const ret = (await axiosClient.get(`/sessions/${sessionId}/default`)).data
    ret.modified = new Date(ret!.modified)
    ret.created = new Date(ret!.created)
    return ret as iSession
  }

  public async getSessions(options?: {
    folderId?: string
    searchValue?: string
    teamId?: string
    limit?: number
    offset?: number
  }): Promise<{ data: iSession[]; total: number }> {
    const ret = (
      await axiosClient.get(`/sessions`, {
        params: {
          folderId: options?.folderId,
          searchValue: options?.searchValue,
          teamId: options?.teamId,
          limit: options?.limit,
          offset: options?.offset,
        },
      })
    ).data
    ret.data.map((session: { created: Date; modified: Date }) => {
      session.modified = new Date(session!.modified)
      session.created = new Date(session!.created)
    })
    return ret as { data: iSession[]; total: number }
  }

  public async updateSession(session: iSession): Promise<void> {
    await axiosClient.put(`/sessions/${session.id}`, session)
  }

  public async getSourceFiles(
    sessionId: string,
    messageId: string,
  ): Promise<iSourceFileCitation> {
    const data = (
      await axiosClient.get(
        `/sessions/${sessionId}/messages/${messageId}/sourcefiles`,
      )
    ).data
    return data as iSourceFileCitation
  }

  public async deleteMessage(sessionId: string, messageId: string): Promise<void> {
    await axiosClient.delete(`/sessions/${sessionId}/messages/${messageId}`)
  }

  public async deleteMessages(sessionId: string): Promise<void> {
    await axiosClient.delete(`/sessions/${sessionId}/messages`)
  }

  public async getMessages(
    sessionId: string,
    options?: { searchValue?: string; limit?: number; offset?: number },
  ): Promise<{ data: iMessage[]; total: number }> {
    const params = {
      searchValue: options?.searchValue,
      limit: options?.limit,
      offset: options?.offset,
    }

    // Use 'all' to fetch messages from all sessions
    const url = `/sessions/${sessionId}/messages`

    const response = await axiosClient.get(url, { params })
    return response.data as { data: iMessage[]; total: number }
  }

  public async getMessage(sessionId: string, messageId: string): Promise<iMessage> {
    return (await axiosClient.get(`/sessions/${sessionId}/messages/${messageId}`))
      .data as iMessage
  }

  public async createMessage(
    sessionId: string,
    message: iMessage,
  ): Promise<iMessage> {
    return (await axiosClient.post(`/sessions/${sessionId}/messages`, message))
      .data as iMessage
  }

  public async updateMessage(sessionId: string, message: iMessage): Promise<void> {
    await axiosClient.put(`/sessions/${sessionId}/messages/${message.id}`, message)
  }
}

export default SessionService.getInstance()
