import { createWithEqualityFn } from 'zustand/traditional'
import { AccessRight, Role } from '../interfaces/iShared.ts'
import AssistantService from '../services/assistantService.ts'
import { iAssistant } from '../interfaces/iAssistantTypes.ts'
import { getUserKey } from '../utils/authentication.ts'
import { iDefaultStore } from '@/interfaces/stores/iDefaultStore.ts'

interface iAssistantState {
  storeIsInitialized: boolean
  isLoading: boolean
  assistants: iAssistant[]
  search: { text: string }
  isLoadingSharedAssistants: boolean
  isLoadingManagedAssistants: boolean
  sharedAssistants: { data: iAssistant[]; total: number; offset: number }
  managedAssistants: { data: iAssistant[]; total: number; offset: number }
}

interface iAssistantStore extends iAssistantState, Omit<iDefaultStore, 'init'> {
  getAssistantRole: (assistant: iAssistant) => Role | undefined
  getAssistantById: (assistantId: string) => iAssistant | undefined
  hasRightTo: (minRole: Role, assistant: iAssistant) => boolean
  deleteAssistant: (id: string) => Promise<void>
  createAssistant: (assistant: iAssistant) => Promise<iAssistant>
  updateAssistant: (assistant: iAssistant) => Promise<iAssistant>
  setIsLoading: (isLoading: boolean) => void
  setAssistants: (assistants: iAssistant[]) => void
  loadAssistants: (options?: {
    accessRight: AccessRight
    searchTerm: string
    offset?: number
  }) => Promise<iAssistant[]>
  setSearch: (search: { text: string }) => void
}

const initialState: iAssistantState = {
  storeIsInitialized: false,
  isLoading: true,
  search: { text: '' },
  assistants: [],
  sharedAssistants: {
    data: [],
    total: 0,
    offset: 0,
  },
  managedAssistants: {
    data: [],
    total: 0,
    offset: 0,
  },
  isLoadingSharedAssistants: true,
  isLoadingManagedAssistants: true,
}
const useAssistantStore = createWithEqualityFn<iAssistantStore>((set, getState) => {
  return {
    ...initialState,
    reset: () => {
      set(initialState)
    },
    init: async () => {
      getState().reset()
      set({ storeIsInitialized: true })
    },
    setAssistants: (assistants: iAssistant[]) => {
      set(() => ({ assistants }))
    },
    setSearch: (search: { text: string }) => {
      set(() => ({ search }))
    },
    deleteAssistant: async (id) => {
      await AssistantService.deleteAssistant(id)
      const sharedAssistants = getState().sharedAssistants.data.filter(
        (assistant) => assistant.id !== id,
      )
      const managedAssistants = getState().managedAssistants.data.filter(
        (assistant) => assistant.id !== id,
      )
      set((store) => ({
        sharedAssistants: {
          ...store.sharedAssistants,
          data: sharedAssistants,
          total: store.sharedAssistants.total - 1,
        },
      }))
      set((store) => ({
        managedAssistants: {
          ...store.managedAssistants,
          data: managedAssistants,
          total: store.managedAssistants.total - 1,
        },
      }))
    },
    createAssistant: async (assistant: iAssistant) => {
      const newAssistant = await AssistantService.createAssistant(assistant)
      const tmpAssistants = [...getState().managedAssistants.data, newAssistant]
      set((store) => ({
        managedAssistants: {
          ...store.managedAssistants,
          data: tmpAssistants,
          total: store.managedAssistants.total + 1,
        },
      }))
      return newAssistant
    },
    updateAssistant: async (assistant: iAssistant) => {
      await AssistantService.updateAssistant(assistant)
      const updatedManagedAssistants = getState().managedAssistants.data.map((a) => {
        if (a.id === assistant.id) {
          return { ...a, ...assistant }
        }
        return a
      })
      set({
        managedAssistants: {
          ...getState().managedAssistants,
          data: updatedManagedAssistants,
        },
      })

      return assistant
    },
    setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
    getAssistantRole: (assistant) => {
      const shared = assistant?.shared?.find((s) => s.id === getUserKey())

      return shared?.role
    },
    getAssistantById: (assistantId) => {
      return getState().assistants.find((a: iAssistant) => a.id === assistantId)
    },

    hasRightTo: (minRole: Role, assistant) => {
      const isOwner = assistant?.ownerId === getUserKey()
      if (isOwner) return true
      const role = getState().getAssistantRole(assistant)
      if (role === undefined) return false
      return role <= minRole
    },
    loadAssistants: async (options?: {
      accessRight?: AccessRight
      searchTerm: string
      offset?: number
    }) => {
      const offset = options?.offset ?? 0
      if (options?.accessRight === AccessRight.Write) {
        set({
          managedAssistants: {
            ...getState().managedAssistants,
            offset,
          },
        })
        set({ isLoadingManagedAssistants: true })
      }
      if (options?.accessRight === AccessRight.ReadOnly) {
        set({
          sharedAssistants: {
            ...getState().sharedAssistants,
            offset,
          },
        })
        set({ isLoadingSharedAssistants: true })
      }

      const assistants = await AssistantService.getAssistants({
        accessRight: options?.accessRight,
        searchValue: options?.searchTerm,
        offset,
      })
      if (options?.accessRight === AccessRight.Write) {
        //TODO: fix any
        const newAssistants: any =
          offset === 0
            ? assistants
            : {
                data: [...getState().managedAssistants.data, ...assistants.data],
                total: assistants.total,
              }
        newAssistants.offset = offset
        set({ managedAssistants: newAssistants })
        set({ isLoadingManagedAssistants: false })
      }
      if (options?.accessRight === AccessRight.ReadOnly) {
        //TODO: fix any
        const newAssistants: any =
          offset === 0
            ? assistants
            : {
                data: [...getState().sharedAssistants.data, ...assistants.data],
                total: assistants.total,
              }
        newAssistants.offset = offset
        set({ sharedAssistants: newAssistants })
        set({ isLoadingSharedAssistants: false })
      }
      return assistants.data
    },
  }
})

export default useAssistantStore
