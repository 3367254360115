import React, { useEffect } from 'react'
import { Button } from '@nextui-org/react'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import SearchInput from '../basic/searchInput/SearchInput.tsx'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import SkeletonCard from '@components/skeletons/SkeletonCard.tsx'
import ToolCard from '@components/tools/ToolCard.tsx'
import useToolStore from '@states/toolStore.ts'
import { AccessRight } from '@/interfaces/iShared.ts'
import {
  ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS,
  ROUTE_MARKETPLACE_TOOLS,
} from '@/constants/routes.ts'
import { iTool } from '@/interfaces/iTool.ts'
import LoadMoreButton from '@components/basic/loadMoreButton/LoadMoreButton.tsx'
import { shallow } from 'zustand/shallow'

function ToolsListView() {
  const {
    loadTools,
    managedTools,
    isLoadingManagedTools,
    setSearch,
    search,
    sharedTools,
    isLoadingSharedTools,
    deleteTool,
  } = useToolStore(
    (state) => ({
      sharedTools: state.sharedTools,
      isLoadingSharedTools: state.isLoadingSharedTools,
      managedTools: state.managedTools,
      isLoadingManagedTools: state.isLoadingManagedTools,
      deleteTool: state.deleteTool,
      search: state.search,
      setSearch: state.setSearch,
      loadTools: state.loadTools,
    }),
    shallow,
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (sharedTools.total) return
    refresh(0)
  }, [])

  const loadManagedTools = (offset: number) => {
    const managedOffset = offset ?? managedTools.offset

    loadTools({
      accessRight: AccessRight.Write,
      searchTerm: search.text,
      offset: managedOffset,
    }).catch((e) => console.error(e))
  }

  const loadSharedTools = (offset: number) => {
    const sharedOffset = offset ?? sharedTools.offset
    loadTools({
      accessRight: AccessRight.ReadOnly,
      searchTerm: search.text,
      offset: sharedOffset,
    }).catch((e) => console.error(e))
  }

  const refresh = (offset: number) => {
    loadManagedTools(offset)
    loadSharedTools(offset)
  }

  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    () => {
      refresh(0)
    },
    // delay in ms
    800,
  )

  return (
    <div className="mx-auto overflow-visible rounded-xl max-w-[1500px] w-full">
      <div className="flex flex-col w-full">
        {/* Search and Filter */}
        <div className="flex justify-between items-center mb-6">
          <SearchInput
            searchTerm={search.text}
            setSearchTerm={(searchTerm) => {
              setSearch({ text: searchTerm })
              debounced()
            }}
            placeholder="Search for Tool, Owner, Description or Instruction"
          >
            <div className="flex items-center gap-2">
              <Button
                isIconOnly
                variant="light"
                onPress={() => refresh(0)}
                className="mr-2"
              >
                <ArrowPathIcon className="h-6 w-6" />
              </Button>
            </div>
          </SearchInput>
        </div>

        {/* Loading Indicator */}

        <>
          {/* Own Tools Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">Your manageable Tools</h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingManagedTools && !managedTools.offset ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : managedTools.total > 0 ? (
                <>
                  {managedTools.data.map((tool) => (
                    <ToolCard
                      value={tool}
                      // handleApply={handleApply}
                      onDelete={deleteTool}
                      onEditClick={() => {
                        navigate(`${ROUTE_MARKETPLACE_TOOLS}/${tool.id}`, {
                          state: { isEditMode: true },
                        })
                      }}
                      onClick={() => {
                        navigate(`${ROUTE_MARKETPLACE_TOOLS}/${tool.id}`)
                      }}
                      key={tool.id}
                    />
                  ))}
                  <LoadMoreButton
                    remainingItems={managedTools.total - managedTools.data.length}
                    handleLoadMore={() => loadManagedTools(managedTools.offset + 10)}
                    isLoading={isLoadingManagedTools}
                  ></LoadMoreButton>
                </>
              ) : (
                <p className="text-default-600">You have no Tools.</p>
              )}
            </div>
          </div>

          {/* Shared Assistants Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">Shared Tools</h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingSharedTools && !sharedTools.offset ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : sharedTools.total > 0 ? (
                <>
                  {sharedTools.data.map((tool) => (
                    <ToolCard
                      // handleApply={handleApply}
                      onDelete={deleteTool}
                      onEditClick={() => {
                        navigate(`${ROUTE_MARKETPLACE_TOOLS}/${tool.id}`, {
                          state: { isEditMode: true },
                        })
                      }}
                      value={tool}
                      onClick={() => {
                        navigate(`${ROUTE_MARKETPLACE_TOOLS}/${tool.id}`)
                      }}
                      key={tool.id}
                    />
                  ))}
                  <LoadMoreButton
                    remainingItems={sharedTools.total - sharedTools.data.length}
                    handleLoadMore={() => loadSharedTools(sharedTools.offset + 10)}
                    isLoading={isLoadingSharedTools}
                  ></LoadMoreButton>
                </>
              ) : (
                <p className="text-default-600">No shared tools available.</p>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default ToolsListView
