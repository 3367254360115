import { Editor } from '@monaco-editor/react'
import { editor as mcEditor } from 'monaco-editor'
import { useTheme } from 'next-themes'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Tab,
  Tabs,
} from '@nextui-org/react'
import { ICON_EXPAND } from '@/constants/icons.tsx'
import { useEffect, useState } from 'react'
import './codeEditor.scss'
import Markdown from '@components/markdown/Markdown.tsx'

type CodeEditorProps = {
  value: string | undefined
  language?: string
  onChange?: (content: string | undefined) => void
  onBlur?: (content: string | undefined) => void
  isDisabled?: boolean
  previewMode?: boolean
  modalLabel?: string
}

function CodeEditor({
  value,
  language = 'markdown',
  onChange,
  isDisabled,
  onBlur,
  previewMode,
  modalLabel,
}: CodeEditorProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [editorContent, setEditorContent] = useState(value)
  const { theme } = useTheme()
  useEffect(() => {
    setEditorContent(value)
  }, [value])

  const supportedLanguages = ['markdown']
  const renderEditor = (
    options: mcEditor.IStandaloneEditorConstructionOptions | undefined = {},
  ) => (
    <>
      <Editor
        wrapperProps={{
          className:
            'rounded-medium overflow-hidden flex-1 pt-4 pr-4 bg-background ', // Added classes
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur && onBlur(editorContent)
          },
        }}
        theme={`vs-${theme}`}
        className="rounded-medium"
        height={'100%'}
        defaultLanguage={language}
        options={{
          scrollbar: {
            horizontal: 'visible',
          },
          readOnly: isDisabled,
          lineNumbers: isDisabled ? 'off' : 'on',
          minimap: { enabled: false },
          ...options,
        }}
        value={editorContent}
        onChange={(val) => {
          setEditorContent(val)
          onChange && onChange(val)
        }}
      />
    </>
  )
  return (
    <>
      {previewMode ? (
        <Markdown className="h-full w-full" value={editorContent ?? ''}></Markdown>
      ) : (
        <div className="relative h-full">
          <Button
            size="sm"
            isIconOnly
            onPress={() => setIsExpanded(!isExpanded)}
            className="absolute -top-4 -right-2 z-50"
          >
            <ICON_EXPAND />
          </Button>
          {renderEditor({
            lineNumbers: 'off',
            glyphMargin: false,
            folding: false,
            // Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
            lineDecorationsWidth: 10,
            lineNumbersMinChars: 0,
          })}
        </div>
      )}

      <Modal
        closeButton
        isOpen={isExpanded}
        onClose={() => setIsExpanded(false)}
        scrollBehavior="inside"
      >
        <ModalContent className="min-h-[60%] min-w-[60%]">
          <ModalHeader>{modalLabel}</ModalHeader>
          <ModalBody className="h-full w-full flex-1 pb-6">
            <Tabs className="justify-start">
              <Tab
                key="code"
                title="Code"
                className="flex flex-1 flex-col relative h-full w-full"
              >
                {renderEditor({})}
              </Tab>
              <Tab
                key="preview"
                title="Preview"
                isDisabled={!supportedLanguages?.includes(language)}
              >
                <div className="h-full w-full">
                  {language === 'markdown' && (
                    <Markdown
                      className="h-full w-full"
                      value={editorContent ?? ''}
                    ></Markdown>
                  )}
                </div>
              </Tab>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CodeEditor
