import { createWithEqualityFn } from 'zustand/traditional'
import TeamService from '../services/teamService.ts'
import { Role } from '../interfaces/iShared.ts'
import UserProfileState from './userProfileState.ts'
import { iTeam } from '../interfaces/iTeam.ts'
import { getUserKey } from '../utils/authentication.ts'
import { iTeamState, iTeamStore } from '@/interfaces/stores/iTeamStore.ts'
import SessionState from '@states/sessionStore.ts'
import ChatStore from '@states/chatStore.ts'
import FolderState from '@states/folderStore.ts'
const initialState: iTeamState = {
  isLoading: true,
  teams: [],
  editableTeams: [],
  selectedTeam: null,
  role: null,
  isOwner: false,
}

const useTeamStore = createWithEqualityFn<iTeamStore>()((set, getState) => ({
  ...initialState,
  hasRightTo: (minRole: Role, team?: iTeam) => {
    let isOwner = getState().isOwner
    let role = getState().role!
    if (team) {
      const userId = UserProfileState.getState().userProfile?.id
      role = team.shared.find((s) => s.id === userId)?.role ?? Role.LimitedMember
      isOwner = team.ownerId === getUserKey()
    }
    if (isOwner) return true
    return role >= minRole
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setRole: (role: Role) => set(() => ({ role })),
  setTeams: (teams: iTeam[]) => {
    set(() => ({ teams }))
    getState().setEditableTeams(teams)
  },
  setEditableTeams: (teams) => {
    const editableTeams = teams.filter((team) =>
      getState().hasRightTo(Role.Manager, team),
    )
    set(() => ({ editableTeams }))
  },
  setSelectedTeamByString: async (teamId: string) => {
    const team = getState().teams.find((team) => team.id === teamId)
    if (team) await getState().setSelectedTeam(team)
  },
  setSelectedTeam: async (selectedTeam: iTeam) => {
    getState().setIsLoading(true)
    const userId = UserProfileState.getState().userProfile?.id
    const role = selectedTeam.shared.find((s) => s.id === userId)?.role
    const isOwner = selectedTeam.ownerId === getUserKey()
    getState().setRole(role!)
    getState().setIsLoading(false)

    const url = new URL(location.href)
    url.searchParams.set('teamId', selectedTeam.id!)
    history.replaceState(null, '', url)
    SessionState.getState().init({ teamId: selectedTeam.id! })
    ChatStore.getState().reset()
    FolderState.getState().init({
      teamId: selectedTeam.id!,
    })

    set(() => ({ isOwner }))
    set(() => ({ selectedTeam }))
  },
  getTeams: async () => {
    getState().setIsLoading(true)
    return TeamService.getTeams(false)
      .then((teams) => {
        getState().setTeams(teams.data)
        getState().setIsLoading(false)
        return teams
      })
      .catch((e: unknown) => {
        getState().setIsLoading(false)
        console.error(e)
        return { data: [], total: 0 }
      })
  },
  deleteTeam: async (teamId) => {
    await TeamService.deleteTeam(teamId)
    const newTeams = getState().teams.filter((team) => {
      return team.id !== teamId
    })
    getState().setTeams(newTeams)
    if (getState().selectedTeam?.id === teamId) {
      getState().setSelectedTeam(getState().teams[0] ?? undefined)
    }
  },
  createTeam: async (team: iTeam) => {
    const newTeam = await TeamService.createTeam(team)
    getState().setTeams([...getState().teams, newTeam])
    return newTeam
  },
}))

export default useTeamStore
