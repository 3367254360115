import katex from 'katex'
import { getCodeString } from 'rehype-rewrite'
import 'katex/dist/katex.min.css'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { iMessage } from '@/interfaces/iMessage.ts'
import CopyToClipboard from '../basic/copyToClipboard/CopyToClipboard.tsx'
import Citation from './Citation.tsx'

export const liMarkdown = (
  props: any,
  message?: iMessage,
  onClickCitation?: (sourceId: number) => void,
) => {
  if (props.id?.startsWith('user-content-fn')) {
    const cidNumber = props.id.replace('user-content-fn-', '')
    const pElement = props.node!.children.find(
      (child: any) => child.tagName === 'p',
    ) as any
    if (!pElement) {
      return <li id={props.id}>{props.children}</li>
    }
    if (pElement.children.length === 0) {
      return <li id={props.id}>{props.children}</li>
    }
    const text = pElement.children.find((child: any) => child.type === 'text')!
      .value as any
    const backHRefs = pElement.children
      .filter((child: any) => child.tagName === 'a')
      .map((a: any) => {
        if (!a.properties.href) return
        let fnrefNumber = a.properties.href.replace('#user-content-fnref-', '')
        fnrefNumber = fnrefNumber.replace('cid-', '')
        return `#user-content-fnref-cid-${fnrefNumber}-${message?.id}`
      })
    return (
      <li id={`user-content-fn-cid-${cidNumber}-${message?.id}`}>
        <Citation
          key={cidNumber}
          cid={cidNumber}
          text={text as string}
          backHRefs={backHRefs}
          onClick={() => onClickCitation && onClickCitation(cidNumber)}
        />
      </li>
    )
  } else {
    const { children, className, node, ...rest } = props
    return (
      <li {...rest} className={className}>
        {children}
      </li>
    )
  }
}

export const codeMarkdown = (props: any) => {
  const { children, className, ...rest } = props
  // syntax for inline katex and latex code
  if (typeof children === 'string' && /^\$\$(.*)\$\$/.test(children)) {
    const html = katex.renderToString(children.replace(/^\$\$(.*)\$\$/, '$1'), {
      throwOnError: false,
    })
    return (
      <code
        dangerouslySetInnerHTML={{ __html: html }}
        style={{ background: 'transparent' }}
      />
    )
  }
  const code =
    props.node && props.node.children ? getCodeString(props.node.children) : children
  // syntax for katex and latex code
  if (
    typeof code === 'string' &&
    typeof className === 'string' &&
    /^language-katex/.test(className.toLocaleLowerCase())
  ) {
    const html = katex.renderToString(code, {
      throwOnError: false,
    })
    return (
      <code
        style={{ fontSize: '150%' }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )
  }
  const match = /language-(\w+)/.exec(className || '')
  if (match) {
    const codeString = Array.isArray(children)
      ? children.join('')
      : String(children).replace(/\n$/, '')
    const lines = codeString.split('\n')
    return (
      <SyntaxHighlighter
        {...rest}
        PreTag="pre"
        useInlineStyles={false}
        children={String(children).replace(/\n$/, '')}
        language={match[1]}
        lineProps={(lineNumber: number) => {
          const style: React.CSSProperties = { display: 'block' }
          const lineContent = lines[lineNumber - 1] || '' // Ensure lineContent is a string

          if (lineContent.startsWith('<del>') && lineContent.endsWith('</del>')) {
            style.backgroundColor = '#ffe6e6' // Red background for deletions
            const cleanLineContent = lineContent.slice(5, -6) // Remove <del></del>
            lines[lineNumber - 1] = cleanLineContent
          } else if (
            lineContent.startsWith('<ins>') &&
            lineContent.endsWith('</ins>')
          ) {
            style.backgroundColor = '#e6ffe6' // Green background for additions
            const cleanLineContent = lineContent.slice(5, -6) // Remove <ins></ins>
            lines[lineNumber - 1] = cleanLineContent
          }

          return { style }
        }}
      />
    )
  }
  return <code className={String(className)}>{children}</code>
}

export const sectionMarkdown = (props: any, message?: iMessage) => {
  // if (props.className === 'footnotes') {
  //   const ol = props.node.children.find((c) => c.tagName === 'ol')
  //   const li = ol.children.filter((c) => c.tagName === 'li')
  //   const citationIds = li.map((citation) => {
  //     const id = citation.properties.id
  //     return Number(id.substring(id.lastIndexOf('-') + 1))
  //   })
  //   console.log('rerender')
  //   return <></>
  // }
  return <section {...props}>{props.children}</section>
}

export const h2Markdown = (props: any) => {
  return <h4 className="text-lg font-semibold">{props.children}</h4>
}

export const aMarkdown = (props: any, message?: iMessage) => {
  if (props.id?.startsWith('user-content-fnref')) {
    let fnrefNumber = props.id.replace('user-content-fnref-', '')
    fnrefNumber = fnrefNumber.replace('cid-', '')
    const cidNumber = fnrefNumber.split('-').shift()
    return (
      <a
        {...props}
        id={`user-content-fnref-cid-${fnrefNumber}-${message?.id}`}
        href={`#user-content-fn-cid-${cidNumber}-${message?.id}`}
      >
        {cidNumber}
      </a>
    )
  }
  return (
    <a {...props} target={'_blank'}>
      {props.children}
    </a>
  )
}

export const preMarkdown = (pre: any) => {
  const codeChunk = pre.node.children[0].children[0].value
  return (
    <div className="preWrapper">
      <CopyToClipboard textToCopy={codeChunk}></CopyToClipboard>
      <pre {...pre}></pre>
    </div>
  )
}

export const delMarkdown = (props: any) => {
  return (
    <del style={{ backgroundColor: '#ffe6e6', textDecoration: 'line-through' }}>
      {props.children}
    </del>
  )
}

export const insMarkdown = (props: any) => {
  return (
    <ins style={{ backgroundColor: '#e6ffe6', textDecoration: 'none' }}>
      {props.children}
    </ins>
  )
}
