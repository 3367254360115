import React, { useMemo } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionItem,
  cn,
  Slider,
  Switch,
  Tooltip,
  Select,
  SelectItem,
} from '@nextui-org/react'

import { iSettings } from '@/interfaces/iSettings.ts'

const toolTipMaxTokens =
  'This parameter determines the maximum number of tokens that the model will generate in a single response. A token can be as short as one character or as long as one word. For example, "ChatGPT is great!" is five tokens. If the max tokens are set to 32, the model will stop generating more text after 32 tokens, regardless of whether it has finished the thought.'
const toolTipTemperature =
  'Lower temperatures make the model more confident, but also more conservative. As the temperature approaches zero, the model will become deterministic and repetitive. Higher temperatures cause the model to take more chances and increase diversity of results, but at a cost of more mistakes.'
const toolTipTopP =
  'Also known as nucleus sampling, this parameter filters out the least likely options for the next token. If top-p is set to 0.9, for example, the model will consider the options that together comprise 90% of the cumulative probability for the next token. This can help to ensure that the generated text is relevant and coherent.'
const toolTipTransfer =
  'This parameter determines how many messages from the chat history will be used as input to the model. If set to "all", the entire chat history will be used. If set to a number, the last n messages will be used.'
const toolTipReasoningEffort =
  'This parameter allows you to control the amount of reasoning effort the assistant will use when generating responses. Higher effort may produce more detailed and thoughtful responses at the cost of longer processing time. Default is medium.'

type AssistantModelSettingsProps = {
  onTransferLengthChange?: (range: number) => void
  onMaxTokensChange?: (range: number) => void
  onTemperatureChange?: (range: number) => void
  onTopPChange?: (range: number) => void
  onStreamChange?: (active: boolean) => void
  onReasoningEffortChange?: (value: 'low' | 'medium' | 'high') => void
  settings: iSettings
  maxOutputTokens?: number
  isDisabled?: boolean
}

function AssistantModelSettings({
  settings,
  maxOutputTokens,
  onTransferLengthChange,
  onTemperatureChange,
  onMaxTokensChange,
  onTopPChange,
  onStreamChange,
  onReasoningEffortChange,
  isDisabled,
}: AssistantModelSettingsProps) {
  const minOutputToken = 1
  const minTransfer = 2
  const maxTransfer = 21
  const temperature = 2
  const maxTopP = 1
  const minTopP = 0

  const isO1Model = useMemo(() => {
    return [
      'o1-preview-sweden-central',
      'o1-mini-sweden-central',
      'o1-sweden-central',
    ].includes(settings.modelId ?? '')
  }, [settings.modelId])

  const isReasoningModel = useMemo(() => {
    return settings.modelId === 'o1-sweden-central'
  }, [settings.modelId])

  return (
    <Accordion variant="splitted" className={'px-0'}>
      <AccordionItem
        className={`px-0 overflow-hidden !shadow-none bg-default-100`}
        aria-label="More options"
        title="More options"
        classNames={{
          heading: 'px-4',
        }}
      >
        <div className="p-4 flex flex-col gap-4">
          {!isO1Model && (
            <div className="mt-4">
              <label className="label cursor-pointer">
                <Switch
                  onChange={(e) =>
                    onStreamChange && onStreamChange(e.target.checked)
                  }
                  isDisabled={!onStreamChange || isDisabled}
                  classNames={{
                    base: cn(
                      'inline-flex flex-row-reverse w-full max-w-full items-center',
                      'justify-between cursor-pointer',
                    ),
                  }}
                  isSelected={settings.stream ?? false}
                >
                  <div className="flex flex-col gap-1">
                    <p className="text-medium">Enable stream</p>
                    <p className="text-tiny text-default-400">
                      Enables streaming of tokens
                    </p>
                  </div>
                </Switch>
              </label>
            </div>
          )}
          <div className="mt-4">
            <div>
              <Slider
                isDisabled={!onMaxTokensChange || isDisabled}
                minValue={minOutputToken}
                maxValue={maxOutputTokens}
                label="Max-Tokens"
                renderLabel={({ children, ...props }) => (
                  <label {...props} className="text-medium flex gap-2 items-center">
                    {children}
                    <Tooltip
                      content={toolTipMaxTokens}
                      className={'max-w-96'}
                      delay={500}
                    >
                      <span className="transition-opacity opacity-80 hover:opacity-100">
                        <InformationCircleIcon className="h-4 w-4" />
                      </span>
                    </Tooltip>
                  </label>
                )}
                defaultValue={settings.maxTokens}
                className="range  range-xs"
                onChangeEnd={(range) =>
                  onMaxTokensChange && onMaxTokensChange(range as number)
                }
              />
            </div>
          </div>
          {!isO1Model && (
            <div className="mt-4">
              <Slider
                isDisabled={!onTemperatureChange || isDisabled}
                label="Temperature"
                renderLabel={({ children, ...props }) => (
                  <label {...props} className="text-medium flex gap-2 items-center">
                    {children}
                    <Tooltip
                      content={toolTipTemperature}
                      className={'max-w-96'}
                      delay={500}
                    >
                      <span className="transition-opacity opacity-80 hover:opacity-100">
                        <InformationCircleIcon className="h-4 w-4" />
                      </span>
                    </Tooltip>
                  </label>
                )}
                minValue={0}
                maxValue={temperature}
                defaultValue={settings.temperature}
                step={0.01}
                className="range  range-xs"
                onChangeEnd={(range) =>
                  onTemperatureChange && onTemperatureChange(range as number)
                }
              />
            </div>
          )}
          {!isO1Model && (
            <div className="mt-4">
              <Slider
                isDisabled={!onTopPChange || isDisabled}
                label="Top-P"
                renderLabel={({ children, ...props }) => (
                  <label {...props} className="text-medium flex gap-2 items-center">
                    {children}
                    <Tooltip
                      content={toolTipTopP}
                      className={'max-w-96'}
                      delay={500}
                    >
                      <span className="transition-opacity opacity-80 hover:opacity-100">
                        <InformationCircleIcon className="h-4 w-4" />
                      </span>
                    </Tooltip>
                  </label>
                )}
                minValue={minTopP}
                maxValue={maxTopP}
                step={0.01}
                defaultValue={settings.topP}
                className="range range-xs"
                onChangeEnd={(range) =>
                  onTopPChange && onTopPChange(range as number)
                }
              />
            </div>
          )}
          <div className="mt-4">
            <Slider
              isDisabled={!onTransferLengthChange || isDisabled}
              label="Transferred"
              renderLabel={({ children, ...props }) => (
                <label {...props} className="text-medium flex gap-2 items-center">
                  {children}
                  <Tooltip
                    content={toolTipTransfer}
                    className={'max-w-96'}
                    delay={500}
                  >
                    <span className="transition-opacity opacity-80 hover:opacity-100">
                      <InformationCircleIcon className="h-4 w-4" />
                    </span>
                  </Tooltip>
                </label>
              )}
              getValue={(value) =>
                (value as number[])[0] === 21 ? 'all' : value.toString()
              }
              minValue={minTransfer}
              maxValue={maxTransfer}
              step={1}
              defaultValue={settings.transferLength}
              className="range  range-xs"
              onChangeEnd={(range) => {
                onTransferLengthChange && onTransferLengthChange(range as number)
              }}
            />
          </div>
          {isReasoningModel && (
            <div className="mt-4">
              <Select
                isDisabled={!onReasoningEffortChange || isDisabled}
                selectedKeys={[settings.reasoningEffort ?? 'medium']}
                label={
                  <span className="text-medium flex gap-2 items-center">
                    Reasoning Effort
                    <Tooltip
                      content={toolTipReasoningEffort}
                      className={'max-w-96'}
                      delay={500}
                    >
                      <span className="transition-opacity opacity-80 hover:opacity-100">
                        <InformationCircleIcon className="h-4 w-4" />
                      </span>
                    </Tooltip>
                  </span>
                }
                onSelectionChange={(keys) => {
                  const value = Array.from(keys)[0] as 'low' | 'medium' | 'high'
                  onReasoningEffortChange && onReasoningEffortChange(value)
                }}
              >
                <SelectItem key="low">Low</SelectItem>
                <SelectItem key="medium">Medium</SelectItem>
                <SelectItem key="high">High</SelectItem>
              </Select>
            </div>
          )}
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default AssistantModelSettings
