import axiosClient from '../utils/axiosClient'
import { iTool } from '../interfaces/iTool.ts'
import { AccessRight } from '@/interfaces/iShared.ts'

class ToolService {
  private static instance: ToolService

  private constructor() {}

  public static getInstance(): ToolService {
    if (!ToolService.instance) {
      ToolService.instance = new ToolService()
    }
    return ToolService.instance
  }

  /**
   * Get all tools
   * @param options
   */

  public async getTools(options?: {
    searchValue?: string
    accessRight?: AccessRight
    limit?: number
    offset?: number
  }): Promise<{ data: iTool[]; total: number }> {
    const params = {
      searchValue: options?.searchValue,
      limit: options?.limit,
      offset: options?.offset,
      accessRight: options?.accessRight,
    }
    const response = await axiosClient.get(`/tools`, {
      params: {
        ...params,
      },
    })
    return response.data as { data: iTool[]; total: number }
  }

  public async runTool(toolId: string, parameters: any): Promise<any> {
    const response = await axiosClient.post(`/tools/${toolId}/run`, parameters)
    return response.data
  }

  public async getTool(toolsId: string): Promise<iTool> {
    return (await axiosClient.get(`/tools/${toolsId}`)).data as iTool
  }

  public async createTool(tool: iTool): Promise<iTool> {
    return (await axiosClient.post(`/tools`, tool)).data as iTool
  }

  public async updateTool(tool: iTool): Promise<iTool> {
    const response = await axiosClient.put(`/tools`, tool)
    return response.data
  }

  public async deleteTool(toolsId: string): Promise<void> {
    await axiosClient.delete(`/tools/${toolsId}`)
  }

  public async createToolIcon(toolId: string): Promise<string> {
    const response = await axiosClient.post(`/tools/${toolId}/icons`)
    if (response.status !== 200) {
      throw new Error('Could not create tool icon')
    }
    return response.data as string
  }

  public async deleteToolIcon(toolId: string, iconId: string): Promise<void> {
    const response = await axiosClient.delete(`/tools/${toolId}/icons/${iconId}`)
    if (response.status !== 200 && response.status !== 204) {
      throw new Error('Could not delete tool icon')
    }
  }

  public async getToolIconUrl(toolId: string, iconId: string): Promise<string> {
    const response = await axiosClient.get(`/tools/${toolId}/icons/${iconId}`)
    if (response.status !== 200) {
      throw new Error('Could not get tool icon URL')
    }
    return response.data as string
  }
}

export default ToolService.getInstance()
