import React, { useEffect, useMemo, useState } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Tab,
  Tabs,
  useDisclosure,
} from '@nextui-org/react'
import { useLocation, useSearchParams } from 'react-router-dom'
import ShareLinkService from '../../services/shareLinkService.ts'
import {
  EntityType,
  iShareLink,
  iShareLinkItemPreview,
  SharableEntityType,
} from '@/interfaces/iShared.ts'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import JoinInviteCard from './JoinInviteCard.tsx'
import useTeamStore from '@/states/teamStore.ts'
import { shallow } from 'zustand/shallow'
import userService from '../../services/UserService.ts'
import useFolderStore from '@states/folderStore.ts'
import useAssistantStore from '@states/assistantStore.ts'
import useKnowledgeContainerStore from '@states/knowledgeContainerStore.ts'
import useToolStore from '@states/toolStore.ts'
import useAssistantModelStore from '@states/assistantModelStore.ts'
import {
  invitationItemIdSearchParam,
  invitationItemTypeSearchParam,
  invitationLinkIdSearchParam,
  invitationPathName,
  invitationSearchParam,
} from '@/constants/invation.ts'
import { getUserKey } from '@/utils/authentication.ts'
import CreateSessionButton from '../session/CreateSessionButton.tsx'
import useUserProfileStore from '@states/userProfileState.ts'
import IconFetcher from '@components/basic/icon/IconFetcher.tsx'

type InvitationDialogProps = React.HTMLAttributes<HTMLDialogElement> & {}
export default function InvitationDialog({}: InvitationDialogProps) {
  const location = useLocation()
  const { selectedTeam, getTeams } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
      getTeams: state.getTeams,
    }),
    shallow,
  )
  const { loadAssistants } = useAssistantStore(
    (state) => ({
      loadAssistants: state.loadAssistants,
    }),
    shallow,
  )
  const { getFolders } = useFolderStore(
    (state) => ({
      getFolders: state.getFolders,
    }),
    shallow,
  )
  const { loadKnowledgeContainers } = useKnowledgeContainerStore(
    (state) => ({
      loadKnowledgeContainers: state.loadKnowledgeContainers,
    }),
    shallow,
  )
  const { loadAssistantModels } = useAssistantModelStore(
    (state) => ({
      loadAssistantModels: state.loadAssistantModels,
    }),
    shallow,
  )
  const { loadTools } = useToolStore(
    (state) => ({
      loadTools: state.loadTools,
    }),
    shallow,
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const [linkId, setLinkId] = useState<string | null>()
  const [itemId, setItemId] = useState<string | null>()
  const [itemType, setItemType] = useState<SharableEntityType | null>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const [shareLinkInfo, setShareLinkInfo] = useState<{
    shareLink: iShareLink
    itemPreview: iShareLinkItemPreview
  }>()
  const [error, setError] = useState()
  const [selected, setSelected] = React.useState<
    | 'validateLink'
    | 'errorLink'
    | 'errorUser'
    | 'errorTeam'
    | 'teamOrUser'
    | 'team'
    | 'addTeam'
    | 'user'
    | 'addUser'
  >('validateLink')
  const userProfile = useUserProfileStore((state) => state.userProfile)

  useEffect(() => {
    // avoid reload if loading is in progress, shareLink is set or error occured
    if (isLoading || shareLinkInfo || error) return
    const isInvitation = searchParams.get(invitationSearchParam)
    if (location.pathname === invitationPathName && isInvitation) {
      const linkId = searchParams.get(invitationLinkIdSearchParam)
      const itemId = searchParams.get(invitationItemIdSearchParam)
      const itemType = Number(searchParams.get(invitationItemTypeSearchParam))
      setLinkId(linkId)
      setItemType(itemType)
      setItemId(itemId)
      if (!linkId || !itemId || itemType == null)
        return console.error('no valid invitation link type provided')
      onOpen()
      setIsLoading(true)
      ShareLinkService.validateShareLink(
        itemId,
        itemType as unknown as SharableEntityType,
        linkId,
      )
        .then((data) => {
          setShareLinkInfo(data)
          setSelected('teamOrUser')
        })
        .catch((e) => {
          setError(e)
          setSelected('errorLink')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [location, searchParams])

  const joinAsUser = useMemo(() => {
    return shareLinkInfo?.shareLink?.allowedEntityTypes.includes(EntityType.User)
  }, [shareLinkInfo])

  const joinAsTeam = useMemo(() => {
    return shareLinkInfo?.shareLink?.allowedEntityTypes.includes(EntityType.Team)
  }, [shareLinkInfo])

  const resourceTypeText = useMemo(() => {
    switch (itemType) {
      case SharableEntityType.Assistant:
        return `Assistant`
      case SharableEntityType.Team:
        return `Team`
      case SharableEntityType.KnowledgeContainer:
        return `KnowledgeContainer`
      case SharableEntityType.Tool:
        return `Tool`
      default:
        return `Tool`
    }
  }, [shareLinkInfo, searchParams])
  const headerText = useMemo(() => {
    return `Join ${resourceTypeText} '${shareLinkInfo?.itemPreview.name}'`
  }, [shareLinkInfo, searchParams])

  const joinToResource = (entityId: string, entityType: EntityType) => {
    if (!linkId || !itemId || itemType == null) return
    setIsLoading(true)
    setSelected(EntityType.User === entityType ? 'addUser' : 'addTeam')
    ShareLinkService.addEntityToItemViaShareLink(
      itemId,
      itemType,
      linkId,
      entityId,
      entityType,
    )
      .then(() => {
        setSelected(EntityType.User === entityType ? 'user' : 'team')
        if (itemType === SharableEntityType.Team) return getTeams()
      })
      .then(() => {
        // load assistants if you join a team or assistant
        if (
          itemType === SharableEntityType.Team ||
          itemType === SharableEntityType.Assistant
        )
          return loadAssistants()
      })
      .then(() => {
        // load tools
        if (
          itemType === SharableEntityType.Team ||
          itemType === SharableEntityType.Assistant
        )
          return loadTools()
      })
      .then(() => {
        // load kc
        if (
          itemType === SharableEntityType.Team ||
          itemType === SharableEntityType.Assistant
        )
          return loadKnowledgeContainers()
      })
      .then(() => {
        // load models
        if (
          itemType === SharableEntityType.Team ||
          itemType === SharableEntityType.Assistant
        )
          return loadAssistantModels(['chat', 'text'])
      })
      .catch((e) => {
        setSelected(EntityType.User === entityType ? 'errorUser' : 'errorTeam')
        console.error(e)
      })
      .finally(() => setIsLoading(false))
  }
  const onBeforeCreateSession = async () => {
    if (itemType === SharableEntityType.Team) {
      searchParams.set('teamId', itemId!)
      setSearchParams(searchParams)
      userService.setLastTeamId(itemId!)
      return getFolders({ teamId: itemId! }).then(() => setIsLoading(false))
    }
  }
  const onCreatedNewSession = (onClose: () => void) => {
    onClose()
  }
  return (
    <>
      <Modal
        scrollBehavior={'inside'}
        backdrop={'blur'}
        shouldCloseOnInteractOutside={() => false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onClose={() => {
          searchParams.delete(invitationSearchParam)
          searchParams.delete(invitationLinkIdSearchParam)
          searchParams.delete(invitationItemTypeSearchParam)
          searchParams.delete(invitationItemIdSearchParam)
          setSearchParams(searchParams)
        }}
        classNames={{
          base: '!max-w-[500px] w-fit',
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <div className="flex items-center ">
                  <h3 className="font-bold text-lg mr-2">{headerText}</h3>
                  <IconFetcher
                    entityId={itemId!}
                    entityType={resourceTypeText}
                  ></IconFetcher>
                </div>
              </ModalHeader>
              <ModalBody>
                <Tabs
                  variant="underlined"
                  aria-label="Options"
                  selectedKey={selected}
                  classNames={{
                    tabList: 'hidden',
                  }}
                  onSelectionChange={(key) => setSelected(key as any)}
                >
                  <Tab key="validateLink" title="a">
                    <div className={'flex flex-col justify-center items-center'}>
                      <Spinner size={'lg'} className={'pb-6'}></Spinner>
                      <b>Invitation link will be checked ...</b>
                    </div>
                  </Tab>
                  <Tab key="errorLink" title="b">
                    <div className={'flex flex-col justify-center items-center'}>
                      <ExclamationTriangleIcon
                        className={'h-12 w-12 text-danger'}
                      ></ExclamationTriangleIcon>
                      <b className={'my-4 '}>Link is not valid</b>
                      <p>
                        The invitation link does not seem to be valid. Make sure that
                        you have entered the correct link in the address bar and
                        contact the creator of the link if necessary
                      </p>
                    </div>
                  </Tab>
                  <Tab key="teamOrUser" title="c">
                    <>
                      {joinAsTeam && (
                        <JoinInviteCard
                          isDisabled
                          variant={'team'}
                          shared={shareLinkInfo?.itemPreview.shared ?? []}
                          onClick={() => setSelected('team')}
                        ></JoinInviteCard>
                      )}
                      {joinAsUser && joinAsTeam && (
                        <div className={`divider transition`}>OR</div>
                      )}
                      {joinAsUser && (
                        <JoinInviteCard
                          variant={'user'}
                          shared={shareLinkInfo?.itemPreview.shared ?? []}
                          onClick={() => {
                            joinToResource(userProfile?.id!, EntityType.User)
                          }}
                        ></JoinInviteCard>
                      )}
                    </>
                  </Tab>
                  <Tab key="team" title="c">
                    <>Team</>
                  </Tab>
                  <Tab key="user" title="c">
                    <div className={'flex flex-col justify-center items-center'}>
                      <CheckCircleIcon
                        className={'h-12 w-12 text-success'}
                      ></CheckCircleIcon>
                      <b className={'my-4 '}>Added to {resourceTypeText}</b>
                      <p>
                        You were added successfully to
                        <b>'{shareLinkInfo?.itemPreview.name}'</b>
                      </p>
                    </div>
                    <div>
                      <p className={'py-6'}>
                        Click on the button below to create a new Session with
                        <b>'{shareLinkInfo?.itemPreview.name}'</b>
                      </p>
                      <CreateSessionButton
                        loading={isLoading}
                        selectedTeam={
                          itemType === SharableEntityType.Team
                            ? itemId!
                            : selectedTeam!
                        }
                        assistantId={
                          itemType === SharableEntityType.Assistant
                            ? itemId!
                            : undefined
                        }
                        defaultFolderName={shareLinkInfo?.itemPreview.name!}
                        onCreated={() => onCreatedNewSession(onClose)}
                        onBeforeCreateSession={onBeforeCreateSession}
                        description={`Create a new session with '${shareLinkInfo?.itemPreview.name}'`}
                      ></CreateSessionButton>
                    </div>
                  </Tab>
                  <Tab key="addUser" title="a">
                    <div className={'flex flex-col justify-center items-center'}>
                      <Spinner size={'lg'} className={'pb-6'}></Spinner>
                      <b>Adding user ...</b>
                    </div>
                  </Tab>
                  <Tab key="addTeam" title="a">
                    <div className={'flex flex-col justify-center items-center'}>
                      <Spinner size={'lg'} className={'pb-6'}></Spinner>
                      <b>Adding Teams ...</b>
                    </div>
                  </Tab>
                </Tabs>
              </ModalBody>
              <ModalFooter>
                <Button isLoading={isLoading} variant={'flat'} onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
