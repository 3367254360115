import axiosClient from '../utils/axiosClient'
import { iAssistantVariable, iUser } from '../interfaces/iUser.ts'
import { AccessRight } from '@/interfaces/iShared.ts'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import { AuthRole } from '@/utils/authentication.ts'

class UserService {
  private static instance: UserService
  // private baseURL: string = import.meta.env.VITE_API_BASE_URL;

  private constructor() {}

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService()
    }

    return UserService.instance
  }

  public async createUser(email: string): Promise<void> {
    await axiosClient.post(`/users/${email}`, {})
  }

  public async deleteUser(userId: string): Promise<void> {
    await axiosClient.delete(`/users/${userId}`)
  }

  public async getMe(): Promise<iUser> {
    return (await axiosClient.get(`/users/me`)).data as iUser
  }

  public async getUser(id: string): Promise<iUser> {
    return (await axiosClient.get(`/users/${id}`)).data as iUser
  }

  public async acceptedToUVersion(
    isoDate: string,
    resourceId?: string,
  ): Promise<iUser> {
    return (
      await axiosClient.patch(`/users/me/terms-of-use`, {
        version: isoDate,
        resourceId,
      })
    ).data as iUser
  }

  public async getUsers(filter?: {
    searchValue?: string
    limit?: number
    offset?: number
  }): Promise<{ data: iUser[]; total: number }> {
    return (
      await axiosClient.get(`/users`, {
        params: {
          limit: filter?.limit,
          offset: filter?.offset,
          searchValue: filter?.searchValue,
        },
      })
    ).data as {
      data: iUser[]
      total: number
    }
  }

  public async setLastTeamId(teamId: string): Promise<iUser[]> {
    return (
      await axiosClient.patch(`/users/me/profile`, {
        lastTeamId: teamId,
      })
    ).data as iUser[]
  }

  public async setLastSessionId(sessionId: string): Promise<iUser[]> {
    return (
      await axiosClient.patch(`/users/me/profile`, {
        lastSessionId: sessionId,
      })
    ).data as iUser[]
  }

  public async addUserToCostCenter(
    userId: string,
    costcenterId: string,
  ): Promise<void> {
    await axiosClient.patch(`/users/${userId}/costcenter/${costcenterId}`, {})
  }

  public async removeUserFromCostCenter(
    userId: string,
    costcenterId: string,
  ): Promise<void> {
    await axiosClient.delete(`/users/${userId}/costcenter/${costcenterId}`)
  }

  public async userPicture(): Promise<string> {
    const response = await axiosClient.get(`/users/me/profile-picture`)
    return 'data:image/jpeg;base64,' + response.data
  }

  public async userPictureByMail(email: string): Promise<string> {
    const response = await axiosClient.get(`/users/${email}/profile-picture/`)
    return 'data:image/jpeg;base64,' + response.data
  }

  public async searchUsers(): Promise<{ data: iUser[]; total: number }> {
    return (await axiosClient.get(`/users`)).data as { data: iUser[]; total: number }
  }

  public async updateUserProfileChangelog(changelog: string): Promise<void> {
    await axiosClient.patch(`/users/me/profile`, { changelog: changelog })
  }

  public async updateUserProfileAssistantLanguage(
    assistantLanguage: string,
  ): Promise<void> {
    await axiosClient.patch(`/users/me/profile`, { assistantLanguage })
  }

  public async updateUserAsAdmin(userId: string, role: AuthRole): Promise<void> {
    await axiosClient.patch(`/users/${userId}`, { params: { role } })
  }
}

export default UserService.getInstance()
