import { getAccessToken } from '@/utils/authentication.ts'

export function isServiceConnectDomain(domain: string) {
  return domain.includes('https://api.connect.draeger.com/')
}

export function addBearer(domain: string) {
  if (isServiceConnectDomain(domain)) {
    return `&bearer=${getAccessToken()}`
  }
  return ''
}
