import React, { useState, useEffect } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'

import KnowledgeContainerDetailView from '@components/knowledgeContainers/KnowledgeContainerDetailView.tsx'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'

type KnowledgeContainerDialogProps = {
  value: iKnowledgeContainer
  onDialogClose?: () => void
}

function KnowledgeContainerDialog({
  value,
  onDialogClose,
}: KnowledgeContainerDialogProps) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  // patkeys is a dict with the teamId as key and the pat as value
  useEffect(() => {
    onOpen()
  }, [])
  return (
    <Modal
      isOpen={isOpen}
      scrollBehavior={'inside'}
      backdrop={'blur'}
      onOpenChange={() => {
        if (isOpen) onDialogClose && onDialogClose()
        onOpenChange()
      }}
      classNames={{
        base: '!max-w-[60vw]',
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>
              <div className="flex items-center w-full justify-between">
                <h3 className="text-xl font-semibold">{value.name}</h3>
              </div>
            </ModalHeader>
            <ModalBody>
              <KnowledgeContainerDetailView
                value={value}
                isDisabled
              ></KnowledgeContainerDetailView>
            </ModalBody>
            <ModalFooter>
              <Button onPress={onClose}>Close</Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default KnowledgeContainerDialog
