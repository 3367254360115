import React, { useMemo } from 'react'
import { Button, Card } from '@nextui-org/react'
import { ChevronRightIcon } from '@nextui-org/shared-icons'
import { getDocumentIcon } from '@/utils/getDocumentIcon'
import { iDocumentItem } from '@/interfaces/iDocumentItem'
import useChatStore from '@states/chatStore'
import { shallow } from 'zustand/shallow'
import { DocumentType } from '@/enums/DocumentType'

type DocumentItemCardProps = {
  item: iDocumentItem
  onPress: (item: iDocumentItem, sourceId?: number) => void
}

const DocumentItemCard = ({ item, onPress }: DocumentItemCardProps) => {
  const fileType = item.documentType

  const filename = useMemo(() => {
    if (item.documentType === DocumentType.WebPage) return 'External Link'
    if (item.documentType === DocumentType.Tool) {
      return item.title.replace('.tool', '')
    }
    return item.title
  }, [item])

  const { messagesInView } = useChatStore(
    (state) => ({
      messagesInView: state.messagesInView,
    }),
    shallow,
  )

  // Gather all references (matched or not) in one array
  const allReferences = useMemo(() => {
    const refs: {
      sourceId: number
      description: string
      timestamp: string
      matched: boolean
    }[] = []
    item._documentChunks?.forEach((chunk) => {
      chunk.references.forEach((ref) => {
        refs.push({
          sourceId: chunk.sourceId,
          description: ref.description,
          timestamp: ref.timestamp,
          matched: messagesInView?.some((m) => m === ref.messageId) ?? false,
        })
      })
    })
    return refs
  }, [item, messagesInView])

  return (
    <Card
      isHoverable
      className={`p-4 w-full mt-2 ${
        // If there is at least one matching reference,
        // remove the default lighter background
        allReferences.some((r) => r.matched) ? '' : 'bg-default bg-opacity-15'
      }`}
    >
      <div className="flex w-full items-center gap-3 truncate">
        {getDocumentIcon(fileType)}
        <div className="flex-1 text-start min-w-0 truncate">
          <h3 className="font-medium truncate">{filename}</h3>

          {/* Display total reference count from our aggregated references array */}
          <p className="text-sm text-gray-500">
            Found {allReferences.length} reference(s)
          </p>

          {allReferences.length > 0 && (
            <div className="mt-2">
              <p className="text-xs">Message References:</p>
              <ul className="text-sm">
                {allReferences.map((ref, index) => (
                  <li
                    key={index}
                    // Highlight if matched, dim if not
                    className={`cursor-pointer hover:underline ${
                      ref.matched ? '' : 'opacity-50'
                    }`}
                    onClick={() => onPress(item, ref.sourceId)}
                  >
                    <span className="text-xs text-blue-500">[{ref.sourceId}]</span>
                    <span className="text-xs"> - </span>
                    {ref.description}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <Button isIconOnly variant="light" size="lg" onPress={() => onPress(item)}>
          <ChevronRightIcon className="w-4 h-4" />
        </Button>
      </div>
    </Card>
  )
}

export default DocumentItemCard
