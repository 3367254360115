import { iTool } from '@/interfaces/iTool.ts'
import { getFAQById } from '@/utils/getLocalFaqs.ts'
import { FAQ_ID_KNOWLEDGE_CONTAINERS, FAQ_ID_TOOLS } from '@/constants/faqIDs.ts'
import FaqItem from '@components/faq/FaqItem.tsx'
import React from 'react'
import ToolService from '@/services/toolService.ts'
import { Alert, Skeleton, Tab, Tabs, useDisclosure } from '@nextui-org/react'

import AssistantSettings from '@components/session/AssistantSettings.tsx'
import ItemSelection from '@components/tools/ItemSelection.tsx'
import KnowledgeContainerService from '@/services/knowledgeContainerService.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'

import useChatStore from '@/states/chatStore.ts'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import {
  ICON_HELP,
  ICON_KNOWLEDGE_CONTAINER,
  ICON_RESET,
  ICON_TOOL,
} from '@/constants/icons.tsx'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'
import IconFetcher from '@components/basic/icon/IconFetcher.tsx'

import SidebarItem from '@components/basic/sidenav/SidenavItem.tsx'
import useMainStore from '@states/mainState.ts'
import { Popup } from '@/enums/PopupTypes.ts'
import AssistantSelectDrawer from '@components/assistants/AssistantSelectDrawer.tsx'
import { color } from 'framer-motion'

const SessionGeneralView = () => {
  const assistant = useChatStore((state) => state.assistant)
  const inheritedAssistant = useChatStore((state) => state.inheritedAssistant)
  const updateAssistant = useChatStore().updateAssistant

  const setSessionToDefault = useChatStore().setSessionToDefault
  const isLoadingTools = useChatStore().isLoadingTools
  const isLoadingKnowledgeContainer = useChatStore().isLoadingKnowledgeContainer
  const tools = useChatStore().tools
  const knowledgeContainers = useChatStore().knowledgeContainers
  const setTools = useChatStore().setTools
  const setKnowledgeContainers = useChatStore().setKnowledgeContainers
  const setPopup = useMainStore((state) => state.setPopup)
  const toolFAQ = getFAQById(FAQ_ID_TOOLS)
  const knowledgeContainerFAQ = getFAQById(FAQ_ID_KNOWLEDGE_CONTAINERS)

  const onChangeTools = async (tools: iTool[]) => {
    if (!assistant) return
    assistant!.toolIds = tools.map((tool) => tool.id) as string[]
    assistant!._tools = tools

    return updateAssistant(assistant).then(() => {
      setTools(tools)
    })
  }

  const onChangeKnowledgeContainers = async (
    knowledgeContainers: iKnowledgeContainer[],
  ) => {
    if (!assistant) return
    assistant!.knowledgeContainerIds = knowledgeContainers.map(
      (kc) => kc.id,
    ) as string[]
    assistant!._knowledgeContainers = knowledgeContainers
    return updateAssistant(assistant).then(() => {
      setKnowledgeContainers(knowledgeContainers)
    })
  }

  const onChangeAssistantSettings = async (assistant: iAssistant) => {
    await updateAssistant(assistant)
  }

  //TODO: REFACTOR!
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()
  return (
    <div>
      <div
        className="flex items-center space-x-2 p-2 rounded cursor-pointer"
        onClick={onOpen}
      >
        <IconFetcher
          entityId={assistant?.baseAssistantId ?? assistant!.id!}
          entityType={'Assistant'}
          imageId={assistant?.image}
        />
        <div className="flex-grow">
          <div className="font-semibold">{assistant?.title}</div>
          <div className="text-sm text-gray-500">
            by{' '}
            {(inheritedAssistant ?? assistant)?.ownerName || assistant?.ownerEmail}
          </div>
        </div>
        <SidebarItem
          tooltip="Reset to default Assistant"
          onClick={() => setSessionToDefault()}
          icon={<ICON_RESET />}
        ></SidebarItem>
      </div>

      <Tabs variant="underlined">
        <Tab key="general" title="General">
          <div className={'flex flex-col gap-4'}>
            {isLoadingTools ? (
              <Skeleton className="rounded-large m-2 w-[340px] h-[40px]"></Skeleton>
            ) : (
              <SectionCard
                className=" min-w-72"
                icon={<ICON_TOOL></ICON_TOOL>}
                title="Active Tools"
                description=""
              >
                {assistant?.configuration?.tools.visible ? (
                  <>
                    <ItemSelection
                      isDisabled={!assistant?.configuration?.tools.editable}
                      onClickItem={(item) => setPopup(Popup.Tool, { value: item })}
                      label="Add tools"
                      placeholder="Seek for tools"
                      loadFunc={ToolService.getTools}
                      selectedItems={tools}
                      onChange={(tools) => onChangeTools(tools as iTool[])}
                    />
                    {!assistant?.configuration?.tools.editable && (
                      <Alert
                        color="warning"
                        variant="solid"
                        title={`Tools can´t be modified`}
                      />
                    )}
                  </>
                ) : (
                  <Alert
                    color="warning"
                    variant="solid"
                    title={`Tools are not visible for this assistant`}
                  />
                )}
              </SectionCard>
            )}
            {isLoadingKnowledgeContainer ? (
              <Skeleton className="rounded-large m-2 w-[340px] h-[40px]"></Skeleton>
            ) : (
              <SectionCard
                className=" min-w-72"
                icon={<ICON_KNOWLEDGE_CONTAINER></ICON_KNOWLEDGE_CONTAINER>}
                title="Active Knowledge-Containers"
                description=""
              >
                {assistant?.configuration?.knowledgeContainer.visible ? (
                  <>
                    <ItemSelection
                      label="Add knowledge containers"
                      isDisabled={
                        !assistant?.configuration?.knowledgeContainer.editable
                      }
                      onClickItem={(item) =>
                        setPopup(Popup.KnowledgeContainer, { value: item })
                      }
                      placeholder="Seek for knowledge containers"
                      loadFunc={KnowledgeContainerService.getKnowledgeContainers}
                      selectedItems={knowledgeContainers}
                      onChange={(kc) =>
                        onChangeKnowledgeContainers(kc as iKnowledgeContainer[])
                      }
                    ></ItemSelection>
                    {!assistant?.configuration?.knowledgeContainer.editable && (
                      <Alert
                        color="warning"
                        variant="solid"
                        title={`Knowledge-Containers can´t be modified`}
                      />
                    )}
                  </>
                ) : (
                  <Alert
                    color="warning"
                    variant="solid"
                    title={`Knowledge-Containers are not visible for this assistant`}
                  />
                )}
              </SectionCard>
            )}

            <SectionCard
              className=" min-w-72"
              icon={<ICON_HELP></ICON_HELP>}
              title="Help Center"
              description=""
            >
              <div className="flex flex-col gap-4">
                {toolFAQ && <FaqItem markdown={toolFAQ?.markdown}></FaqItem>}
                {knowledgeContainerFAQ && (
                  <FaqItem markdown={knowledgeContainerFAQ?.markdown}></FaqItem>
                )}
              </div>
            </SectionCard>
          </div>
        </Tab>
        <Tab key="model-settings" title="Model Settings">
          <div className="w-full">
            <SectionCard
              className=" min-w-72"
              icon={<ICON_TOOL></ICON_TOOL>}
              title="Model Settings"
              description=""
            >
              {assistant?.configuration?.modelSettings.visible ? (
                <>
                  {!assistant?.configuration?.modelSettings.editable && (
                    <Alert
                      color="warning"
                      variant="solid"
                      className="mb-2"
                      title={`Model settings can´t be modified`}
                    />
                  )}
                  <AssistantSettings
                    isDisabled={!assistant?.configuration?.modelSettings.editable}
                    assistant={assistant!}
                    updateAssistant={onChangeAssistantSettings}
                  ></AssistantSettings>
                </>
              ) : (
                <Alert
                  color="warning"
                  variant="solid"
                  title={`Model settings are not visible for this assistant`}
                />
              )}
            </SectionCard>
          </div>
        </Tab>
      </Tabs>
      <AssistantSelectDrawer
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
      ></AssistantSelectDrawer>
    </div>
  )
}

export default SessionGeneralView
