import { useEffect, useState } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { FlagField, InputField, TextField } from '../basic/inputfields/model.ts'
import { FieldsEditor } from '../basic/inputfields/FieldEditors.tsx'
import {
  iOverridableParameter,
  iOverridableParameterItem,
} from '../../interfaces/iOverridableParameter.ts'
import { ICON_DELETE } from '@/constants/icons.tsx'
import { Button, Input } from '@nextui-org/react'

const tooltipHasDefaullt = `
  If the parameter has a default value, the value is used when the parameter is not mapped to a user secret.
`
const tooltipDefault = 'The actual default value for the parameter.'

type OverridableParameterEditorProps = {
  item: iOverridableParameterItem
  index: number
  onNameChange: (index: number, newName: string) => void
  onValueChange: (index: number, overridable: iOverridableParameter) => void
  onRemove(index: number): void
  isDisabled?: boolean
}

const HttpOverridableEditor = ({
  item,
  index,
  onNameChange,
  onValueChange,
  onRemove,
  isDisabled,
}: OverridableParameterEditorProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [fields, setFields] = useState<InputField[]>([
    new FlagField({
      key: 'hasDefaultValue',
      name: 'Has default value',
      value: item.overridableParameter.hasDefaultValue,
      info: tooltipHasDefaullt,
    }),
    new TextField({
      key: 'defaultValue',
      name: 'Default value',
      value: item.overridableParameter.defaultValue,
      info: tooltipDefault,
    }),
  ])
  const [hiddenFields, setHiddenFields] = useState<string[]>([])

  useEffect(() => {
    if (!item.name) {
      setIsExpanded(true)
    }
  }, [])

  useEffect(() => {
    const hasDefaultValue = (fields[0] as FlagField).value
    if (hasDefaultValue) {
      setHiddenFields([])
    } else {
      setHiddenFields(['defaultValue'])
    }
    onValueChange(index, {
      hasDefaultValue,
      defaultValue: (fields[1] as TextField).value,
    })
  }, [fields])

  return (
    <>
      <div className="p-2 bg-default rounded-lg mb-2">
        <details open={isExpanded}>
          <summary>
            <div className={'inline ml-1'}>
              <Input
                readOnly={isDisabled}
                value={item.name}
                placeholder="Name"
                onChange={(e) => onNameChange(index, e.target.value)}
                isRequired
                endContent={
                  <Button
                    isDisabled={isDisabled}
                    isIconOnly
                    variant="light"
                    onPress={() => onRemove(index)}
                  >
                    <ICON_DELETE />
                  </Button>
                }
              />
            </div>
          </summary>
          <div className="w-10/12">
            <FieldsEditor
              isDisabled={isDisabled}
              fields={fields}
              onChange={setFields}
              hide={hiddenFields}
            ></FieldsEditor>
          </div>
        </details>
      </div>
    </>
  )
}
export default HttpOverridableEditor
