import React, { useCallback, useMemo } from 'react'
import { Card, CardBody, CardHeader, Button } from '@nextui-org/react'
import { iDocumentChunk } from '@/interfaces/iDocumentChunk'
import { iReference } from '@/interfaces/iReference'
import { TbMessageForward } from 'react-icons/tb'
import useChatStore from '@states/chatStore.ts'
import { shallow } from 'zustand/shallow'

type ReferencesOverlayProps = {
  documentChunks: iDocumentChunk[]
  setPage: (page: number) => void
  currentPage: number
}

const ReferencesOverlay: React.FC<ReferencesOverlayProps> = ({
  documentChunks = [],
  setPage,
  currentPage,
}) => {
  const { messagesInView, goToMessageById } = useChatStore(
    (state) => ({
      messagesInView: state.messagesInView,
      goToMessageById: state.goToMessageById,
    }),
    shallow,
  )

  const pagesWithReferences = useMemo(() => {
    return [
      ...new Set(documentChunks.map((chunk) => chunk.excerpt.startOffset)),
    ].sort((a, b) => a - b)
  }, [documentChunks])

  const currentChunks = useMemo(() => {
    return documentChunks.filter(
      (chunk) => chunk.excerpt.startOffset === currentPage,
    )
  }, [documentChunks, currentPage])

  const allReferences = useMemo(() => {
    return currentChunks.flatMap((chunk) => chunk.references)
  }, [currentChunks])

  // Determine pages that have references to the current message
  const pagesWithCurrentMessageReferences = useMemo(() => {
    const pagesSet = new Set<number>()
    documentChunks.forEach((chunk) => {
      chunk.references.forEach((ref) => {
        if (messagesInView?.some((m) => m === ref.messageId)) {
          pagesSet.add(chunk.excerpt.startOffset)
        }
      })
    })
    return Array.from(pagesSet)
  }, [documentChunks, messagesInView])

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page)
    },
    [setPage],
  )
  // Render pagination buttons
  const renderPaginationButtons = useMemo(() => {
    return pagesWithReferences.map((page) => {
      const isCurrentPage = page === currentPage
      const hasCurrentMessageReference =
        pagesWithCurrentMessageReferences.includes(page)
      const color = isCurrentPage
        ? 'primary'
        : hasCurrentMessageReference
          ? 'secondary'
          : 'default'

      return (
        <Button
          key={page}
          variant={isCurrentPage ? 'solid' : 'solid'}
          color={color}
          style={{ minWidth: '2rem' }}
          size="sm"
          onPress={() => handlePageChange(page)}
          className="mx-1"
          radius="sm"
        >
          {page}
        </Button>
      )
    })
  }, [
    pagesWithReferences,
    currentPage,
    pagesWithCurrentMessageReferences,
    handlePageChange,
  ])

  const sourceIdFromReference = useCallback(
    (reference: iReference) => {
      return documentChunks.find((chunk) => chunk.references.includes(reference))
        ?.sourceId
    },
    [documentChunks],
  )

  const renderReference = useCallback(
    (reference: iReference) => {
      return (
        <div
          key={reference.messageId}
          className="flex items-center space-x-2 p-2 rounded hover:bg-default-100"
        >
          <div className="flex-grow min-w-0">
            <p className="text-sm truncate">
              [{sourceIdFromReference(reference)}]: {reference.description}
            </p>
          </div>
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onPress={() => {
              goToMessageById(reference.messageId)
            }}
          >
            <TbMessageForward className="h-4 w-4 text-secondary" />
          </Button>
        </div>
      )
    },
    [sourceIdFromReference, allReferences],
  )

  return (
    <Card className="w-full shadow-large">
      <CardHeader className="flex flex-col gap-2 bg-default">
        <div className="flex justify-between items-center w-full">
          <span className="text-sm font-semibold">Page {currentPage}</span>
          <div className="flex">{renderPaginationButtons}</div>
        </div>
      </CardHeader>
      <CardBody className="p-4 max-h-60 overflow-y-auto">
        <h3 className="text-medium font-semibold mb-2">
          References ({allReferences.length})
        </h3>
        {allReferences.length > 0 ? (
          <div className="space-y-1">{allReferences.map(renderReference)}</div>
        ) : (
          <p className="space-y-1 text-sm text-gray-500">
            No references on this page.
          </p>
        )}
      </CardBody>
    </Card>
  )
}

export default ReferencesOverlay
