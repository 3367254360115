import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import {
  Alert,
  Autocomplete,
  AutocompleteItem,
  Button,
  DatePicker,
  Spacer,
  Switch,
} from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { iTermsOfUse, iTermsOfUseContent } from '@/interfaces/iTermsOfUse.ts'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'
import { ICON_ADD } from '@/constants/icons.tsx'
import ISO6391 from 'iso-639-1'
import CodeEditor from '@components/basic/codeEditor/CodeEditor.tsx'
import { getLocalTimeZone, now, parseAbsoluteToLocal } from '@internationalized/date'

type AssistantLegalSectionProps = {
  value: iAssistant
  isDisabled?: boolean
}
const AssistantLegalSection = ({
  value,
  isDisabled,
}: AssistantLegalSectionProps) => {
  // Store the entire JSON in local state
  const [legalData, setLegalData] = useState<iTermsOfUse>(
    value.termsOfUse ?? {
      version: now(getLocalTimeZone()).toAbsoluteString(),
      content: [],
    },
  )

  useEffect(() => {
    value.termsOfUse = legalData
  }, [legalData])
  /** Add a new row/item */
  const handleAddLanguage = () => {
    setLegalData((prev) => ({
      ...prev,
      content: [
        ...prev.content,
        {
          language: 'new-lang',
          isFallback: false,
          text: '',
        },
      ],
    }))
  }

  /** Remove an item (by index in the array) */
  const handleRemoveLanguage = (index: number) => {
    setLegalData((prev) => ({
      ...prev,
      content: prev.content.filter((_, i) => i !== index),
    }))
  }

  /** Update changes to a single row item */
  const handleUpdateItem = (index: number, updated: Partial<iTermsOfUseContent>) => {
    setLegalData((prev) => {
      const newContent = [...prev.content]
      newContent[index] = { ...newContent[index], ...updated }
      return { ...prev, content: newContent }
    })
  }

  /** Update the version field */
  const handleUpdateVersion = (newVersion: string) => {
    setLegalData((prev) => ({
      ...prev,
      version: newVersion,
    }))
  }

  const languages = ISO6391.getAllCodes().map((code) => ({
    key: code,
    value: ISO6391.getName(code),
  }))

  return (
    <SectionCard title={'Manage Legal Content'}>
      <Alert
        color="warning"
        title={`Provide your own terms and conditions, data protection or other documents for the use of your assistant. The user of your assistant must confirm these once before use.`}
        variant="solid"
      />
      {/* Version Input */}
      <DatePicker
        isReadOnly={isDisabled}
        hideTimeZone
        showMonthAndYearPickers
        className="max-w-[284px]"
        label="Date Version"
        value={parseAbsoluteToLocal(new Date(legalData?.version).toISOString())}
        onChange={(e) => {
          if (e) {
            handleUpdateVersion(e.toAbsoluteString())
          }
        }}
      />

      <Spacer y={1} />

      {/* List of items, each representing a language block */}
      {legalData?.content.map((item, index) => (
        <div
          key={index}
          className="border-b border-default-200 pb-4 mb-4 flex flex-col gap-2"
        >
          <div className="flex items-center gap-4">
            {/* Language */}
            <Autocomplete
              isRequired
              isReadOnly={isDisabled}
              defaultSelectedKey={item.language}
              isClearable={false}
              label="Language"
              className="w-1/2"
              onSelectionChange={(lang) => {
                if (lang) {
                  handleUpdateItem(index, { language: lang as string })
                }
              }}
            >
              {languages.map((lang) => (
                <AutocompleteItem key={lang.key} value={lang.value}>
                  {lang.value}
                </AutocompleteItem>
              ))}
            </Autocomplete>
            <div className="flex flex-1 items-center">
              <Switch
                isReadOnly={isDisabled}
                size="md"
                isSelected={item.isFallback}
                onValueChange={(val) => handleUpdateItem(index, { isFallback: val })}
              />
              <span className="ml-2">Is Fallback</span>
            </div>
            {/* Remove Button */}
            <Button
              isDisabled={isDisabled}
              variant="light"
              color="danger"
              isIconOnly
              onPress={() => handleRemoveLanguage(index)}
            >
              <TrashIcon className="w-4 h-4" />
            </Button>
          </div>

          {/* Text */}
          <CodeEditor
            isDisabled={isDisabled}
            value={item.text}
            onChange={(e) => handleUpdateItem(index, { text: e })}
          ></CodeEditor>
        </div>
      ))}

      {/* Add new language */}
      <Button
        isDisabled={isDisabled}
        startContent={<ICON_ADD />}
        variant="light"
        color="primary"
        onPress={handleAddLanguage}
      >
        Add Another Language
      </Button>
    </SectionCard>
  )
}
export default AssistantLegalSection
