import { createWithEqualityFn } from 'zustand/traditional'
import userService from '../services/UserService.ts'
import { iUser } from '../interfaces/iUser.ts'

interface iUserProfileState {
  isLoading: boolean
  userProfile: iUser | null
  setIsLoading: (isLoading: boolean) => void
  setUserProfile: (userProfile: iUser) => void
  acceptedToUVersion: (resourceId: string) => Promise<void>
  loadUserProfile: () => Promise<iUser>
  updateUserProfileChangelog: (changelogDate: string) => Promise<void>
  updateAssistantLanguage: (assistantLanguage: string) => Promise<void>
}

const useUserProfileStore = createWithEqualityFn<iUserProfileState>(
  (set, getState) => ({
    isLoading: false,
    userProfile: null,
    setUserProfile: (userProfile: iUser) => {
      set(() => ({ userProfile }))
    },

    setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
    loadUserProfile: async () => {
      const user = await userService.getMe()
      getState().setUserProfile(user)
      return user
    },
    acceptedToUVersion: async (resourceId: string) => {
      const date = new Date().toISOString()
      await userService.acceptedToUVersion(date, resourceId)
      const tmpUserProfile = getState().userProfile!
      tmpUserProfile.acceptedToUVersion = {
        ...tmpUserProfile.acceptedToUVersion,
        [resourceId]: date,
      }
      getState().setUserProfile(tmpUserProfile)
    },
    updateUserProfileChangelog: async (changelogDate: string) => {
      const tmpUserProfile = getState().userProfile!
      tmpUserProfile.changelogdate = changelogDate
      getState().setUserProfile(tmpUserProfile)
      await userService.updateUserProfileChangelog(changelogDate)
    },
    updateAssistantLanguage: async (assistantLanguage: string) => {
      const tmpUserProfile = getState().userProfile!
      tmpUserProfile.assistantLanguage = assistantLanguage
      getState().setUserProfile(tmpUserProfile)
      await userService.updateUserProfileAssistantLanguage(assistantLanguage)
    },
  }),
)

export default useUserProfileStore
