import React, { useState, useEffect } from 'react'
import { Divider, Switch } from '@nextui-org/react'
import useChatStore from '@states/chatStore.ts'
import { shallow } from 'zustand/shallow'
import SidebarItem from '../basic/sidenav/SidenavItem.tsx'
import SideNav from '../basic/sidenav/Sidenav.tsx'
import useMainStore from '../../states/mainState.ts'
import { Popup } from '@/enums/PopupTypes.ts'
import SidenavDetailContent from '../basic/sidenav/SidenavDetailContent.tsx'
import { ICON_ARTIFACT, ICON_DELETE, ICON_REFRESH } from '@/constants/icons.tsx'
import ChatDocumentsView from '@components/session/sessionChatControlViews/documents/ChatDocumentsView.tsx'
import SessionGeneralView from '@components/session/sessionChatControlViews/SessionGeneralView.tsx'
import SessionSharingView from '@components/session/sessionChatControlViews/SessionSharingView.tsx'
import IconFetcher from '@components/basic/icon/IconFetcher.tsx'
import { iReference } from '@/interfaces/iReference.ts'

const ChatControl = () => {
  const {
    session,
    assistant,
    clearChatMessages,
    isChatControlContentPinned,
    isChatControlContentOpen,
    setIsChatControlContentPinned,
    setIsChatControlContentOpen,
    isAssistantGenerating,
    chatControlContentRoute,
    setChatControlContentRoute,
    activateChatSession,
    inheritedAssistant,
  } = useChatStore(
    (state) => ({
      clearChatMessages: state.clearChatMessages,
      isChatControlContentPinned: state.isChatControlContentPinned,
      isChatControlContentOpen: state.isChatControlContentOpen,
      setIsChatControlContentOpen: state.setIsChatControlContentOpen,
      setIsChatControlContentPinned: state.setIsChatControlContentPinned,
      isAssistantGenerating: state.isAssistantGenerating,
      session: state.session!,
      chatControlContentRoute: state.chatControlContentRoute,
      setChatControlContentRoute: state.setChatControlContentRoute,
      assistant: state.assistant,
      activateChatSession: state.activateChatSession,
      inheritedAssistant: state.inheritedAssistant,
    }),
    shallow,
  )

  const [docRefs, setDocRefs] = useState<iReference[]>([])
  const [readDocRefs, setReadDocRefs] = useState<iReference[]>([])
  const [unreadDocCount, setUnreadDocCount] = useState(0)
  const [autoOpenReferences, setAutoOpenReferences] = useState(true)
  const [routePinnedStates, setRoutePinnedStates] = useState<
    Record<string, boolean>
  >({})

  const setPopup = useMainStore((state) => state.setPopup)

  useEffect(() => {
    const allRefs: iReference[] =
      session.documentChunks?.flatMap((chunk) => chunk.references) || []
    setDocRefs(allRefs)
  }, [session.documentChunks])

  // if the user disables auto-open, close the chat control content
  useEffect(() => {
    if (!autoOpenReferences) {
      setIsChatControlContentOpen(false)
      setReadDocRefs((prev) => [...prev, ...docRefs])
      setIsChatControlContentPinned(false)
    }
  }, [autoOpenReferences])

  // Count unread references
  useEffect(() => {
    const unreadRefs = docRefs.filter(
      (ref) =>
        !readDocRefs.some(
          (r) =>
            r.messageId === ref.messageId &&
            r.timestamp === ref.timestamp &&
            r.description === ref.description,
        ),
    )
    setUnreadDocCount(unreadRefs.length)
  }, [docRefs, readDocRefs])

  // Only auto-open if not already open on "artifacts"
  useEffect(() => {
    if (unreadDocCount > 0 && autoOpenReferences && !isChatControlContentOpen) {
      openSessionDetailContent('artifacts')
      setIsChatControlContentPinned(true)
      setIsChatControlContentOpen(true)
    }
  }, [
    docRefs,
    autoOpenReferences,
    isChatControlContentOpen,
    chatControlContentRoute?.contentName,
  ])

  const handleClearSession = async () => {
    setPopup(Popup.SessionFeedback, {
      onClear: async () => {
        setChatControlContentRoute('overview')
        setIsChatControlContentOpen(false)
        setIsChatControlContentPinned(false)
        await clearChatMessages()
      },
    })
  }

  // Avoid re-toggling if we're already on the same content route
  const openSessionDetailContent = (contentType: string) => {
    if (chatControlContentRoute?.contentName === contentType) {
      if (isChatControlContentPinned) {
        setIsChatControlContentPinned(false)
        setIsChatControlContentOpen(false)
      }
      setIsChatControlContentOpen(!isChatControlContentOpen)
    } else {
      setChatControlContentRoute(contentType)
      setIsChatControlContentPinned(routePinnedStates[contentType] ?? false)
      setIsChatControlContentOpen(true)
    }
  }

  const handlePinnedUpdate = (pinned: boolean) => {
    setIsChatControlContentPinned(pinned)
    if (chatControlContentRoute?.contentName) {
      setRoutePinnedStates((prev) => ({
        ...prev,
        [chatControlContentRoute.contentName!]: pinned,
      }))
    }
  }

  return (
    <SideNav
      open={true}
      openDetailContent={isChatControlContentOpen}
      pinnedDetailContent={isChatControlContentPinned}
      right
      contentWidth={
        ['general', 'sharing'].includes(chatControlContentRoute?.contentName ?? '')
          ? '400px'
          : chatControlContentRoute?.contentName === 'canvasContent'
            ? '1000px'
            : chatControlContentRoute?.contentName === 'artifacts' &&
                chatControlContentRoute?.route === 'Detail'
              ? '700px'
              : '400px'
      }
      onUpdatePinned={handlePinnedUpdate}
      onOpenDetailContent={setIsChatControlContentOpen}
      header={<></>}
      detailContent={
        <SidenavDetailContent right title="">
          {chatControlContentRoute?.contentName === 'general' && (
            <SessionGeneralView />
          )}
          {chatControlContentRoute?.contentName === 'canvas' && <div />}
          {chatControlContentRoute?.contentName === 'artifacts' && (
            <ChatDocumentsView
              routeParams={{
                route: chatControlContentRoute.route,
                value: chatControlContentRoute.value,
              }}
            />
          )}
          {chatControlContentRoute?.contentName === 'sharing' && (
            <SessionSharingView session={session} />
          )}
          {chatControlContentRoute?.contentName === 'canvasContent' && <div />}
        </SidenavDetailContent>
      }
      footer={
        <>
          <Divider className={'mb-2'} />
          <SidebarItem
            tooltip="Auto Open References"
            color={'transparent'}
            icon={
              <Switch
                size={'sm'}
                isSelected={autoOpenReferences}
                onValueChange={() => setAutoOpenReferences(!autoOpenReferences)}
              />
            }
          />
          <SidebarItem
            tooltip="Refresh chat"
            onClick={() => activateChatSession(session)}
            icon={<ICON_REFRESH />}
          />
          <SidebarItem
            tooltip="Clear chat"
            isDisabled={isAssistantGenerating}
            color="danger"
            onClick={handleClearSession}
            icon={<ICON_DELETE />}
          />
        </>
      }
    >
      <SidebarItem
        tooltip="Inspect Session"
        onClick={() => openSessionDetailContent('general')}
        icon={
          <IconFetcher
            entityId={(inheritedAssistant ?? assistant)?.id}
            entityType="Assistant"
            imageId={(inheritedAssistant ?? assistant)?.image}
          />
        }
      />
      <Divider className={'my-2'} />
      {unreadDocCount > 0 && !autoOpenReferences ? (
        <SidebarItem
          tooltip="Inspect Documents"
          badgeContent={unreadDocCount}
          isActive={chatControlContentRoute?.contentName === 'artifacts'}
          onClick={() => {
            setReadDocRefs(docRefs)
            openSessionDetailContent('artifacts')
          }}
          icon={<ICON_ARTIFACT />}
        />
      ) : (
        <SidebarItem
          tooltip="Inspect Documents"
          isActive={chatControlContentRoute?.contentName === 'artifacts'}
          onClick={() => {
            setReadDocRefs(docRefs)
            openSessionDetailContent('artifacts')
          }}
          icon={<ICON_ARTIFACT />}
        />
      )}
    </SideNav>
  )
}

export default ChatControl
