import React, { useEffect, useState } from 'react'
import { Card, CardHeader, Spinner } from '@nextui-org/react'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import useSessionStore from '@states/sessionStore.ts'
import { shallow } from 'zustand/shallow'
import useFolderStore from '@states/folderStore.ts'
import useTeamStore from '@/states/teamStore.ts'
import useChatStore from '@/states/chatStore.ts'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { iSession } from '@/interfaces/iSession.ts'
import { iFolder } from '@/interfaces/iFolder.ts'
import { iTeam } from '@/interfaces/iTeam.ts'
import AssistantService from '@/services/assistantService.ts'

type CreateSessionButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  selectedTeam: iTeam | string
  folder?: iFolder
  description?: string
  defaultFolderName?: string
  assistantId?: string
  loading?: boolean
  hideIcon?: boolean
  hideDescription?: boolean
  onCreated?: (session: iSession) => void
  onBeforeCreateSession?: () => Promise<void>
}

function CreateSessionButton({
  folder,
  selectedTeam,
  loading,
  description = 'Create a new session',
  defaultFolderName,
  assistantId,
  onCreated,
  onBeforeCreateSession,
  hideIcon,
  hideDescription,
}: CreateSessionButtonProps) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(loading ?? false)
  useEffect(() => {
    setIsLoading(loading ?? false)
  }, [loading])
  const { addSession, updateSession } = useSessionStore(
    (state) => ({
      addSession: state.createSession,
      updateSession: state.updateSession,
    }),
    shallow,
  )

  const { activeSession, changeAssistant } = useChatStore(
    (state) => ({
      activeSession: state.session,
      changeAssistant: state.changeAssistant,
    }),
    shallow,
  )

  const { addFolder, folders } = useFolderStore(
    (state) => ({
      addFolder: state.addFolder,
      folders: state.folders,
    }),
    shallow,
  )
  const { setSelectedTeamByString } = useTeamStore(
    (state) => ({
      setSelectedTeamByString: state.setSelectedTeamByString,
    }),
    shallow,
  )

  const createSession = async () => {
    if (isLoading) return
    setIsLoading(true)
    if (onBeforeCreateSession) await onBeforeCreateSession()
    const changeToTeam = typeof selectedTeam === 'string'
    const session: iSession = {
      assistantId: '',
      folderId: folder?.id ?? activeSession?.folderId,
      teamId: typeof selectedTeam === 'string' ? selectedTeam : selectedTeam!.id!,
      title: 'New Session',
      _isActive: false,
      automaticTitle: true,
    }
    if (changeToTeam) {
      await setSelectedTeamByString(selectedTeam)
    }
    if (defaultFolderName) {
      const checkFolderNameAlreadyExists = folders.find(
        (folder) => folder.title === defaultFolderName,
      )
      if (checkFolderNameAlreadyExists) {
        session.folderId = checkFolderNameAlreadyExists!.id
      } else {
        const defaultFolder = {
          title: defaultFolderName,
          teamId:
            typeof selectedTeam === 'string' ? selectedTeam : selectedTeam!.id!,
        }
        // creat new folder for session
        return addFolder(defaultFolder, true)
          .then((folder) => {
            session.folderId = folder!.id
            return addSession(session!)
          })
          .then(async (session) => {
            if (assistantId) {
              const assistant = await AssistantService.getAssistant(assistantId)
              await changeAssistant(assistant)
            }
            onCreated && onCreated(session!)
            return updateSession(session!)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }

    addSession(session!)
      .then(async (s) => {
        if (assistantId) {
          const assistant = await AssistantService.getAssistant(assistantId)
          await changeAssistant(assistant)
        }
        navigate({
          pathname: '/',
          search: createSearchParams({
            sessionId: s!.id!,
          }).toString(),
        })
        onCreated && onCreated(s!)
        return updateSession(s!)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Card
      className="border-dashed w-full border-2 bg-background/40"
      shadow={'none'}
      isDisabled={isLoading}
      isPressable={!isLoading}
      isHoverable={!isLoading}
      onPress={async () => await createSession()}
    >
      <CardHeader className="flex gap-3">
        {!hideIcon &&
          (isLoading ? <Spinner></Spinner> : <PlusCircleIcon className="w-8 h-8" />)}

        <div className="flex flex-col">
          <p className="text-start text-md">New session</p>
          {!hideDescription && (
            <p className="text-small text-default-500">{description}</p>
          )}
        </div>
      </CardHeader>
    </Card>
  )
}

export default CreateSessionButton
