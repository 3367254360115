import React from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  Input,
  Checkbox,
} from '@nextui-org/react'

interface GenerateYamlTemplateModalProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void

  yamlTemplatePrompt: string
  setYamlTemplatePrompt: (value: string) => void

  endpointInfo: string
  setEndpointInfo: (value: string) => void

  includeExistingTemplate: boolean
  setIncludeExistingTemplate: (value: boolean) => void

  openApiFileContent: string
  setOpenApiFileContent: (content: string) => void

  isLoading: boolean
  onGenerate: () => void
}

/**
 * Modal for generating a YAML template via assistant.
 */
const GenerateYamlTemplateModal: React.FC<GenerateYamlTemplateModalProps> = ({
  isOpen,
  onOpenChange,
  yamlTemplatePrompt,
  setYamlTemplatePrompt,
  endpointInfo,
  setEndpointInfo,
  includeExistingTemplate,
  setIncludeExistingTemplate,
  openApiFileContent,
  setOpenApiFileContent,
  isLoading,
  onGenerate,
}) => {
  const handleOpenApiFileUpload = async (file: File) => {
    const fileContent = await file.text()
    setOpenApiFileContent(fileContent)
  }

  return (
    <Modal
      isDismissable={false}
      isKeyboardDismissDisabled={true}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Generate Template
            </ModalHeader>
            <ModalBody>
              <Textarea
                label="Describe what you want to achieve"
                value={yamlTemplatePrompt}
                onChange={(e) => setYamlTemplatePrompt(e.target.value)}
                placeholder="Enter your description here"
                rows={4}
              />
              <Textarea
                label="Endpoint Information"
                value={endpointInfo}
                onChange={(e) => setEndpointInfo(e.target.value)}
                placeholder="Copy-paste endpoint info here"
                rows={4}
              />
              <div className="mt-4">
                <Input
                  type="file"
                  label="Upload OpenAPI JSON/YAML File"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      handleOpenApiFileUpload(e.target.files[0])
                    }
                  }}
                  accept=".json,.yaml,.yml"
                />
              </div>
              <Checkbox
                className="mt-4"
                isSelected={includeExistingTemplate}
                onValueChange={setIncludeExistingTemplate}
              >
                Include existing template for modifications
              </Checkbox>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                variant="light"
                onPress={() => {
                  // Reset fields
                  setYamlTemplatePrompt('')
                  setEndpointInfo('')
                  setIncludeExistingTemplate(false)
                  setOpenApiFileContent('')
                  onClose()
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                isLoading={isLoading}
                onPress={() => {
                  onGenerate()
                  onClose()
                }}
              >
                Generate
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default GenerateYamlTemplateModal
