import { useState } from 'react'
import { Card, Accordion, AccordionItem, Divider } from '@nextui-org/react'
import { iToolCallHttpContext } from '@/interfaces/iMessage.ts'
import JsonDisplay from '@components/basic/jsonViewer/JSONDisplay.tsx'

type HttpToolCallProps = {
  httpContext: iToolCallHttpContext
}

const HttpToolCall = ({ httpContext }: HttpToolCallProps) => {
  const [context] = useState(httpContext)

  return (
    <Card className="mt-4 p-4 bg-default">
      {context.requestCompileError && (
        <div>
          <h3 className="text-xl text-primary mb-2">Request Compile Error</h3>
          <pre className="bg-danger-100 dark:bg-danger-900 p-2 rounded max-h-96 overflow-auto">
            {context.requestCompileError}
          </pre>
        </div>
      )}
      {context.request && (
        <>
          <h3 className="mt-2 text-xl text-primary mb-2">Request</h3>
          <div className="ml-4">
            <p className="mt-1 text-lg text-primary font-mono">
              {context.request.method} {context.request.url}
            </p>
            <Accordion>
              <AccordionItem key="headers" aria-label="Headers" title="Headers">
                <div className="max-h-96 overflow-auto">
                  {[
                    ...Object.entries(context.request.headers).map(
                      ([key, value]) => (
                        <div className="pl-4 text-base" key={key}>
                          <span className="font-bold text-primary">{key}:</span>
                          <span className="text-foreground-500">{value}</span>
                        </div>
                      ),
                    ),
                  ]}
                </div>
              </AccordionItem>
              <AccordionItem key="body" aria-label="Body" title="Body">
                <JsonDisplay jsonString={context.request.body} />
              </AccordionItem>
            </Accordion>
          </div>
        </>
      )}
      {context.requestError && (
        <p className="mt-1 text-danger">Request Error: {context.requestError}</p>
      )}
      {context.response && (
        <div>
          <h3 className="mt-2 text-xl text-primary mb-2">Response</h3>
          <Divider className="my-2" />
          <div className="mt-1 ml-4">
            <p className="text-lg text-primary font-mono">
              Status Code:
              <span
                className={context.responseError ? 'text-danger' : 'text-success'}
              >
                {context.response.statusCode}
              </span>
            </p>
            <Accordion>
              <AccordionItem key="headers" aria-label="Headers" title="Headers">
                <div className="max-h-96 overflow-auto">
                  {Object.entries(context.response.headers).map(([key, value]) => (
                    <div className="pl-4 text-base" key={key}>
                      <span className="font-bold text-primary">{key}:</span>
                      <span className="text-foreground-500">{value}</span>
                    </div>
                  ))}
                </div>
              </AccordionItem>
              <AccordionItem key="body" aria-label="Body" title="Body">
                <JsonDisplay
                  jsonString={context.responseError || context.response.body}
                />
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      )}
    </Card>
  )
}

export default HttpToolCall
