import React, { Key, useMemo } from 'react'
import {
  Switch,
  Select,
  SelectItem,
  SharedSelection,
  ListboxItem,
  Listbox,
  Input,
  Autocomplete,
  AutocompleteItem,
  Alert,
} from '@nextui-org/react'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import UserProfile from '@components/user/UserProfile.tsx'
import { useTranslation } from 'react-i18next'
import SkeletonDetailPage from '@components/skeletons/SkeletonDetailPage.tsx'
import useUserProfileStore from '@states/userProfileState.ts'
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import { useTheme } from 'next-themes'
import ISO6391, { LanguageCode } from 'iso-639-1'

const UserSettings = () => {
  return (
    <DefaultPageLayout
      title="User Settings"
      editAble={false}
      headerContent={<UserProfile />}
      tabs={[
        { title: 'General', key: 'general' },
        { title: 'Privacy', key: 'privacy' },
      ]}
    >
      {(selectedTab) => {
        switch (selectedTab) {
          case 'general':
            return <AccountSettings />
          case 'privacy':
            return <PrivacySettings />
          default:
            return <></>
        }
      }}
    </DefaultPageLayout>
  )
}

const assistantLanguages = () => {
  const codes = ISO6391.getAllCodes().map((code) => ({
    key: code,
    value: ISO6391.getName(code),
  }))
  codes.push({
    key: 'Default' as LanguageCode,
    value: 'Default',
  })
  return codes
}

const AccountSettings: React.FC = () => {
  const { i18n } = useTranslation()
  const [value, setValue] = React.useState<SharedSelection>(new Set([i18n.language]))
  const userProfile = useUserProfileStore().userProfile
  const updateAssistantLanguage = useUserProfileStore().updateAssistantLanguage

  const { theme, setTheme } = useTheme()

  const changeAssistantLanguageHandler = async (lang: Key) => {
    await updateAssistantLanguage(lang as string)
  }
  const changeLanguageHandler = (lang: SharedSelection) => {
    const langValue = lang[Symbol.iterator]().next().value
    i18n
      .changeLanguage(langValue as string)
      .then((d) => console.log('finished :', d))
  }

  if (!userProfile) {
    return <SkeletonDetailPage />
  }

  return (
    <div className="space-y-4 max-w-4xl">
      <div className="flex flex-col gap-4 mt-4">
        <SectionCard
          className="min-w-96 "
          title="Personal Information"
          description="Your account details"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input readOnly label="Name" value={userProfile.name || ''} />
            <Input readOnly label="Email" value={userProfile.email || ''} />
            <Input
              readOnly
              label="Department"
              value={userProfile.department || ''}
            />
            <Input readOnly label="Company" value={userProfile.companyName || ''} />
            <Input
              readOnly
              label="Cost Center"
              value={userProfile.costCenterId || ''}
            />
            <Input readOnly label="Country" value={userProfile.country || ''} />
          </div>
        </SectionCard>
      </div>

      <div className="flex flex-col gap-4 mt-4">
        <SectionCard
          className="min-w-96 "
          title="App Settings"
          description="Customize your app experience"
        >
          <div className="flex flex-col gap-4">
            <Select
              selectedKeys={value}
              isDisabled
              label="UI-Language"
              onSelectionChange={(lang) => {
                setValue(lang)
                changeLanguageHandler(lang)
              }}
            >
              <SelectItem key="en-US" value="en">
                English
              </SelectItem>
              <SelectItem key="de" value="de">
                German
              </SelectItem>
              <SelectItem key="fr" value="fr">
                French
              </SelectItem>
            </Select>
            <Autocomplete
              defaultSelectedKey={userProfile.assistantLanguage}
              isClearable={false}
              label="Assistant-Language"
              onSelectionChange={(lang) => {
                if (lang) {
                  changeAssistantLanguageHandler(lang)
                }
              }}
            >
              {assistantLanguages().map((lang) => (
                <AutocompleteItem key={lang.value} value={lang.value}>
                  {lang.value}
                </AutocompleteItem>
              ))}
            </Autocomplete>
            <Alert
              variant="solid"
              title={'Assistant-Language'}
              description={
                "The assistant language setting allows the model to respond in your chosen language. If you select 'Default,' the model will reply in the language of your prompt/question. For example, if you ask a question in English, the response will also be in English."
              }
            ></Alert>
            <div className="flex items-center gap-4">
              <Switch
                isSelected={theme === 'dark'}
                onChange={() => setTheme(theme === 'light' ? 'dark' : 'light')}
                size="lg"
                startContent={<SunIcon className="h-5 w-5 text-yellow-500" />}
                endContent={<MoonIcon className="h-5 w-5 text-gray-600" />}
              />
              <label className="text-default-500">
                {theme === 'light' ? 'Light Mode' : 'Dark Mode'}
              </label>
            </div>
          </div>
        </SectionCard>
      </div>
    </div>
  )
}

import legalJSON from '../../public/legal/legal.json'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'

const PrivacySettings: React.FC = () => {
  const fileName = `./legal/${
    legalJSON.termsOfUse.find(
      (tou: { lang: string; fileName: string }) => tou.lang === 'en',
    )?.fileName
  }`

  return (
    <div className="space-y-6 max-w-4xl">
      <SectionCard
        className="w-full"
        title="Privacy Settings"
        description=" Manage your privacy preferences"
      >
        <div className="flex flex-col gap-4">
          <Switch
            isDisabled={true}
            defaultSelected={false}
            disabled
            title="Receive notifications"
          >
            <p className="text-default-500">
              Receive notifications about new Assistants, Knowledge Containers,
              Tools, and more
            </p>
          </Switch>
        </div>
      </SectionCard>

      <SectionCard
        className="w-full"
        title="Legal Documents"
        description="Review our terms and policies"
      >
        <Listbox
          aria-label="Legal Documents"
          className="p-0 gap-0 divide-y divide-default-300/50 dark:divide-default-100/80 bg-content1 max-w-[300px] overflow-visible shadow-small rounded-medium"
          itemClasses={{
            base: 'px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80',
          }}
        >
          <ListboxItem key="terms" href={fileName} target="_blank">
            Terms of Use
          </ListboxItem>
          {/*<ListboxItem key="privacy" href="/privacy-policy">*/}
          {/*  Privacy Policy*/}
          {/*</ListboxItem>*/}
          {/*<ListboxItem key="cookies" href="/cookie-policy">*/}
          {/*  Cookie Policy*/}
          {/*</ListboxItem>*/}
        </Listbox>
      </SectionCard>
    </div>
  )
}

export default UserSettings
