import React, { useState } from 'react'
import {
  Input,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from '@nextui-org/react'
import { ICON_DELETE } from '@/constants/icons.tsx'

type AssistantQuickPromptsProps = {
  prompts: string[]
  onPromptsChange: (prompts: string[]) => void
  isDisabled?: boolean
}

const AssistantQuickPrompts = ({
  prompts,
  onPromptsChange,
  isDisabled,
}: AssistantQuickPromptsProps) => {
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [editText, setEditText] = useState('')
  const [newPrompt, setNewPrompt] = useState('')

  const handleEditPrompt = (index: number) => {
    setEditingIndex(index)
    setEditText(prompts[index])
  }

  const handleChangeEditText = (value: string) => {
    setEditText(value)
  }

  const handleSavePrompt = () => {
    if (editText.trim() !== '') {
      const newPrompts = [...prompts]
      newPrompts[editingIndex!] = editText.trim()
      onPromptsChange(newPrompts)
    }
    setEditingIndex(null)
    setEditText('')
  }

  const handleDeletePrompt = (index: number) => {
    const newPrompts = prompts.filter((_, i) => i !== index)
    onPromptsChange(newPrompts)
    if (editingIndex === index) {
      setEditingIndex(null)
      setEditText('')
    }
  }

  const handleAddPrompt = () => {
    if (newPrompt.trim() !== '') {
      onPromptsChange([...prompts, newPrompt.trim()])
      setNewPrompt('')
    }
  }

  return (
    <>
      <Table aria-label="Quick Prompts">
        <TableHeader>
          <TableColumn>Prompt</TableColumn>
          <TableColumn width={80}> </TableColumn>
        </TableHeader>
        <TableBody emptyContent={'No prompts available'}>
          {prompts.map((prompt, index) => (
            <TableRow key={index} className="hover:bg-default transition-colors">
              <TableCell>
                {editingIndex === index ? (
                  <Input
                    readOnly={isDisabled}
                    value={editText}
                    onChange={(e) => handleChangeEditText(e.target.value)}
                    onBlur={handleSavePrompt}
                    autoFocus
                    className="w-full"
                  />
                ) : (
                  <span
                    className={`cursor-pointer ${isDisabled ? 'text-default-500' : 'text-default-900'}`}
                    onClick={() => !isDisabled && handleEditPrompt(index)}
                  >
                    {prompt}
                  </span>
                )}
              </TableCell>
              <TableCell>
                <Button
                  isIconOnly
                  isDisabled={isDisabled}
                  color="danger"
                  variant="light"
                  onPress={() => handleDeletePrompt(index)}
                  aria-label="Delete prompt"
                >
                  <ICON_DELETE />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="mt-4">
        <h4 className="text-lg font-semibold mb-2">Add New Prompt</h4>
        <div className="flex gap-2">
          <Input
            isDisabled={isDisabled}
            value={newPrompt}
            onChange={(e) => setNewPrompt(e.target.value)}
            placeholder="Enter a new prompt"
            className="flex-grow"
          />
          <Button color="primary" onPress={handleAddPrompt} isDisabled={isDisabled}>
            Add
          </Button>
        </div>
      </div>
    </>
  )
}
export default AssistantQuickPrompts
