import React from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react'
import CodeEditor from '@components/basic/codeEditor/CodeEditor'
import ParameterFields from './ParameterFields'

interface Parameter {
  name: string
  parameter: {
    enum?: string[]
    required?: boolean
  }
}

interface TestToolModalProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  parameters: Parameter[]
  parameterValues: Record<string, any>
  onParameterChange: (name: string, value: any) => void

  testToolResponse: any
  testToolError: string | null

  isLoadingTestTool: boolean

  onSubmit: () => void
  onReset: () => void
}

/**
 * Modal for testing the tool with parameters; displays response/error.
 */
const TestToolModal: React.FC<TestToolModalProps> = ({
  isOpen,
  onOpenChange,
  parameters,
  parameterValues,
  onParameterChange,
  testToolResponse,
  testToolError,
  isLoadingTestTool,
  onSubmit,
  onReset,
}) => {
  return (
    <Modal
      isDismissable={false}
      isKeyboardDismissDisabled={true}
      isOpen={isOpen}
      onOpenChange={(open) => {
        if (!open) onReset()
        onOpenChange(open)
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Enter Parameters
            </ModalHeader>
            <ModalBody>
              <ParameterFields
                parameters={parameters}
                parameterValues={parameterValues}
                onParameterChange={onParameterChange}
              />
              {testToolResponse && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold">Response:</h3>
                  <CodeEditor
                    isDisabled={true}
                    value={JSON.stringify(testToolResponse, null, 2)}
                    language="json"
                  />
                </div>
              )}
              {testToolError && (
                <div className="mt-4 text-red-500">
                  <h3 className="text-lg font-semibold">Error:</h3>
                  <p>{testToolError}</p>
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                variant="light"
                onPress={() => {
                  onReset()
                  onClose()
                }}
              >
                Close
              </Button>
              <Button
                color="primary"
                isLoading={isLoadingTestTool}
                onPress={onSubmit}
              >
                Submit
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default TestToolModal
