import React, {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { shallow } from 'zustand/shallow'
import { toast } from 'react-toastify'
import YAML from 'js-yaml'
import { XMLParser } from 'fast-xml-parser'

import { deepCopy } from '@/utils/deepCopy'
import { getFAQById } from '@/utils/getLocalFaqs'
import { FAQ_ID_TOOLVIEW } from '@/constants/faqIDs'
import { iTool, ExecutionEnvironment } from '@/interfaces/iTool'
import { iShared } from '@/interfaces/iShared'
import { ShareType } from '@/interfaces/iItem'
import { OverridableParameterSet } from '@/interfaces/iOverridableParameter'
import { iHttpTemplate } from '@/interfaces/iHttpParameter'
import ToolService from '../../services/toolService'
import AssistantService from '@/services/assistantService'
import useToolStore from '@states/toolStore'
import {
  useDisclosure,
  Button,
  Input,
  Tab,
  Tabs,
  Textarea,
  Switch,
  Card,
  CardHeader,
  CardBody,
  Select,
  SelectItem,
} from '@nextui-org/react'

import HttpTemplateEditor from './httpTemplates/HttpTemplateEditor'
import OverridableEditor from './OverridableSetEditor'
import ParameterModal from './ParameterModal'
import TestToolModal from './TestToolModal'
import GenerateTemplateModal from './GenerateTemplateModal'
import GenerateYamlTemplateModal from './GenerateYamlTemplateModal'
import MembersSelection from '../members/MemberSelection'
import ShareWithSelection from '@components/basic/shareWithSelection/ShareWithSelection'
import SectionCard from '@components/basic/sectionCard/SectionCard'
import CodeEditor from '@components/basic/codeEditor/CodeEditor'
import Markdown from '@components/markdown/Markdown'
import FaqItem from '@components/faq/FaqItem'
import { renderHandlebarsTemplate } from '@components/tools/HandlebarsRenderer.ts'
import IconManager from '@components/basic/icon/IconManager.tsx'
import { ICON_ASSISTANT } from '@/constants/icons.tsx'

export type ToolDetailViewHandler = {
  deleteTool: () => Promise<void>
  saveTool: () => Promise<iTool | undefined>
}

type ToolDetailViewProps = {
  value: iTool
  isDisabled?: boolean
}

/**
 * Recursively gets all paths of a JSON/XML object for auto-complete usage.
 */
function getAllPaths(obj: any, prefix = ''): string[] {
  if (obj === null || typeof obj !== 'object') {
    return prefix ? [prefix] : []
  }
  const paths: string[] = []
  if (Array.isArray(obj) && obj.length > 0) {
    const arrayPrefix = prefix ? prefix + '[*]' : '[*]'
    paths.push(arrayPrefix)
    const subPaths = getAllPaths(obj[0], '')
    for (const sp of subPaths) {
      paths.push(arrayPrefix + (sp ? '.' + sp : ''))
    }
  } else {
    // we only need array paths
    //for (const key of Object.keys(obj)) {
    //  const val = obj[key]
    //  const newPrefix = prefix ? prefix + '.' + key : key
    //  paths.push(...getAllPaths(val, newPrefix))
    //}
  }
  return paths
}

const ToolDetailView: ForwardRefRenderFunction<
  ToolDetailViewHandler,
  ToolDetailViewProps
> = ({ value, isDisabled }: ToolDetailViewProps, ref) => {
  const {
    createTool,
    deleteTool: deleteToolStore,
    updateTool,
  } = useToolStore(
    (state) => ({
      createTool: state.createTool,
      deleteTool: state.deleteTool,
      updateTool: state.updateTool,
    }),
    shallow,
  )

  const [tool, setTool] = useState<iTool>({ ...value })
  const [parsedData, setParsedData] = useState<any>(null)
  const [renderedMarkdown, setRenderedMarkdown] = useState<string>('')

  // Share / Roles
  const [shared, setShared] = useState<iShared[]>([])
  const [sharedLimited, setSharedLimited] = useState<iShared[]>([])
  const [sharedPublic, setSharedPublic] = useState<iShared[]>([])

  // HTTP or Local
  const [isHttpTool, setIsHttpTool] = useState<boolean>(true)
  const [httpTemplate, setHttpTemplate] = useState<iHttpTemplate>(
    value?.httpTemplate || {
      url: '',
      method: 'auto',
      constants: {},
      parameters: {},
    },
  )

  // Overridable
  const [overridables, setOverridables] = useState<OverridableParameterSet>(
    tool.overridableParameters || {},
  )

  const [responseExample, setResponseExample] = useState<string>(
    tool.responseExample || '',
  )
  const [markdownTemplate, setMarkdownTemplate] = useState<string>(
    tool.markdownTemplate || '',
  )
  const [allPaths, setAllPaths] = useState<string[]>([])
  const [referenceTagging, setReferenceTagging] = useState<boolean>(
    tool.referenceTagging || false,
  )
  const [refIdPath, setRefIdPath] = useState<string>(value.refIdPath || '')

  // YAML vs Editor
  const [isYamlView, setIsYamlView] = useState<boolean>(false)
  const [yamlContent, setYamlContent] = useState<string>('')

  // Loading and unsaved states
  const [isLoading, setIsLoading] = useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  // Parameter values
  const [parameterValues, setParameterValues] = useState<{ [key: string]: any }>({})

  // Test response
  const [isLoadingTestResponse, setIsLoadingTestResponse] = useState(false)

  // Test Tool modal
  const [testToolResponse, setTestToolResponse] = useState<any>(null)
  const [testToolError, setTestToolError] = useState<string | null>(null)
  const [isLoadingTestTool, setIsLoadingTestTool] = useState(false)

  // Generate Handlebars Template
  const [templatePrompt, setTemplatePrompt] = useState<string>('')
  const [isLoadingTemplateGeneration, setIsLoadingTemplateGeneration] =
    useState(false)
  const [includeExistingTemplate, setIncludeExistingTemplate] =
    useState<boolean>(false)

  // Generate YAML Template
  const [yamlTemplatePrompt, setYamlTemplatePrompt] = useState<string>('')
  const [endpointInfo, setEndpointInfo] = useState<string>('')
  const [isLoadingYamlTemplateGeneration, setIsLoadingYamlTemplateGeneration] =
    useState(false)
  const [openApiFileContent, setOpenApiFileContent] = useState<string>('')

  // FAQ
  const toolviewFAQ = getFAQById(FAQ_ID_TOOLVIEW)

  // Disclosure hooks
  const {
    isOpen: isParameterModalOpen,
    onOpen: onOpenParameterModal,
    onOpenChange: onParameterModalOpenChange,
  } = useDisclosure({ defaultOpen: false })

  const {
    isOpen: isGenerateTemplateModalOpen,
    onOpen: onOpenGenerateTemplateModal,
    onOpenChange: onGenerateTemplateModalOpenChange,
  } = useDisclosure({ defaultOpen: false })

  const {
    isOpen: isGenerateYamlTemplateModalOpen,
    onOpen: onOpenGenerateYamlTemplateModal,
    onOpenChange: onGenerateYamlTemplateModalOpenChange,
  } = useDisclosure({ defaultOpen: false })

  const {
    isOpen: isTestToolModalOpen,
    onOpen: onOpenTestToolModal,
    onOpenChange: onTestToolModalOpenChange,
  } = useDisclosure({ defaultOpen: false })

  useImperativeHandle(ref, () => ({
    deleteTool,
    saveTool,
  }))

  useEffect(() => {
    setTool(value)
    setIsHttpTool(!!value.httpTemplate)
    if (!value.shared) {
      setShared([])
      setTool((prev) => ({ ...prev, shared: [] }))
    } else {
      setShared(value.shared!)
      setTool((prev) => ({ ...prev, shared: value.shared! }))
    }
    setResponseExample(value.responseExample || '')
    setMarkdownTemplate(value.markdownTemplate || '')
    setReferenceTagging(value.citationRequired || false)
  }, [value])

  useEffect(() => {
    setTool((prevTool) => ({ ...prevTool, citationIdPath: refIdPath }))
  }, [refIdPath])

  useEffect(() => {
    setTool((prevTool) => ({ ...prevTool, citationRequired: referenceTagging }))
  }, [referenceTagging])

  useEffect(() => {
    if (isHttpTool) {
      setTool({
        ...tool,
        httpTemplate,
        executionEnvironment: ExecutionEnvironment.ExternalHttp,
      })
    } else {
      setTool({
        ...tool,
        httpTemplate: undefined,
        executionEnvironment: ExecutionEnvironment.Local,
      })
    }
  }, [isHttpTool])

  useEffect(() => {
    if (value.httpTemplate && value.httpTemplate.parameters) {
      const initialValues: { [key: string]: any } = {}
      Object.keys(value.httpTemplate.parameters).forEach((p) => {
        initialValues[p] = ''
      })
      setParameterValues(initialValues)
    }
  }, [value])

  const ensureHttpTemplateDefaults = (template: iHttpTemplate): iHttpTemplate => ({
    url: template.url || '',
    method: template.method || 'auto',
    constants: template.constants || {},
    parameters: template.parameters || {},
  })

  const deleteTool = async () => {
    setIsLoading(true)
    const confirmed = window.confirm(
      'Are you sure you want to delete this Tool? This action cannot be undone.',
    )
    if (!confirmed) {
      setIsLoading(false)
      return
    }
    return deleteToolStore(value.id!)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const saveTool = async () => {
    try {
      if (referenceTagging && !refIdPath) {
        toast.error('Please set a citationIdPath.')
        return
      }
      if (isYamlView) {
        try {
          const parsedYaml = YAML.load(yamlContent)
          const updatedHttpTemplate = ensureHttpTemplateDefaults(
            parsedYaml as iHttpTemplate,
          )
          setHttpTemplate(updatedHttpTemplate)
          setTool({ ...tool, httpTemplate: updatedHttpTemplate })
        } catch (e) {
          toast.error('Cannot save: Invalid YAML in HTTP Template')
          return
        }
      }
      const loadingToastId = toast('Please wait...', { isLoading: true })
      setIsLoading(true)

      tool.referenceTagging = referenceTagging
      tool.responseExample = responseExample
      tool.markdownTemplate = markdownTemplate
      tool.citationRequired = referenceTagging
      tool.refIdPath = refIdPath

      let newTool: iTool
      if (!value.id) {
        newTool = await createTool(tool)
      } else {
        newTool = await updateTool(tool)
      }

      setIsLoading(false)
      toast.update(loadingToastId, {
        render: 'All is good',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      })
      setHasUnsavedChanges(false)
      return newTool
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleShareTypeChange = (shareType: ShareType) => {
    setTool({ ...tool, sharedType: shareType })
    switch (shareType) {
      case ShareType.Limited:
        setShared(deepCopy(sharedLimited))
        break
      case ShareType.Public:
        setShared(deepCopy(sharedPublic))
        break
    }
  }

  useEffect(() => {
    setTool({ ...tool, shared })
  }, [shared])

  const [definitionStyle, setDefinitionStyle] = useState<
    null | 'default' | 'templateString'
  >(null)

  useEffect(() => {
    if (definitionStyle === 'default') {
      setTool({ ...tool, httpTemplate })
    }
    if (definitionStyle === 'templateString') {
      setTool({ ...tool, httpTemplateString: '<HTTP request template>' })
    }
  }, [definitionStyle])

  const handleJsonExampleChange = (value: string | undefined) => {
    if (!value) return
    setResponseExample(value)
    setTool((prev) => ({ ...prev, responseExample: value }))

    let parsed: any = null
    try {
      parsed = JSON.parse(value)
    } catch {
      try {
        const parser = new XMLParser()
        parsed = parser.parse(value)
      } catch {
        parsed = null
        setRenderedMarkdown('')
      }
    }
    if (parsed !== null) {
      setParsedData(parsed)
    } else {
      setParsedData(null)
      setRenderedMarkdown('')
    }
  }

  const handleMarkdownTemplateChange = (value: string | undefined) => {
    if (!value) return
    setMarkdownTemplate(value)
    setTool((prev) => ({ ...prev, markdownTemplate: value }))
  }

  useEffect(() => {
    if (responseExample) {
      let parsed: any = null
      try {
        parsed = JSON.parse(responseExample)
      } catch {
        try {
          const parser = new XMLParser()
          parsed = parser.parse(responseExample)
        } catch {
          parsed = null
          setRenderedMarkdown('')
        }
      }
      if (parsed !== null) {
        setParsedData(parsed)
      }
    }
  }, [responseExample])

  useEffect(() => {
    if (parsedData && markdownTemplate) {
      try {
        const rendered = renderHandlebarsTemplate(markdownTemplate, parsedData)
        setRenderedMarkdown(rendered)
      } catch {
        setRenderedMarkdown('Error rendering markdown')
      }
    }
  }, [parsedData, markdownTemplate])

  useEffect(() => {
    if (parsedData) {
      const paths = getAllPaths(parsedData, '')
      setAllPaths(paths.filter(Boolean))
    } else {
      setAllPaths([])
    }
  }, [parsedData])

  const handleParameterChange = (name: string, val: any) => {
    setParameterValues({ ...parameterValues, [name]: val })
  }

  // "Load Test Response"
  const handleParameterModalSubmit = () => {
    setIsLoadingTestResponse(true)
    ToolService.runTool(tool.id!, parameterValues)
      .then((response) => {
        const isStr = typeof response === 'string'
        const text = isStr ? (response as string) : JSON.stringify(response, null, 2)
        setResponseExample(text)
        setIsLoadingTestResponse(false)
      })
      .catch((error) => {
        console.error(error)
        setIsLoadingTestResponse(false)
      })
  }

  // "Test Tool" modal
  const handleTestToolSubmit = () => {
    setIsLoadingTestTool(true)
    setTestToolResponse(null)
    setTestToolError(null)
    ToolService.runTool(tool.id!, parameterValues)
      .then((response) => {
        setTestToolResponse(response)
        setIsLoadingTestTool(false)
      })
      .catch((error) => {
        console.error(error)
        setTestToolError(error.message || 'An error occurred')
        setIsLoadingTestTool(false)
      })
  }

  const resetTestToolModal = () => {
    setParameterValues({})
    setTestToolResponse(null)
    setTestToolError(null)
  }

  // Generate Handlebars Template
  const handleTemplateGeneration = async (prompt?: string) => {
    setIsLoadingTemplateGeneration(true)
    let retries = 3
    let success = false
    while (retries > 0 && !success) {
      try {
        let assistantPrompt = responseExample
        if (includeExistingTemplate && markdownTemplate) {
          assistantPrompt += `\n\nCurrent Handlebars Template:\n\`\`\`handlebars\n${markdownTemplate}\n\`\`\``
        }
        if (tool.citationRequired && refIdPath) {
          assistantPrompt += `\n\nIMPORTANT: Please ensure each item has an anchor in Markdown with [{{refId}}] e.g. ## [{{refId}}] {{title}}.\nAlso the first each item must be {{#each this}}`
        }
        if (prompt) {
          assistantPrompt += `\n\nInstructions: ${prompt}`
        }
        const message = await AssistantService.runAssistantChatCompletion(
          '00002',
          assistantPrompt,
        )
        const content = message?.content || ''
        if (!content.includes('```handlebars')) {
          throw new Error('Failed to find handlebars block in assistant response.')
        }
        const extracted = content.split('```handlebars')[1].split('```')[0].trim()
        setMarkdownTemplate(extracted)

        // Test rendering
        if (parsedData) {
          renderHandlebarsTemplate(extracted, parsedData)
        }
        success = true
      } catch (error) {
        console.error(error)
        retries--
        if (retries === 0) {
          toast.error('Failed to generate template after multiple attempts')
        }
      }
    }
    setIsLoadingTemplateGeneration(false)
    onGenerateTemplateModalOpenChange()
  }

  // Generate YAML template
  const handleYamlTemplateGeneration = async () => {
    setIsLoadingYamlTemplateGeneration(true)
    try {
      let assistantPrompt = yamlTemplatePrompt
      if (includeExistingTemplate && httpTemplate) {
        const existingTemplateYaml = YAML.dump(httpTemplate)
        assistantPrompt += `\n\nModify the existing YAML template:\n\`\`\`yaml\n${existingTemplateYaml}\n\`\`\``
      }
      if (openApiFileContent) {
        assistantPrompt += `\n\nOpenAPI specification:\n${openApiFileContent}`
      }
      if (endpointInfo) {
        assistantPrompt += `\n\nEndpoint Information:\n${endpointInfo}`
      }
      const message = await AssistantService.runAssistantChatCompletion(
        '00003',
        assistantPrompt,
      )
      const content = message?.content || ''
      const yamlMatch = content.match(/```yaml([\s\S]*?)```/)
      if (yamlMatch && yamlMatch[1]) {
        const generatedYamlTemplate = yamlMatch[1].trim()
        const parsedYamlTemplate = YAML.load(generatedYamlTemplate) as iHttpTemplate
        const updatedTemplate = ensureHttpTemplateDefaults(parsedYamlTemplate)
        setHttpTemplate(updatedTemplate)
        setTool({ ...tool, httpTemplate: updatedTemplate })
        if (isYamlView) {
          setYamlContent(YAML.dump(updatedTemplate))
        }
        setHasUnsavedChanges(true)
      } else {
        throw new Error('YAML template not found in assistant response')
      }
    } catch (error) {
      console.error(error)
      toast.error('Failed to generate YAML template')
    } finally {
      setIsLoadingYamlTemplateGeneration(false)
      onGenerateYamlTemplateModalOpenChange()
    }
  }

  // Switch between YAML and Editor
  const ymlSwitchView = (view: boolean) => {
    setIsYamlView(view)
    if (view) {
      const yamlStr = YAML.dump(tool.httpTemplate || {})
      setYamlContent(yamlStr)
    } else {
      try {
        const parsedYaml = YAML.load(yamlContent) as iHttpTemplate
        const updatedHttpTemplate = ensureHttpTemplateDefaults(parsedYaml)
        setHttpTemplate({ ...updatedHttpTemplate })
        setTool({ ...tool, httpTemplate: updatedHttpTemplate })
      } catch (e) {
        console.error('Invalid YAML:', e)
      }
    }
  }

  const handleYamlContentChange = (value: string | undefined) => {
    setYamlContent(value || '')
    setHasUnsavedChanges(true)
  }

  // Build parameter arrays for modals
  const parameters =
    tool.httpTemplate && tool.httpTemplate.parameters
      ? Object.keys(tool.httpTemplate.parameters).map((paramName) => ({
          name: paramName,
          parameter: tool.httpTemplate!.parameters[paramName],
        }))
      : []

  const detectLanguage = (content: string): string => {
    const trimmed = content.trim()
    if (trimmed.startsWith('{') || trimmed.startsWith('[')) {
      return 'json'
    } else if (trimmed.startsWith('<')) {
      return 'xml'
    }
    return 'plaintext'
  }

  return (
    <>
      {/* Parameter Modal */}
      <ParameterModal
        isOpen={isParameterModalOpen}
        onOpenChange={onParameterModalOpenChange}
        parameters={parameters}
        parameterValues={parameterValues}
        onParameterChange={handleParameterChange}
        onSubmit={handleParameterModalSubmit}
        isLoading={isLoadingTestResponse}
      />

      {/* Generate YAML Template Modal */}
      <GenerateYamlTemplateModal
        isOpen={isGenerateYamlTemplateModalOpen}
        onOpenChange={onGenerateYamlTemplateModalOpenChange}
        yamlTemplatePrompt={yamlTemplatePrompt}
        setYamlTemplatePrompt={setYamlTemplatePrompt}
        endpointInfo={endpointInfo}
        setEndpointInfo={setEndpointInfo}
        includeExistingTemplate={includeExistingTemplate}
        setIncludeExistingTemplate={setIncludeExistingTemplate}
        openApiFileContent={openApiFileContent}
        setOpenApiFileContent={setOpenApiFileContent}
        isLoading={isLoadingYamlTemplateGeneration}
        onGenerate={handleYamlTemplateGeneration}
      />

      {/* Generate Template Modal */}
      <GenerateTemplateModal
        isOpen={isGenerateTemplateModalOpen}
        onOpenChange={onGenerateTemplateModalOpenChange}
        templatePrompt={templatePrompt}
        setTemplatePrompt={setTemplatePrompt}
        includeExistingTemplate={includeExistingTemplate}
        setIncludeExistingTemplate={setIncludeExistingTemplate}
        isLoading={isLoadingTemplateGeneration}
        onGenerate={() => handleTemplateGeneration(templatePrompt)}
      />

      {/* Test Tool Modal */}
      <TestToolModal
        isOpen={isTestToolModalOpen}
        onOpenChange={onTestToolModalOpenChange}
        parameters={parameters}
        parameterValues={parameterValues}
        onParameterChange={handleParameterChange}
        testToolResponse={testToolResponse}
        testToolError={testToolError}
        isLoadingTestTool={isLoadingTestTool}
        onSubmit={handleTestToolSubmit}
        onReset={resetTestToolModal}
      />

      <Tabs variant={'underlined'} destroyInactiveTabPanel={false}>
        <Tab key="general" title="General">
          <div className="flex flex-col gap-4">
            <SectionCard title="Tool setup">
              <div className="mb-4">
                <Input
                  label="Name"
                  readOnly={isDisabled}
                  type="text"
                  name="name"
                  defaultValue={tool.name}
                  onChange={(e) => setTool({ ...tool, name: e.target.value })}
                  isRequired
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Function name"
                  readOnly={isDisabled}
                  type="text"
                  name="functionName"
                  maxLength={64}
                  defaultValue={tool.functionName}
                  onChange={(e) =>
                    setTool({ ...tool, functionName: e.target.value })
                  }
                  isRequired
                />
              </div>
              {/*<div className="mb-4">*/}
              {/*  <Input*/}
              {/*    label="Image url"*/}
              {/*    readOnly={isDisabled}*/}
              {/*    type="text"*/}
              {/*    defaultValue={tool.image}*/}
              {/*    name="image"*/}
              {/*    onChange={(e) => setTool({ ...tool, image: e.target.value })}*/}
              {/*    isRequired*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="mb-4">
                <Textarea
                  label="Description"
                  readOnly={isDisabled}
                  defaultValue={tool.description}
                  name="description"
                  maxLength={1000}
                  rows={4}
                  onChange={(e) => setTool({ ...tool, description: e.target.value })}
                  isRequired
                />
              </div>
            </SectionCard>
            <IconManager
              entity={tool}
              isDisabled={isDisabled}
              setEntity={setTool}
              entityType="Tool"
            />
          </div>
        </Tab>
        <Tab key="reference" title="Referencing">
          <div className="flex flex-col gap-4">
            <SectionCard title="Referencing" description="Enable reference tagging">
              <div className="mb-4 mr-4 flex flex-auto">
                <Switch
                  isDisabled={isDisabled}
                  isSelected={referenceTagging}
                  onValueChange={(checked) => {
                    setReferenceTagging(checked)
                    setTool({ ...tool, referenceTagging: checked })
                  }}
                >
                  {referenceTagging ? 'Enabled' : 'Disabled'}
                </Switch>
              </div>

              {toolviewFAQ && <FaqItem markdown={toolviewFAQ?.markdown} />}
            </SectionCard>

            {referenceTagging && (
              <>
                <SectionCard
                  title="Example Response"
                  description="Example response from the tool"
                >
                  <div className="mb-4">
                    <div className="flex justify-between m-2">
                      <Button
                        variant="solid"
                        size="sm"
                        className="mr-6"
                        onPress={onOpenParameterModal}
                        isLoading={isLoadingTestResponse}
                      >
                        Load Test Response
                      </Button>
                    </div>
                    <CodeEditor
                      isDisabled={isDisabled}
                      value={responseExample}
                      modalLabel="Example Response"
                      language={detectLanguage(responseExample)}
                      onChange={handleJsonExampleChange}
                    />
                  </div>
                </SectionCard>

                {referenceTagging && (
                  <SectionCard
                    title="Reference Id Path"
                    description="Set the path where the reference id must be located"
                  >
                    <div className="mb-4">
                      <div className="mb-4 mr-4 flex flex-col">
                        <label className="text-sm font-medium mb-1">
                          Reference ID Path
                        </label>
                        <Select
                          isDisabled={!parsedData || isDisabled} // Nur aktiv, wenn es eine Example-Response gibt
                          isRequired
                          selectedKeys={refIdPath ? new Set([refIdPath]) : new Set()}
                          onSelectionChange={(keys) => {
                            const val = Array.from(keys)[0] as string
                            setRefIdPath(val)
                          }}
                          placeholder={
                            parsedData
                              ? 'Select the field path'
                              : 'No example response available'
                          }
                        >
                          {allPaths.map((path) => (
                            <SelectItem key={path} value={path}>
                              {path}
                            </SelectItem>
                          ))}
                        </Select>
                        <p className="text-xs mt-2 text-gray-500">
                          This path tells the system where to add each item’s unique
                          reference identifier in your response data. The reference
                          identifier is used to link the item to the corresponding
                          citation in your document. That way, the Assistant can cite
                          the item correctly. Only arrays are supported.
                        </p>
                      </div>
                    </div>
                  </SectionCard>
                )}

                <SectionCard
                  title="Handlebars Tool Template"
                  description={
                    "Generate a Handlebars template to render the tool's output"
                  }
                >
                  <Card className="mb-4">
                    <CardHeader>
                      <span className="text-sm">
                        You can embed special tokens within your Handlebars template.
                        The system will automatically replace these with data from
                        the user’s profile:
                      </span>
                    </CardHeader>
                    <CardBody className="text-sm">
                      <ul className="list-disc list-inside ml-4 mb-2">
                        <li>
                          <strong>@user_jwt</strong> – Replaced with the user’s JWT
                          token, if available.
                        </li>
                        <li>
                          <strong>@user_email</strong> – Replaced with the user’s
                          email address, if available.
                        </li>
                        <li>
                          <strong>@user_name</strong> – Replaced with the user’s
                          display name, if available.
                        </li>
                      </ul>
                    </CardBody>
                  </Card>

                  <div className="mb-4">
                    <div className="flex justify-between m-2">
                      <Button
                        variant="solid"
                        size="sm"
                        className="mr-6"
                        isDisabled={!parsedData || isDisabled}
                        onPress={onOpenGenerateTemplateModal}
                        isLoading={isLoadingTemplateGeneration}
                      >
                        <ICON_ASSISTANT />
                        Generate
                      </Button>
                    </div>
                    <CodeEditor
                      isDisabled={isDisabled}
                      value={markdownTemplate}
                      modalLabel="Handlebars Tool Template"
                      language="handlebars"
                      onChange={handleMarkdownTemplateChange}
                    />
                  </div>
                  {renderedMarkdown && (
                    <SectionCard title="Rendered Tool Preview">
                      <Markdown value={renderedMarkdown} />
                    </SectionCard>
                  )}
                </SectionCard>
              </>
            )}
          </div>
        </Tab>
        <Tab key="api" title="API">
          <SectionCard title="Parameter">
            <div className="text-base mb-2 mt-2 flex flex-row justify-between">
              <Switch isSelected={isYamlView} onValueChange={ymlSwitchView}>
                {isYamlView ? 'YAML View' : 'Editor View'}
              </Switch>
              <div className="flex items-center">
                <Button
                  isDisabled={isDisabled}
                  variant="solid"
                  size="sm"
                  className="mr-2"
                  onPress={onOpenGenerateYamlTemplateModal}
                  isLoading={isLoadingYamlTemplateGeneration}
                >
                  <ICON_ASSISTANT />
                  Generate
                </Button>
                <Button
                  variant="solid"
                  size="sm"
                  className="mr-6"
                  onPress={() => {
                    if (hasUnsavedChanges) {
                      toast.warn('Please save your changes before testing the tool.')
                      return
                    }
                    resetTestToolModal()
                    onOpenTestToolModal()
                  }}
                  isLoading={isLoadingTestTool}
                >
                  Test Tool
                </Button>
              </div>
            </div>
            {isYamlView ? (
              <div className="h-[450px]">
                <CodeEditor
                  isDisabled={isDisabled}
                  value={yamlContent}
                  language="yaml"
                  onChange={handleYamlContentChange}
                />
              </div>
            ) : (
              <HttpTemplateEditor
                isDisabled={isDisabled}
                currentHttpTemplate={httpTemplate}
                onChange={(template) => {
                  setHttpTemplate(template)
                  setTool({ ...tool, httpTemplate: template })
                }}
              />
            )}
            <div className="mb-4">
              <OverridableEditor
                isDisabled={isDisabled}
                overridableSet={overridables}
                onChange={(o) => setTool({ ...tool, overridableParameters: o })}
              />
            </div>
          </SectionCard>
        </Tab>
        <Tab key="publicity" title="Public">
          <div className="flex flex-col gap-4">
            <SectionCard className="min-w-96" title="Privacy Settings">
              <ShareWithSelection
                isDisabled={isDisabled}
                selectedKey={tool.sharedType ?? ShareType.None}
                onChange={handleShareTypeChange}
              />
            </SectionCard>
            <MembersSelection
              isDisabled={isDisabled || tool.sharedType === ShareType.None}
              shared={shared}
              ownerId={tool.ownerId}
              onSelectionChange={setShared}
              writeOnly={tool.sharedType === ShareType.Public}
              roles={tool.roles}
            />
          </div>
        </Tab>
      </Tabs>
    </>
  )
}

export default React.forwardRef(ToolDetailView)
