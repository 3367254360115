import React, { useState } from 'react'
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/outline'
import { Button } from '@nextui-org/react'

type ThumbRatingProps = {
  currentValue: number
  onRated: (value: number) => void
  size?: 'sm' | 'md' | 'lg'
}

const ThumbRating = ({ currentValue, onRated, size = 'md' }: ThumbRatingProps) => {
  const [highlightedValue, setHighlightedValue] = useState(currentValue)

  return (
    <>
      <Button
        isIconOnly
        size={size}
        variant="light"
        className={highlightedValue === 1 ? 'text-success' : 'text-gray-500'}
        onMouseEnter={() => setHighlightedValue(1)}
        onMouseLeave={() => setHighlightedValue(currentValue)}
        onPress={() => onRated(1)}
      >
        <HandThumbUpIcon className="w-[1.5em] inline-block cursor-pointer" />
      </Button>
      <Button
        variant="light"
        isIconOnly
        size={size}
        className={highlightedValue === -1 ? 'text-danger' : 'text-gray-500'}
        onMouseEnter={() => setHighlightedValue(-1)}
        onMouseLeave={() => setHighlightedValue(currentValue)}
        onPress={() => onRated(-1)}
      >
        <HandThumbDownIcon className="w-[1.5em] inline-block cursor-pointer" />
      </Button>
    </>
  )
}

export default ThumbRating
