import React, { useEffect, useState } from 'react'
import { PencilSquareIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { Button, Tooltip } from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import useTeamStore from '@/states/teamStore.ts'
import useAssistantStore from '@states/assistantStore.ts'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import AssistantService from '@/services/assistantService.ts'
import { Role } from '@/interfaces/iShared.ts'
import AssistantModelSelection from '../assistants/AssistantModelSelection.tsx'
import { isDefaultTeam } from '@/interfaces/iTeam.ts'
import AssistantModelSettings from '@components/session/AssistantModelSettings.tsx'
import useAssistantModelStore from '@states/assistantModelStore.ts'
import { iAssistantModel } from '@/interfaces/iAssistantModel.ts'
import FaqItem from '@components/faq/FaqItem.tsx'
import { getFAQById } from '@/utils/getLocalFaqs.ts'
import { FAQ_ID_LLMCAPS } from '@/constants/faqIDs.ts'
import { ICON_TOOL } from '@/constants/icons.tsx'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'
import useChatStore from '@states/chatStore.ts'
import AssistantDetailModal from '@components/session/AssistantDetailModal.tsx'
import CodeEditor from '@components/basic/codeEditor/CodeEditor.tsx'

const toolTipModel =
  'Select the model that you would like to use for generating text.'
const toolTipSystemPrompt =
  "This prompt will change GAIA's behavior. You can use this to clarify the context of your conversation, the topic or the output format."

type AssistantSettingsProps = {
  assistant: iAssistant
  updateAssistant: (assistant: iAssistant) => void
  isDisabled?: boolean
}
function AssistantSettings({
  assistant,
  updateAssistant,
  isDisabled,
}: AssistantSettingsProps) {
  const [instructions, setInstructions] = useState<string>(assistant!.instruction!)
  const { hasRightTo, selectedTeam } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
      hasRightTo: state.hasRightTo,
    }),
    shallow,
  )
  const inheritedAssistant = useChatStore().inheritedAssistant
  const getAssistantModelsById = useAssistantModelStore().getAssistantModelsById
  const { hasRightTo: hasAssistantRightTo } = useAssistantStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
    }),
    shallow,
  )

  useEffect(() => {
    setInstructions(assistant!.instruction!)
  }, [assistant?.instruction])
  const [maxOutputTokens, setMaxOutputTokens] = useState<number>()
  const modelsFAQ = getFAQById(FAQ_ID_LLMCAPS)

  const handleModelChange = (modelValue: iAssistantModel) => {
    if (!assistant?.settings) {
      return
    }
    if (
      [
        'o1-preview-sweden-central',
        'o1-mini-sweden-central',
        'o1-sweden-central',
      ].includes(modelValue.id)
    ) {
      assistant.settings.stream = false
    } else {
      assistant.settings.stream = true
    }
    assistant.settings.modelId = modelValue.id
    setMaxOutputTokens(modelValue.maxOutputTokens)

    if (assistant.settings.maxTokens === 0) {
      assistant.settings.maxTokens = modelValue.maxOutputTokens
    } else {
      assistant.settings.maxTokens =
        assistant.settings.maxTokens! > modelValue.maxOutputTokens
          ? modelValue.maxOutputTokens
          : assistant.settings.maxTokens
    }
    updateAssistant(assistant)
  }

  const handleMaxTokenChange = async (range: number) => {
    if (assistant?.settings) {
      assistant.settings.maxTokens = range
      updateAssistant(assistant)
    }
  }

  const handleTemperatureChange = async (range: number) => {
    if (assistant?.settings) {
      assistant.settings.temperature = range
      updateAssistant(assistant)
    }
  }

  const handleTransferLengthChange = async (range: number) => {
    if (assistant?.settings) {
      assistant.settings.transferLength = range
      updateAssistant(assistant)
    }
  }

  const handleTopPChange = async (range: number) => {
    if (assistant?.settings) {
      assistant.settings.topP = range
      updateAssistant(assistant)
    }
  }

  const handleReasoningEffortChange = async (value: 'low' | 'medium' | 'high') => {
    if (assistant?.settings) {
      const updatedAssistant = {
        ...assistant,
        settings: {
          ...assistant.settings,
          reasoningEffort: value,
        },
      }
      updateAssistant(updatedAssistant)
    }
  }

  const handleUpdateSystemPrompt = async (content: string | undefined) => {
    if (assistant) {
      assistant.instruction = content
      updateAssistant(assistant)
    }
  }

  const handleStreamChange = async (isSelected: boolean) => {
    if (assistant?.settings) {
      assistant.settings.stream = isSelected
      updateAssistant(assistant)
    }
  }

  useEffect(() => {
    if (assistant?.settings?.modelId) {
      const model = getAssistantModelsById(assistant.settings.modelId)
      if (model) setMaxOutputTokens(model.maxOutputTokens)
    }
  }, [assistant])

  const updateBaseAssistant = () => {
    if (inheritedAssistant && assistant) {
      const tmpAssistant: iAssistant = inheritedAssistant
      tmpAssistant.settings!.stream = assistant.settings?.stream
      tmpAssistant.instruction = assistant.instruction
      tmpAssistant.settings!.topP = assistant.settings?.topP
      tmpAssistant.settings!.transferLength = assistant.settings?.transferLength
      tmpAssistant.settings!.temperature = assistant.settings?.temperature
      tmpAssistant.settings!.maxTokens = assistant.settings?.maxTokens
      tmpAssistant.settings!.modelId = assistant.settings?.modelId
      tmpAssistant.settings!.reasoningEffort = assistant.settings?.reasoningEffort
      // tmpAssistant.knowledgeContainerIds = assistant.knowledgeContainerIds
      // tmpAssistant.toolIds = assistant.toolIds
      // tmpAssistant.archiveChatHistory = assistant.archiveChatHistory
      AssistantService.updateAssistant(tmpAssistant)
    }
  }

  if (!assistant) return <div>Assistant not found</div>

  return (
    (hasRightTo(Role.Member) || isDefaultTeam(selectedTeam!)) &&
    assistant && (
      <>
        <div className="grid grid-cols-1 gap-4 place-content-center min-w-[222px]">
          {/* <SystemSettingsOnboardingGuide></SystemSettingsOnboardingGuide> */}

          <div className="mt-4">
            <Tooltip
              content={toolTipSystemPrompt}
              className={'max-w-96'}
              delay={500}
            >
              <div className={'flex place-content-center'}>
                Instructions
                <InformationCircleIcon className="w-4 h-4 ml-1" />
              </div>
            </Tooltip>

            <div>
              <CodeEditor
                isDisabled={isDisabled}
                value={instructions}
                modalLabel="Instructions"
                onBlur={(content) => handleUpdateSystemPrompt(content)}
              />
            </div>
          </div>

          <div data-guide-id="model" className="mt-4">
            <Tooltip content={toolTipModel} className={'max-w-96'} delay={500}>
              <div className={' flex place-content-center'}>
                Model
                <InformationCircleIcon className="w-4 h-4 ml-1" />
              </div>
            </Tooltip>

            <AssistantModelSelection
              isDisabled={isDisabled}
              variant="bordered"
              modelId={assistant?.settings!.modelId!}
              onChange={handleModelChange}
              context={'team'}
            ></AssistantModelSelection>
            <div className="my-4"></div>
            <AssistantModelSettings
              isDisabled={isDisabled}
              onMaxTokensChange={handleMaxTokenChange}
              onStreamChange={handleStreamChange}
              onTemperatureChange={handleTemperatureChange}
              onTopPChange={handleTopPChange}
              onTransferLengthChange={handleTransferLengthChange}
              onReasoningEffortChange={handleReasoningEffortChange}
              settings={assistant!.settings!}
              maxOutputTokens={maxOutputTokens ?? 0}
            ></AssistantModelSettings>

            <div data-guide-id="load-export" className="mt-4">
              <div
                className={'grid  justify-items-center min-w-fit min-w-[220px] pb-2'}
              >
                {assistant.configuration?.modelSettings.editable && (
                  <AssistantDetailModal
                    isOpen
                    assistant={assistant}
                  ></AssistantDetailModal>
                )}
                {!!inheritedAssistant &&
                  hasAssistantRightTo(Role.Manager, inheritedAssistant) && (
                    <Button
                      variant={'bordered'}
                      className="mt-3 min-w-fit w-full ml-1"
                      onPress={updateBaseAssistant}
                    >
                      <PencilSquareIcon
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      />
                      Update Assistant
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </div>
        {modelsFAQ && <FaqItem markdown={modelsFAQ?.markdown} />}
      </>
    )
  )
}

export default AssistantSettings
