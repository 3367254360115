import React, { useState } from 'react'

import './loadingScreen.css'
import GaiaLogo from '../basic/logo/GaiaLogo.tsx'
import { Link, Progress } from '@nextui-org/react'
type LoadingScreenProps = React.HTMLAttributes<HTMLDivElement> & {
  isLoading: boolean
}
const LoadingScreen = ({ isLoading }: LoadingScreenProps) => {
  const [isHideWrapper, setIsHideWrapper] = useState(false)
  const [activateHelpPart, setActivateHelpPart] = useState(false)
  const [isMinLoadingTime, setIsMinLoadingTime] = useState(false)

  setTimeout(() => setIsMinLoadingTime(true), 2000)
  setTimeout(() => setActivateHelpPart(true), 3000)

  const mountedStyle = (delay: number = 0) => ({
    opacity: 0,
    animation: 'inAnimation 150ms ease-in',
    animationFillMode: 'forwards',
    animationDelay: delay + 'ms',
  })

  const mountedScaleStyle = (delay: number = 0) => ({
    opacity: 0,
    animation: 'inScaleAnimation 500ms ease-out',
    animationFillMode: 'forwards',
    animationDelay: delay + 'ms',
  })

  return (
    <div
      data-testid="test-component-loading-screen"
      style={isHideWrapper ? { display: 'none' } : {}}
      className={
        `${!isLoading && isMinLoadingTime ? 'closing' : ''} ` +
        'gaia-loading-screen-wrapper text-foreground bg-background z-20 fixed top-0 left-0 right-0 bottom-0'
      }
      onAnimationEnd={(e) => {
        if (
          e.animationName === 'outAnimation' &&
          (e.target as HTMLDivElement).classList.contains('gaia-loading-screen')
        )
          setIsHideWrapper(true)
      }}
    >
      <div
        className={'gaia-loading-screen flex justify-center items-center h-screen'}
      >
        <div className={'flex flex-col justify-center items-center'}>
          <div
            className={'flex flex-col justify-center items-center'}
            style={isLoading ? mountedScaleStyle() : {}}
          >
            <GaiaLogo className={'w-44 h-44'} />
            <h1 className={'gaia-fade-in font-bold text-7xl'}>GAIA</h1>
          </div>

          <div style={isLoading ? mountedStyle(800) : {}}>
            <h3 className={'font-semibold text-center text-slate-500'}>
              Generative AI Assistant
            </h3>
            <Progress
              size="sm"
              isIndeterminate
              aria-label="Loading..."
              className="max-w-md mt-5"
            />
          </div>
          {isLoading && activateHelpPart && (
            <div
              className={'flex flex-col justify-center items-center'}
              style={isLoading ? mountedStyle() : {}}
            >
              <span className={'text-sm mt-4'}>
                You will be redirected in a few seconds
              </span>
              <br></br>
              <span className={'text-sm'}>
                If you are not a member yet, please contact the support
              </span>
              <span className={'text-sm'}>
                <Link href="mailto:DLCDE-IT-GAIA@draeger.com">
                  DLCDE-IT-GAIA@draeger.com
                </Link>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen
