import { createWithEqualityFn } from 'zustand/traditional'
import { iSession } from '@/interfaces/iSession.ts'
import { iDefaultStore } from '@/interfaces/stores/iDefaultStore.ts'
import { iMessage } from '@/interfaces/iMessage.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import { iTool } from '@/interfaces/iTool.ts'
import AssistantService from '@/services/assistantService.ts'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import SessionService from '@/services/sessionService.ts'
import { iAssistantAction } from '@/interfaces/iAssistantAction.ts'
import { uuidv4 } from '@/utils/uuidv4.ts'
import UserService from '@/services/UserService.ts'
import SessionStore from '@states/sessionStore.ts'
import { Popup } from '@/enums/PopupTypes.ts'
import MainState from '@states/mainState.ts'
import { useMemo } from 'react'
import useUserProfileStore from '@states/userProfileState.ts'
export interface iChatState {
  chatControlContentRoute: { contentName?: string; route?: string; value?: any }
  isClearingChatMessages: boolean
  isActivatingSession: boolean // is  true if session is switched
  isAssistantGenerating: boolean
  isAssistantRunning: boolean
  isLoadingAssistant: boolean
  isLoadingMessages: boolean
  isLoadingKnowledgeContainer: boolean
  isLoadingTools: boolean
  isLoadingSession: boolean // set during GET/PUT/POST/DELETE session
  messages: iMessage[]
  knowledgeContainers: iKnowledgeContainer[]
  tools: iTool[]
  session: iSession | null
  assistant: iAssistant | null
  inheritedAssistant: iAssistant | null
  currentAssistantAction: iAssistantAction | null
  isChatControlContentOpen: boolean
  isChatControlContentPinned: boolean
  messagesInView: string[] | null
  messageToScrollToId: string | null
  hasOpenToU: boolean
}

export interface iChatStore extends iChatState, Omit<iDefaultStore, 'init'> {
  fetchMessages: (
    sessionId: string,
    options?: { offset?: number; disableLoading?: boolean },
  ) => Promise<{ data: iMessage[]; total: number }>
  setMessages: (messages: iMessage[]) => void
  setKnowledgeContainers: (messages: iKnowledgeContainer[]) => void
  setTools: (messages: iTool[]) => void
  setSession: (session: iSession | null) => void
  activateChatSession: (session: iSession) => Promise<void>
  setAssistant: (assistant: iAssistant) => void
  setInheritedAssistant: (assistant: iAssistant | null) => void
  setIsLoadingSession: (loading: boolean) => void
  setIsAssistantGenerating: (generating: boolean) => void
  setIsAssistantRunning: (running: boolean) => void
  setCurrentAssistantAction: (action: iAssistantAction | null) => void
  setIsClearingChatMessages: (clearing: boolean) => void
  setIsChatControlContentOpen: (open: boolean) => void
  setIsChatControlContentPinned: (pinned: boolean) => void
  clearChatMessages: () => Promise<void>
  setSessionToDefault: () => Promise<void>
  fetchTools: (assistantId: string) => Promise<void>
  fetchKnowledgeContainers: (assistantId: string) => Promise<void>

  setChatControlContentRoute<T>(contentName: string, route?: string, value?: T): void
  changeAssistant: (assistant: iAssistant) => Promise<void>
  createMessage: (message: iMessage) => Promise<iMessage | undefined>
  deleteMessage: (message: iMessage) => Promise<void>
  updateMessage: (message: iMessage) => Promise<void>
  updateSession: (session: iSession) => Promise<void>
  updateAssistant: (session: iAssistant) => Promise<void>
  updateInheritedAssistant: (session: iAssistant) => Promise<void>
  setMessagesInView: (messageIds: string[] | null) => void
  goToMessageById: (messageId: string | null) => void
  handleTermsOfUse: (assistant: iAssistant) => Promise<void>
}

const initialState: iChatState = {
  hasOpenToU: false,
  chatControlContentRoute: {},
  isActivatingSession: true,
  // is true if message is generating
  isAssistantGenerating: false,
  // is true until assistant isn´t finished eg. with title generation ...
  isAssistantRunning: false,
  isLoadingAssistant: true,
  isLoadingMessages: true,
  isLoadingKnowledgeContainer: true,
  isLoadingTools: true,
  isLoadingSession: true,
  messages: [],
  assistant: null,
  inheritedAssistant: null,
  knowledgeContainers: [],
  tools: [],
  session: null,
  currentAssistantAction: null,
  isClearingChatMessages: false,
  isChatControlContentOpen: false,
  isChatControlContentPinned: false,
  messagesInView: null,
  messageToScrollToId: null,
}
const useChatStore = createWithEqualityFn<iChatStore>((set, getState) => ({
  ...initialState,
  reset: () => {
    set(initialState)
  },
  init: async () => {
    getState().reset()
  },
  setChatControlContentRoute: (contentName, route, value) =>
    set({
      chatControlContentRoute: {
        ...{
          contentName,
          route,
          value,
        },
      },
    }),
  setIsLoadingSession: (isLoadingSession) => set({ isLoadingSession }),
  setAssistant: (assistant) => set({ assistant: { ...assistant } }),
  setInheritedAssistant: (inheritedAssistant) =>
    set({
      inheritedAssistant: inheritedAssistant ? { ...inheritedAssistant } : null,
    }),
  setSession: (session) => {
    set({ session: session ? { ...session } : session })
  },
  setCurrentAssistantAction: (currentAssistantAction: iAssistantAction | null) =>
    set({ currentAssistantAction }),
  setIsAssistantGenerating: (isAssistantGenerating) =>
    set({ isAssistantGenerating }),
  setIsClearingChatMessages: (isClearingChatMessages) =>
    set({ isClearingChatMessages }),
  setIsAssistantRunning: (isAssistantRunning: boolean) =>
    set({ isAssistantRunning }),
  setIsChatControlContentOpen: (isChatControlContentOpen: boolean) =>
    set({ isChatControlContentOpen }),
  setIsChatControlContentPinned: (isChatControlContentPinned: boolean) =>
    set({ isChatControlContentPinned }),
  setTools: (tools) => set({ tools }),
  setMessages: (messages) => set({ messages: [...messages] }),
  setKnowledgeContainers: (knowledgeContainers) => set({ knowledgeContainers }),
  setMessagesInView: (messageIds) => set({ messagesInView: messageIds }),
  goToMessageById: (messageId) => set({ messageToScrollToId: messageId }),
  updateSession: async (session) => {
    getState().setIsLoadingSession(true)
    getState().setSession(session)
    await SessionService.updateSession(session)
    getState().setIsLoadingSession(false)
  },
  fetchMessages: async (
    sessionId: string,
    options?: { offset?: number; disableLoading?: boolean },
  ) => {
    !options?.disableLoading && set({ isLoadingMessages: true })
    const response = await SessionService.getMessages(sessionId, {
      offset: options?.offset,
    })
    const newMessages =
      (options?.offset ?? 0) > 0
        ? [...getState().messages, ...response.data]
        : response.data
    getState().setMessages(newMessages)
    !options?.disableLoading && set({ isLoadingMessages: false })
    return response
  },
  fetchTools: async (assistantId: string) => {
    set({ isLoadingTools: true })
    AssistantService.getAssistantTools(assistantId)
      .then((response) => {
        getState().setTools(response)
      })
      .finally(() => {
        set({ isLoadingTools: false })
      })
  },
  fetchKnowledgeContainers: async (assistantId: string) => {
    set({ isLoadingKnowledgeContainer: true })
    AssistantService.getAssistantKnowledgeContainer(assistantId)
      .then((response) => {
        getState().setKnowledgeContainers(response)
      })
      .finally(() => {
        set({ isLoadingKnowledgeContainer: false })
      })
  },
  setSessionToDefault: async () => {
    const sessionId = getState().session?.id
    if (!sessionId) return
    const session = await SessionService.setSessionToDefault(sessionId)
    const assistant = await AssistantService.getAssistant(session.assistantId)

    getState().setAssistant(assistant)
    getState().setInheritedAssistant(null)
    getState().changeAssistant(assistant)
    return
  },
  handleTermsOfUse: async (assistant: iAssistant) => {
    console.trace()
    if (!assistant.termsOfUse) return
    return new Promise((resolve, reject) => {
      MainState.getState().setPopup(Popup.Legal, {
        termsOfUse: assistant.termsOfUse,
        resourceId: assistant.id,
        callback: (accepted: boolean) => {
          if (accepted) {
            resolve()
            set({ hasOpenToU: false })
          } else {
            reject()
            set({ hasOpenToU: true })
          }
        },
      })
    })
  },
  changeAssistant: async (assistant) => {
    const fetchAssistant = await AssistantService.getAssistant(assistant.id!)
    if (fetchAssistant) {
      assistant = fetchAssistant
    }
    const session = getState().session
    if (!session) return
    const assistantId = assistant.id!
    const sessionAssistant = getState().assistant
    //check legal
    if (assistant.termsOfUse) {
      try {
        await getState().handleTermsOfUse(assistant)
      } catch (e) {
        return
      }
    }
    if (!sessionAssistant) return
    sessionAssistant.baseAssistantId = assistantId
    sessionAssistant.baseAssistantCopyDate = new Date()
    sessionAssistant.title = assistant.title
    sessionAssistant.description = assistant.description
    sessionAssistant.settings = assistant.settings
    sessionAssistant.quickPrompts = assistant.quickPrompts
    sessionAssistant.image = assistant.image
    sessionAssistant.instruction = assistant.instruction
    sessionAssistant.toolIds = assistant.toolIds
    sessionAssistant.knowledgeContainerIds = assistant.knowledgeContainerIds
    //sessionAssistant.teamReferences = assistant.teamReferences TODO: check
    sessionAssistant.archiveChatHistory = assistant.archiveChatHistory
    sessionAssistant.faqMarkdown = assistant.faqMarkdown
    sessionAssistant.canvasMode = assistant.canvasMode
    sessionAssistant.welcomeMessage = assistant.welcomeMessage
    sessionAssistant.configuration = assistant.configuration
    getState().updateAssistant(sessionAssistant)

    //load assistant specific tools
    getState().fetchTools(assistantId)

    // load assistant specific kc´s
    getState().fetchKnowledgeContainers(assistantId)
    getState().setAssistant(sessionAssistant)
    if (assistant.id !== sessionAssistant.id)
      getState().setInheritedAssistant(assistant)
  },
  updateAssistant: async (assistant) => {
    set({ isLoadingAssistant: true })
    getState().setAssistant(assistant)
    await AssistantService.updateAssistant(assistant)
    set({ isLoadingAssistant: false })
  },

  updateInheritedAssistant: async (assistant) => {
    set({ isLoadingAssistant: true })
    getState().setInheritedAssistant(assistant)
    await AssistantService.updateAssistant(assistant)
    set({ isLoadingAssistant: false })
  },

  activateChatSession: async (session: iSession) => {
    set({ isActivatingSession: true })
    // reset store to default state values to avoid data mix ups
    getState().reset()
    try {
      // to be up-to-date, we fetch a session again
      const fetchedSession = await SessionService.getSession(session.id!)
      const storedSession = SessionStore.getState().getStoredSession(fetchedSession)
      if (storedSession) {
        fetchedSession._messageInputFieldValue =
          storedSession._messageInputFieldValue
      }
      getState().setSession(fetchedSession)

      // fetch session specific assistant
      const assistant = await AssistantService.getAssistant(session.assistantId)
      getState().setAssistant(assistant)

      if (assistant?.baseAssistantId) {
        AssistantService.getAssistant(assistant.baseAssistantId).then(
          (baseAssistant) => {
            getState().setInheritedAssistant(baseAssistant)
            getState().handleTermsOfUse(baseAssistant)
          },
        )
      }

      const assistantId = session?._assistant?.baseAssistantId ?? session.assistantId

      if (session) await UserService.setLastSessionId(session.id!)
      //load assistant specific tools
      getState().fetchTools(assistantId)

      // load assistant specific kc´s
      getState().fetchKnowledgeContainers(assistantId)
    } catch (error) {
      console.error(error)
    } finally {
      set({ isActivatingSession: false })
    }
  },

  clearChatMessages: async () => {
    set({ isClearingChatMessages: true })
    const session = getState().session
    if (!session) return
    try {
      await SessionService.deleteMessages(session.id!)
      getState().setMessages([])
    } catch (error) {
      console.error(error)
    } finally {
      set({ isClearingChatMessages: false })
    }
  },

  createMessage: async (message: iMessage) => {
    try {
      const session = getState().session
      if (!session) return
      const messages = getState().messages || []
      const tmpId = uuidv4()
      message._tmpId = tmpId
      message._isLoading = true
      messages.unshift(message)
      // set message to display it before calling be
      getState().setMessages(messages)

      const msg = await SessionService.createMessage(session.id!, message)
      const messageIndex = messages.findIndex((message) => message._tmpId! === tmpId)
      messages[messageIndex] = msg
      delete messages[messageIndex]._isLoading
      getState().setMessages(messages)
      return msg
    } catch (error) {
      console.error('Error: ', error)
    }
    return undefined
  },
  updateMessage: async (message: iMessage) => {
    try {
      const session = getState().session
      if (!session) return
      const messages = getState().messages || []
      const messageIndex = messages.findIndex((msg) => message.id! === msg.id)
      messages[messageIndex]._isLoading = true
      getState().setMessages(messages)
      await SessionService.updateMessage(session.id!, message)
      delete messages[messageIndex]._isLoading
      getState().setMessages(messages)
    } catch (error) {
      console.error(error)
    } finally {
      /* empty */
    }
  },
  deleteMessage: async (message: iMessage) => {
    try {
      const session = getState().session
      if (!session) return
      const messages = getState().messages || []
      const messageIndex = messages.findIndex((msg) => message.id! === msg.id)
      messages[messageIndex]._isDeleting = true
      getState().setMessages(messages)
      await SessionService.deleteMessage(session.id!, message.id!)
      // create an array without the message to delete, go by id
      messages.splice(messageIndex, 1)

      getState().setMessages(messages)
    } catch (error) {
      console.error(error)
    } finally {
      /* empty */
    }
  },
}))

export default useChatStore
