import { Button } from '@nextui-org/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import React from 'react'

type LoadMoreButtonProps = {
  remainingItems: number
  handleLoadMore: () => void
  isLoading: boolean
}

const LoadMoreButton = ({
  remainingItems,
  isLoading,
  handleLoadMore,
}: LoadMoreButtonProps) => {
  return (
    (remainingItems === null || remainingItems > 0) && (
      <Button
        onPress={handleLoadMore}
        isLoading={isLoading}
        className="w-full mt-4"
        color="primary"
        variant="flat"
      >
        {isLoading
          ? 'Loading...'
          : remainingItems !== null
            ? `Load More (${remainingItems} remaining)`
            : 'Load More'}
        <ChevronDownIcon className="w-4 h-4 ml-2" />
      </Button>
    )
  )
}

export default LoadMoreButton
